<template>
    <div class="header">
        <!-- <div class="title">无涯云平台</div> -->
        <div class="img-box">
            <img src="../assets/img/Logo.png" alt="无涯智能官网--无涯智能集研发、生产、销售于一体，主营无线联网通讯模块和设备" border="0" />
        </div>
        <!-- 右侧内容 -->
        <div class="right-box">
            <div class="full-box">
                <div class="full iconfont" @click="screen()" :class="this.full ? 'icon-tuichuquanping' : 'icon-quanping'"></div>
            </div>
            <div class="content-box">
                <img src="../assets/img/service.png">
                <a href="https://www.hi-wooya.com/ServiceSupport" target="_blank">
                    <div>服务支持</div>
                </a>
            </div>
            <div class="content-box">
                <img src="../assets/img/Translate.png">
                <div>Englisth</div>
            </div>
            <div class="content-box">
                <img style="width: 30px;height: 30px;border-radius: 30px;" :src=imgsrc>
                <div @mouseenter="noticeenter()" @mouseleave="noticeleave()">
                    {{ user.mobile }}
                    <div v-if="noticeshow">
                        <div class="notice-box">
                            <div class="notice-son" @click="logout()">退出</div>
                        </div>
                        <div class="triangle"></div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            noticeshow: false,
            user: '',
            head: '',
            imgsrc: '',
            full: false,
        }
    },
    created() {
        this.user = JSON.parse(sessionStorage.getItem('user'))
        this.head = this.user.head
        this.imgsrc = 'https://cloud.hi-wooya.com/headimg/' + this.head
    },
    methods: {
        noticeenter() {
            this.noticeshow = true
        },
        noticeleave() {
            this.noticeshow = false
        },
        logout() {
            this.$js.openpages('/');
            sessionStorage.clear()
        },
        screen() {
            let full = document.fullscreenElement 
            this.full = full
            if (!this.full) {
                //利用requestFullscreen方法实现全屏
                document.documentElement.requestFullscreen()
            } else {
                //退出全屏
                document.exitFullscreen()
            }
            this.full = !this.full
        }
    }
}
</script>
<style scoped>
.header {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
}
.title{
    position: fixed;
    height: 50px;
    line-height: 50px;
    /* left: 50%; */
    left:  calc((100% - 210px)/2 + 210px);
    color: #97a8be;
    transform: translate(-50%, 0);
}
.img-box {
    width: 210px;
    height: 50px;
}

.right-box {
    display: flex;
}

.content-box {
    position: relative;
    display: flex;
    height: 50px;
    align-items: center;
    padding: 0px 10px;
}
.full-box {
    position: relative;
    display: flex;
    height: 50px;
    align-items: center;
    padding: 0px 10px;
}

.notice-box {
    background-color: white;
    position: absolute;
    top: 50px;
    right: 0px;
    padding: 10px 0;
    border: 1px solid #ebeef5;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
}
.full {
    width: 20px;
    height: 20px;
    font-size: 20px;
    cursor: pointer;
    color: #333333;
}

.notice-son {
    width: 100px;
    height: 36px;
    line-height: 36px !important;
    text-align: center;
    color: #333333 !important;
    cursor: pointer;
}

.notice-son:hover {
    color: #012f55 !important;
}

.triangle {
    top: 40px;
    right: 30px;
    position: absolute;
    border-top: 5px solid transparent;
    border-bottom: 5px solid white;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
}

.content-box img {
    width: 15px;
    height: 15px;
    margin-right: 5px;
}

.content-box div {
    font-size: 12px;
    color: #333333;
    line-height: 50px;
}
</style>
import axios from 'axios'
const _axios = axios.create({
    baseURL: 'https://cloudapi.hi-wooya.com/', //基础请求地址
    //baseURL: 'http://localhost:3001/', //基础请求地址
    timeout: 30000, //最大请求时间
})


const http = {
    async get(url = '', params = {}) {
        let res = await _axios({
            url,
            data:params,
            headers: { 'x-security-token': 'wooya' },
            method: 'GET'
        });
        return res.data;
    },
    async post(url = '', params = {}) {
        let res = await _axios({
            url,
            data:params,
            headers: { 'x-security-token': 'wooya'},
            method: 'POST'
        });
        return res.data;
    },
    async postimg(url = '', params = {}) {
        let res = await _axios({
            url,
            data:params,
            headers: { 'x-security-token': 'wooya','Content-Type': 'multipart/form-data' },
            method: 'POST'
        });
        return res.data;
    }
}
export default http
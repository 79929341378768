<template>
  <div style="height: 100vh;width: 100vw;">
    <!-- 页头 -->
    <nav-header2 style="position: absolute;z-index: 999;"></nav-header2>
    <div style="height: 50px;"></div>
    <div style="display: flex;height: calc(100% - 50px);width: 100%;">
      <!-- 侧边栏 -->
      <side-bar></side-bar>
      <div style="flex: 1;padding: 10px;background-color: #e4e7ed;display: flex;">
        <div class="content-box">
          <div>
            <div class="tab">
              <span style="color: #999999;">网络管理</span>
              <span style="margin: 0px 10px;">></span>
              <span>项目列表</span>
            </div>
            <div class="title-box">
              <!-- 标题 -->
              <div class="title">项目列表</div>
              <!-- 按钮 -->
              <div class="btn-box">
                <div
                  class="btn"
                  style="color: white;background-color: #012f55;"
                  @click="addprojectshow()"
                >
                  添加项目
                </div>
                <div
                  class="btn"
                  @click="deleteallshow()"
                >批量删除</div>
                <!-- <div class="btn" @click="exportExcel()">
                        <img class="exportimg" src="../assets/img/export.png">
                        导出
                    </div> -->
              </div>
            </div>
            <!-- 下拉选择框 -->
            <div class="select-box">
              <!-- <div class="select" v-if="isuper == 'manager'">
                                <el-select @change="changefirm(firm)" v-model="firm" placeholder="请选择公司">
                                    <el-option v-for="(item, index) in firmList" :key="index" :label="item.firm"
                                        :value="item.firm">
                                    </el-option>
                                </el-select>
                            </div> -->
              <div class="select">
                <el-input
                  v-model="search"
                  placeholder="请输入项目名称"
                ></el-input>
              </div>
              <div
                class="search"
                @click="handleSearch()"
              >查询</div>
            </div>
            <!-- 网络id部分 -->
            <div>
              <div class="projectListtitle">
                <div class="inputcheck">
                  <div style="width: 32px;">
                    <el-checkbox
                      v-model="checkAll"
                      @change="checkall()"
                    ></el-checkbox>
                  </div>
                  <div
                    class="projectname"
                    style="font-weight: bold;"
                  >项目名称</div>
                </div>
                <div class="projectdesc">项目描述</div>
                <!-- <div class="projectid">编号</div> -->
                <div class="projectproject">创建时间</div>
                <div class="projectoperate">
                  <div style="width: 100%;">操作</div>
                </div>
              </div>
              <div
                class="projectList"
                v-for="item in displayedBoxes"
                :key="item.id"
              >
                <div style="display: flex;">
                  <div class="inputcheck">
                    <div style="width: 32px;">
                      <el-checkbox v-model="item.checked"></el-checkbox>
                    </div>
                    <div class="projectname">{{ item.pjname }}</div>
                  </div>
                </div>
                <div class="projectdesc">{{ item.desc ? item.desc : '暂无描述' }}</div>
                <!-- <div class="projectid">{{ item.id }}</div> -->
                <div class="projectproject">{{ time(item.date) }}</div>
                <div class="projectoperate">
                  <div
                    v-if="item.notice"
                    class="notice2"
                  >
                    <div class="overlay"></div>
                    <div class="deleteallbox">
                      <div style="padding: 20px;">提示</div>
                      <div style="padding: 20px 30px">确定删除此项目？删除后将无法筛选项目网络！</div>
                      <div style="margin-top: 16px;">
                        <div style="display: flex;justify-content: flex-end;">
                          <div
                            @click="showdelete(item)"
                            class="addbtn"
                            style="margin-left: 0px;margin-bottom: 20px;"
                          >取消
                          </div>
                          <div
                            @click="deleteproject(item.id, item.date)"
                            class="addbtn"
                            style="color: white;background-color: #012f55;margin-left: 20px;margin-right: 15px;;"
                          >
                            确定</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="projectbtn"
                    @click="changeprojectshow(item)"
                    style="margin-left: 0px"
                  >编辑
                  </div>
                  <div
                    @click="showdelete(item)"
                    class="projectbtn"
                  >删除</div>
                </div>
              </div>
              <!-- 删除所有展示 -->
              <div v-if="alldelete">
                <div class="overlay"></div>
                <div class="deleteallbox">
                  <div style="padding: 20px;">提示</div>
                  <div style="padding: 20px 30px">确定删除所选项目？删除后将无法筛选项目网络！</div>
                  <div style="margin-top: 16px;">
                    <div style="display: flex;justify-content: flex-end;">
                      <div
                        @click="deleteallcancel()"
                        class="addbtn"
                        style="margin-left: 0px;margin-bottom: 20px;"
                      >取消</div>
                      <div
                        @click="deleteallenter()"
                        class="addbtn"
                        style="color: white;background-color: #012f55;margin-left: 20px;margin-right: 15px;"
                      >
                        确定</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 分页 -->
          <div class="currentpage">
            <button
              class="pagination"
              @click="goToPreviousPage()"
              :disabled="currentPage === 1"
              :class="{ 'pagination-hover': currentPage !== 1 }"
            >上一页</button>
            <span
              v-for="page in pages"
              :key="page"
            >
              <button
                @click="goToPage(page)"
                class="pagination2"
                :class="{ 'pagination-active': page == currentPage }"
              >{{
                                    page }}</button>
            </span>
            <button
              class="pagination"
              @click="goToNextPage()"
              :disabled="currentPage === totalPages"
              :class="{ 'pagination-hover': currentPage !== totalPages }"
            >下一页</button>
          </div>
        </div>
      </div>
    </div>
    <!-- 添加项目内容 -->
    <div v-if="addshow">
      <div class="notice"></div>
      <!-- 添加项目 -->
      <div
        class="addbox"
        v-if="!editshow"
      >
        <div class="addtitle">添加项目</div>
        <div class="addtable">
          <el-form
            :rules="rules"
            ref="form"
            :model="form"
            label-width="80px"
          >
            <el-form-item
              label="项目名称"
              prop="name"
            >
              <el-input
                placeholder="请输入项目名称"
                v-model="form.name"
              ></el-input>
            </el-form-item>
            <el-form-item label="项目描述">
              <el-input
                placeholder="请填写项目描述，不超过50个字符"
                type="textarea"
                v-model="form.desc"
              ></el-input>
            </el-form-item>
          </el-form>
          <div style="display: flex;justify-content: end;">
            <div
              class="addbtn"
              @click="cancel()"
            >取消</div>
            <div
              class="addbtn"
              @click="submitForm('form')"
              style="background-color: #012f55;color: white;"
            >
              确认
            </div>
          </div>
        </div>
      </div>
      <!-- 编辑项目 -->
      <div
        class="addbox"
        v-if="editshow"
      >
        <div class="addtitle">编辑项目</div>
        <div class="addtable">
          <el-form
            :rules="rules"
            ref="form2"
            :model="form2"
            label-width="80px"
          >
            <el-form-item
              label="项目名称"
              prop="name"
            >
              <el-input
                placeholder="请输入项目名称"
                v-model="form2.name"
              ></el-input>
            </el-form-item>
            <el-form-item label="项目描述">
              <el-input
                placeholder="请填写项目描述，不超过50个字符"
                type="textarea"
                v-model="form2.desc"
              ></el-input>
            </el-form-item>
          </el-form>
          <div style="display: flex;justify-content: end;">
            <div
              class="addbtn"
              @click="cancel2()"
            >取消</div>
            <div
              class="addbtn"
              @click="changeproject()"
              style="background-color: #012f55;color: white;"
            >确认
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      id: '',
      alldelete: false,
      addshow: false,
      editshow: false,
      checkAll: false,
      search: '',
      project: '',
      isuper: '',
      firm: '',
      firmList: [],
      fprojectList: [],
      projectList: [],
      form: {
        name: '',
        desc: ''
      },
      form2: {
        name: '',
        desc: ''
      },
      rules: {
        name: [
          { required: true, message: '请输入项目名称', trigger: 'blur' },
          { min: 1, max: 20, message: '长度在 1 到 10 个字符', trigger: 'blur' }
        ],
        desc: [
          { message: '请输入项目名称', trigger: 'blur' },
          { min: 0, max: 50, message: '长度在 0 到 50 个字符', trigger: 'blur' }
        ],
      },
      currentPage: 1,
      itemsPerPage: 16
    }
  },
  created() {
    this.$js.token();
    sessionStorage.setItem('tabid', '22');
    sessionStorage.setItem('path', '/Project');
    sessionStorage.setItem('netpage', '1');
    this.getproject()
    this.changeheight()
  },
  computed: {
    displayedBoxes() {
      const startIndex = this.currentPage * this.itemsPerPage - this.itemsPerPage
      const endIndex = startIndex + this.itemsPerPage
      return this.fprojectList.slice(startIndex, endIndex)
    },
    totalPages() {
      return Math.ceil(this.fprojectList.length / this.itemsPerPage)
    },
    pages() {
      const pages = [1];
      for (let i = 2; i <= this.totalPages; i++) {
        pages.push(i);
      }
      return pages;
    }
  },
  methods: {
    goToPreviousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    goToNextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    goToPage(page) {
      if (page >= 1 && page <= this.totalPages) {
        this.currentPage = page;
      }
    },
    // 获取页面大小
    changeheight() {
      if (document.documentElement.clientHeight <= 800 && document.documentElement.clientHeight > 750) {
        this.itemsPerPage = 12
      } else if (document.documentElement.clientHeight <= 750 && document.documentElement.clientHeight > 720) {
        this.itemsPerPage = 11
      } else if (document.documentElement.clientHeight < 720) {
        this.itemsPerPage = 7
      }
    },
    // 编辑项目展示
    changeprojectshow(item) {
      this.addshow = true
      this.editshow = true
      this.form2.name = item.pjname
      this.form2.desc = item.desc
      this.id = item.id
    },
    // 编辑项目
    async changeproject() {
      let id = this.id
      let name = this.form2.name
      let desc = this.form2.desc
      let res = await this.$apiFun.changeproject({ name, desc, id })
      alert(res)
      location.reload()
    },
    // 取消操作
    cancel() {
      this.addshow = false
    },
    cancel2() {
      this.addshow = false
      this.editshow = false
    },
    //批量删除
    deleteallshow() {
      const allFalse = this.fprojectList.every(obj => obj.checked === false);
      if (allFalse) {
        alert('请选择需要删除的项目')
      } else {
        this.alldelete = true
      }
    },
    deleteallcancel() {
      this.alldelete = false
    },
    deleteallenter() {
      const startIndex = this.currentPage * this.itemsPerPage - this.itemsPerPage
      const endIndex = startIndex + this.itemsPerPage
      this.fprojectList.slice(startIndex, endIndex).forEach(async item => {
        if (item.checked == true) {
          let id = item.id
          let date = item.date
          let res = await this.$apiFun.deleteproject({ id, date });
        }
      })
      this.alldelete = false
      // alert('删除成功')
      // location.reload()
    },
    // 表单确认
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.addproject()
        } else {
          return false;
        }
      });
    },
    // 添加项目
    addprojectshow() {
      this.addshow = true
    },
    async addproject() {
      let pjname = this.form.name
      let desc = this.form.desc
      let data = JSON.parse(sessionStorage.getItem('user'));
      let userid = data.id
      let firm = data.firm
      let res = await this.$apiFun.addproject({ pjname, desc, userid, firm })
      alert(res)
      this.addshow = false
      location.reload()
    },
    // 删除项目
    async deleteproject(id, date) {
      let res = await this.$apiFun.deleteproject({ id, date })
      alert(res)
      location.reload()
    },
    // 搜索功能
    handleSearch() {
      this.fprojectList = [];
      if (this.search == '') {
        this.fprojectList = this.projectList;
        return false;
      }
      this.projectList.forEach(item => {
        if (item.pjname.includes(this.search)) {
          this.fprojectList.push(item);
        }
      });
    },
    // 全选
    checkall() {
      this.fprojectList.forEach(item => item.checked = this.checkAll)
    },
    //改变标签项目
    changefirm(firm) {
      this.currentPage = 1
      if (firm == '所有公司') {
        this.fprojectList = this.projectList
      } else {
        this.fprojectList = this.projectList.filter(item => (item.firm == firm))
      }
    },
    //获取所有项目
    async getproject() {
      let data = JSON.parse(sessionStorage.getItem('user'));
      let userid = data.id
      this.isuper = data.issuper
      let res = await this.$apiFun.getproject({ userid })
      this.projectList = res
      const firmSet = new Set();
      // 生成notice
      //生成checked
      this.projectList.forEach(item => {
        this.$set(item, 'notice', false)
        this.$set(item, 'checked', false)
        firmSet.add(item.firm);
      })
      firmSet.forEach(firm => {
        if (firm != '') {
          this.firmList.push({ firm });
        }
      });
      this.firmList.unshift({ firm: '所有公司' })
      this.projectList.sort((a, b) => a.date - b.date);
      this.fprojectList = this.projectList
      console.log(this.firmList);
    },
    // 时间戳转换
    time(date) {
      let timestamp = parseInt(date);
      let time = new Date(timestamp);
      // 获取年、月、日
      let year = time.getFullYear();
      let month = ("0" + (time.getMonth() + 1)).slice(-2); // 月份是从0开始的，所以需要加1
      let day = ("0" + time.getDate()).slice(-2);
      // 格式化日期
      let formattedDate = year + "-" + month + "-" + day;
      return formattedDate
    },
    // 展示删除框
    showdelete(item) {
      if (item.notice) {
        item.notice = !item.notice
      } else {
        this.fprojectList.forEach(item => {
          item.notice = false
        })
        item.notice = !item.notice
      }
    }
  }
}
</script>

<style scoped>
.content-box {
  min-width: 1300px;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  padding: 0 1.1%;
  background-color: white;
  overflow-y: hidden;
  overflow-x: auto;
  white-space: nowrap;
}

.tab {
  font-size: 12px;
  padding-top: 10px;
  height: 38.8px;
  line-height: 38.8px;
}

.title-box {
  margin: 20px 0px 10px;
  height: 30px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ebeef5;
}

.notice2 {
  color: #333333;
  z-index: 999;
  padding: 10px 0;
  background-color: #012f55;
  border: 1px solid #ebeef5;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

.title {
  /* text-align: center; */
  font-size: 14px;
  font-weight: bold;
}

.btn-box {
  margin: -15px 0px 0px 0px;
  display: flex;
}

.btn {
  display: flex;
  align-items: center;
  padding: 0px 16px;
  height: 32px;
  line-height: 32px;
  margin: 0px 10px 0px 0px;
  border: 1px solid #e7e9f1;
  cursor: pointer;
  font-size: 14px;
  color: #666666;
}

.projectListtitle {
  font-size: 14px;
  display: flex;
  padding: 8px;
  font-weight: bold;
  border-top: 1px solid #e7e9f1;
  border-bottom: 1px solid #e7e9f1;
  background-color: #fafafb;
}

.projectList {
  font-size: 14px;
  display: flex;
  padding: 8px;
  border-bottom: 1px solid #ebeef5;
}

.projectList:hover {
  background: #fafafb;
}

.projectname {
  width: 340px;
  width: 22.7vw;
  flex-shrink: 0;
  margin-top: 1px;
}

.projectproject {
  width: 22.95%;
  /* width: 280px; */
  flex-shrink: 0;
  margin-top: 1px;
}

.projectdesc {
  width: 28.8%;
  flex-shrink: 0;
}

.projectoperate {
  width: 200px;
  display: flex;
  /* justify-content: center; */
  white-space: nowrap;
  flex-shrink: 0;
  margin-top: 1px;
}

.notice {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  /* 半透明黑色背景 */
  z-index: 999;
  /* 确保在上层 */
}

.addbox {
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  height: 300px;
  z-index: 999;
  background-color: white;
}

.addtitle {
  padding: 25px 0px 0px 15px;
  font-size: 14px;
  font-weight: bold;
}

.addtable {
  margin-top: 40px;
  padding: 0px 80px;
}

.addbtn {
  height: 30px;
  line-height: 30px;
  background: #fafafa;
  padding: 0px 25px;
  font-size: 14px;
  border: 1px solid #dcdcdc;
  margin-left: 10px;
  cursor: pointer;
}

.overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  /* 半透明黑色背景 */
  z-index: 999;
  /* 确保在上层 */
}

.deleteallbox {
  position: fixed;
  top: 40%;
  left: 50%;
  width: 428px;
  transform: translate(-50%, -50%);
  z-index: 999;
  background-color: white !important;
}

.projectbtn {
  color: #337ab7;
  border: none;
  cursor: pointer;
  height: 100%;
  margin: 0 10px;
}

.projectbtn:hover {
  color: #70c31d;
}

.inputcheck {
  display: flex;
}

.select-box {
  display: flex;
}

.select {
  margin: 0px 10px 20px 0px;
}

.search {
  margin: 0px 10px 20px 0px;
  line-height: 32px;
  padding: 0 16px;
  background-color: #012f55;
  color: white;
  cursor: pointer;
}

.select-box:deep(.el-input__inner) {
  width: 150px;
  height: 32px;
}

.select-box:deep(.el-input__icon) {
  line-height: 32px;
}

.select:deep(.el-input__inner:focus) {
  border-color: #012f55;
}

.select:deep(.el-input.is-focus .el-input__inner) {
  border-color: #012f55;
}

.inputcheck:deep(.el-textarea__inner:hover) {
  background: #edf2fc;
  border-color: #dcdfe6;
}

.inputcheck:deep(.el-checkbox__inner) {
  background: #edf2fc;
  border-color: #dcdfe6;
}

.addtable:deep(.el-input__inner:hover) {
  border-color: #012f55;
}

.addtable:deep(.el-textarea__inner:hover) {
  border-color: #012f55;
}

.inputcheck:deep(.el-checkbox__input.is-focus .el-checkbox__inner) {
  border-color: #012f55;
}

.inputcheck:deep(
    .el-checkbox__input.is-checked .el-checkbox__inner,
    .el-checkbox__input.is-indeterminate .el-checkbox__inner
  ) {
  background: #012f55;
  border-color: #012f55;
}

.select:deep(.el-input__inner) {
  line-height: 32px;
}

.addbox:deep(.el-form-item) {
  margin-bottom: 35px;
}

.addtable:deep(.el-textarea__inner:focus) {
  border-color: #012f55;
}

.select:deep(.el-input__inner:hover) {
  border-color: #012f55;
}

.select:deep(.el-select:hover .el-input__inner) {
  border-color: #012f55;
}

.addtable:deep(.el-input__inner:focus) {
  border-color: #012f55;
}

@media screen and (max-width: 1600px) {
  .projectproject {
    width: 17.3%;
    /* width: 280px; */
    flex-shrink: 0;
    margin-top: 1px;
  }

  .select-box:deep(.el-input__inner) {
    width: 120px;
    height: 28px;
  }

  .select-box:deep(.el-input) {
    font-size: 12px !important;
  }

  .select-box:deep(.el-input__icon) {
    line-height: 28px;
  }

  .search {
    font-size: 13px;
    margin: 0px 10px 20px 0px;
    line-height: 28px;
    padding: 0 16px;
    background-color: #012f55;
    color: white;
    cursor: pointer;
  }

  .btn {
    font-size: 13px;
    height: 28px;
    line-height: 28px;
  }
}
</style>
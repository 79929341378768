<template>
    <div id="container"></div>
</template>
<script>
import AMapLoader from '@amap/amap-jsapi-loader'
window._AMapSecurityConfig = {
    securityJsCode: '945ecc9cdac7abf98f870c2e58103cb5'
}
export default {
    data() {
        return {
            AMap: null,
            map: null,
            maker: null,
            geocoder: null,
            list: true,
            l: null,
            t: null,
            lnglat: []
        }
    },
    props: ['faMsg', 'faMsg2'],
    methods: {
        initMap() {
            AMapLoader.load({
                key: '9b1633822a3b0ee621e86959679bbe6f', // 申请好的Web端开发者Key，首次调用 load 时必填
                version: '2.0', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
                AMapUI: {
                    version: "1.1", // AMapUI 版本
                },
                plugin: ['AMap.PlaceSearch', 'AMap.ControlBar', "AMap.HawkEye", 'AMap.ToolBar', 'AMap.Scale', 'AMap.Geocoder']
            })
                .then(AMap => {
                    this.AMap = AMap
                    this.map = new AMap.Map('container', {
                        //设置地图容器id
                        viewMode: '3D', //是否为3D地图模式
                        zoom: 15, //初始化地图级别
                        center: this.faMsg, //初始化地图中心点位置
                    });
                    AMap.plugin(['AMap.PlaceSearch', 'AMap.ControlBar', "AMap.HawkEye", "AMap.ToolBar", 'AMap.Scale', 'AMap.Geocoder'], () => {
                        var autoOptions = {
                            city: '全国',
                            extensions: 'all',
                            // map: this.map, // 展现结果的地图实例
                            pageSize: 10, // 单页显示结果条数
                            pageIndex: 1, // 页码
                            autoFitView: false // 是否自动调整地图视野使绘制的 Marker点都处于视口的可见范围
                        };
                        let controlBar = new AMap.ControlBar({
                            visible: true,
                            position: {
                                top: '10px',
                                right: '10px'
                            }
                        })
                        this.placeSearch = new this.AMap.PlaceSearch(autoOptions);
                        this.map.addControl(new AMap.HawkEye({ isOpen: true }));
                        this.map.addControl(controlBar);
                        this.map.addControl(new AMap.ToolBar({ position: 'LT' }));
                        this.map.addControl(new AMap.Scale());
                        this.geocoder = new AMap.Geocoder({});
                        this.marker = new AMap.Marker({
                            position: this.faMsg,   // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
                            title: '当前选择位置'
                        });
                        this.map.add(this.marker);
                    })

                    this.map.on("click", this.clickMapHandler)
                })
                .catch(e => {
                    console.log(e)
                })
        },
        clickMapHandler(e) {
            this.lnglat = [e.lnglat.getLng(), e.lnglat.getLat()];
            // // 点击地图上的位置，根据经纬度转换成详细地址
            this.geocoder.getAddress(this.lnglat, (status, result) => {
                if (status === 'complete' && result.info === 'OK') {
                    this.map.remove(this.marker);
                    this.marker = new this.AMap.Marker({
                        position: this.lnglat,   // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
                        title: '当前位置'
                    });
                    this.map.add(this.marker);
                    let data = { address: result.regeocode.formattedAddress, position: this.lnglat,province:result.regeocode.addressComponent.province }
                    this.mapaddress(data)
                }
            })
            this.list = false
            if (this.l != null) {
                clearTimeout(this.l)
            }
            this.l = setTimeout(() => {
                this.list = true
            }, 3000)
        },
        mapaddress(data) {
            this.$emit('mapaddress', data)
        },
        maplist(data) {
            this.$emit('maplist', data)
        },
    },
    mounted() {
        //DOM初始化完成进行地图初始化
        this.initMap()
    },
    watch: {
        faMsg2(newValue, oldValue) {
            if (this.list) {
                //方法内写：
                if (this.t != null) {
                    clearTimeout(this.t)
                }
                this.t = setTimeout(() => {//多次触发后在5秒后执行最后一次触发
                    this.placeSearch.search(this.faMsg2, (status, result) => {
                        // 搜索成功时，result即是对应的匹配数据
                        if (status == 'complete') {
                            if (result.poiList.pois.length == 0) {
                                console.log('没有查询到对应的地址');
                            } else {
                                this.maplist(result.poiList.pois)
                            }
                        }
                    });
                }, 1000)
            }
        },
        faMsg(newValue, oldValue) {
            this.map.setZoomAndCenter(14, newValue);
            this.map.remove(this.marker);
            this.marker = new this.AMap.Marker({
                position: newValue,   // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
                title: '当前位置'
            });
            this.map.add(this.marker);
        }
    }
}
</script>

<style scoped>
#container {
    padding: 0px;
    margin: 0px;
    width: 100%;
    height: 100%;
}
</style>
<template>
    <div class="footer">
        <div>
            <span>关于我们</span>
            <span> | </span>
            <span><a style="color: #7b7b7b;" href="https://www.hi-wooya.com/" target="_blank">无涯官网</a></span>
        </div>
        <div>
            <span>版权所有©四川无涯智能科技有限公司( 蜀ICP备20020584号-1)</span>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {

        }
    },
}
</script>
<style scoped>
.footer {
    /* position: fixed; */
    text-align: center;
    font-size: 14px;
    color: #7b7b7b;
    width: 100%;
    margin: 0px auto;
    /* padding: 10px 20px; */
    /* bottom: 0px; */
    /* left: 0px; */
}
</style>
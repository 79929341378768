<template>
  <div class="sidebar">
    <div>
      <div
        class="tab"
        :class="{ 'son-hover': path == '/networksindex' }"
      >
        <div
          class="box"
          @click="networksindex()"
          style="display: flex;align-items: center;"
        >
          <!-- <img class="img" src="../assets/img/computer.png"> -->
          <div class="img iconfont icon-diannao"></div>
          概览
        </div>
      </div>
    </div>
    <div
      v-for="(item, index) in tabList"
      :key="item.id"
    >
      <div
        v-if="item.isuper"
        class="tab"
        :class="{ 'tab-hover': item.show }"
      >
        <div
          class="box"
          @click="img(item.id, item.show, index)"
          style="display: flex;align-items: center;"
        >
          <!-- <img class="img" :src="item.src"> -->
          <div
            class="img iconfont"
            :class="{ [item.icon]: true }"
          ></div>
          {{ item.content }}
          <div
            :style="{ transform: item.show ? 'rotate(0deg)' : 'rotate(-90deg)' }"
            v-if="item.son != ''"
            class="down-img iconfont icon-iconfontzhizuobiaozhun023129"
          >
          </div>
        </div>
        <div
          class="sonbox"
          :style="{ height: item.show ? `${item.son.length * 40}px` : '0px' }"
        >
          <div
            @click="changepage(s.id, s.path)"
            :class="{ 'son-hover': s.id == id }"
            class="son-box"
            v-for="s in item.son"
            :key="s.id"
          >
            {{ s.content }}
          </div>
        </div>
      </div>
    </div>
    <!-- <div v-if="isuper == 'manager'">
            <div class="tab" :class="{ 'son-hover': path == '/Userdata' }">
                <div class="box" @click="userList()" style="display: flex;align-items: center;">
                    <div class="img iconfont icon-shezhi"></div>
                    子账号管理
                </div>
            </div>
        </div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      id: '1',
      path: '1',
      isuper: '',
      sonid: '',
      tabList: [
        // {
        //     id: '1',
        //     content: '概览',
        //     src: require('../assets/img/computer.png'),
        //     son: [],
        //     isuper: true,
        //     show: false
        // },
        {
          id: '2',
          content: '网络管理',
          icon: 'icon-hulianwang',
          son: [{
            id: '21',
            content: '网络列表',
            path: '/Networks'
          },
          {
            id: '22',
            content: '项目列表',
            path: '/Project'
          },],
          isuper: true,
          show: false
        },
        // {
        //     id: '3',
        //     content: '数据中心',
        //     src: require('../assets/img/datacenter.png'),
        //     son: [],
        //     isuper: true,
        //     show: false
        // },
        // {
        //     id: '4',
        //     content: '报警联动',
        //     src: require('../assets/img/police.png'),
        //     son: [],
        //     isuper: true,
        //     show: false
        // },
        {
          id: '5',
          content: '增值服务',
          icon: 'icon-zengzhifuwu',
          son: [
            {
              id: '51',
              content: '产品购买',
              path: '/Usercharge'
            },
            {
              id: '52',
              content: '我的订单',
              path: '/Myorder'
            },],
          isuper: true,
          show: false
        },
        // {
        //     id: '6',
        //     content: '系统设置',
        //     src: require('../assets/img/system.png'),
        //     son: [],
        //     isuper: true,
        //     show: false
        // },
        {
          id: '7',
          content: '账号与安全',
          icon: 'icon-anquanfanghu',
          son: [
            {
              id: '71',
              content: '账号信息',
              path: '/Minedata'
            },
            {
              id: '72',
              content: '安全信息',
              path: '/SafeUser'
            }
          ],
          isuper: true,
          show: false
        },]
    }
  },
  created() {
    let tabid = sessionStorage.getItem('tabid');
    let path = sessionStorage.getItem('path');
    let tabList = JSON.parse(sessionStorage.getItem('tabList'));
    let data = JSON.parse(sessionStorage.getItem('user'));
    this.isuper = data.issuper
    this.id = tabid ? tabid : '1'
    this.path = path ? path : '/networksindex'
    this.tabList = tabList ? tabList : this.tabList
  },
  methods: {
    // 箭头旋转
    img(id, show, index) {
      if (show == false && (id == '2' || id == '7' || id == '5')) {
        this.tabList[index].show = true
      } else {
        this.tabList[index].show = false
      }
      // this.id = id
    },
    // 跳转
    changepage(id, path) {
      this.id = id
      this.path = path
      sessionStorage.setItem('tabList', JSON.stringify(this.tabList));
      sessionStorage.setItem('tabid', this.id);
      sessionStorage.setItem('path', this.path);
      this.$js.openpages(path);
    },
    // 概览跳转
    networksindex() {
      this.id = 1
      this.path = '/networksindex'
      sessionStorage.setItem('tabList', JSON.stringify(this.tabList));
      sessionStorage.setItem('tabid', this.id);
      sessionStorage.setItem('path', this.path);
      this.$js.openpages('/networksindex')
    },
    // 子账号跳转
    userList() {
      this.id = 8
      this.path = '/Userdata'
      sessionStorage.setItem('tabList', JSON.stringify(this.tabList));
      sessionStorage.setItem('tabid', this.id);
      sessionStorage.setItem('path', this.path);
      this.$js.openpages('/Userdata');
    }
  }
}
</script>

<style scoped>
.sonbox {
  height: 0px;
  overflow: hidden;
  transition: height 0.2s;
}

.sidebar {
  /* position: absolute; */
  /* z-index: 999; */
  font-size: 15px;
  color: #ffffff;
  height: 100%;
  /* height: calc(100% - 70px); */
  width: 210px;
  /* background-color: white; */
  background-color: #304156;
  overflow: auto;
  flex-shrink: 0;
}

.sidebar2 {
  position: absolute;
  z-index: 999;
  font-size: 15px;
  color: #ffffff;
  height: 100%;
  width: 54px;
  background-color: #304156;
  overflow: auto;
}

.img {
  margin: 0px 25px 0 18px;
}

.imgshow {
  position: fixed;
  z-index: 9999;
  display: flex;
  align-items: center;
  margin-left: 10px;
  width: 50px;
  height: 50px;
  left: 210px;
  color: black;
}

.imgshowbox {
  font-size: 25px;
  cursor: pointer;
}

.tab {
  position: relative;
  line-height: 50px;
  width: 100%;
  text-align: center;
  /* color: #5F6368; */
  color: #bfcbd9;
  cursor: pointer;
  align-items: center;
}

.down-img {
  position: absolute;
  right: 30px;
  top: 0px;
  font-size: 10px;
  transition: 0.3s;
}

.son-box {
  height: 40px;
  line-height: 40px;
  color: #bfcbd9;
  background-color: #1f2d3d;
  /* color: #5F6368; */
}

.son-hover {
  /* box-sizing: border-box;
    color: #012F55;
    background-color: #F0F5FF;
    box-shadow: inset -4px 0 #012f55; */
  color: rgb(64, 158, 255);
}

.son-box:hover {
  /* color: #012f55; */
  background-color: #001528;
}

.box:hover {
  /* color: #012f55; */
  background-color: #263445;
}

.box {
  white-space: nowrap;
}

.tab-hover {
  /* color: #012f55; */
  /* color: rgb(64, 158, 255); */
}
</style>
<template>
    <div>
        <!-- 页头 -->
        <nav-header2></nav-header2>
        <!-- 侧边栏 -->
        <side-bar></side-bar>

        <div class="content-box">
            <div class="title">创建网络</div>
            <!-- 创建网络 -->
            <div style="display: flex;">
                <div>网络名称: </div>
                <input type="text" v-model="networkName" placeholder="请输入网络名称" class="netname">
            </div>
            <div style="display: flex;">
                <div>所属项目: </div>
                <input type="text" v-model="project" placeholder="请输入所属项目" class="netname">
            </div>
            <div style="display: flex;">
                <div>标签名称: </div>
                <input type="text" v-model="label" placeholder="请输入标签名称" class="netname">
            </div>
            <div class="addbtn" @click="addnet()">添加网络</div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            networkName: '',
            project:'',
            label:''
        }
    },
    created() {
        this.$js.token();
    },
    methods: {
        //获取状态
        async address() {
            let res = await this.$apiFun.address();
        },
        //添加网络
        async addnet() {
            let data = JSON.parse(sessionStorage.getItem('user'));
            let userid = data.id
            let username = data.name
            let parentid = data.parentid
            let netname = this.networkName
            let label = this.label
            let project = this.project
            let res = await this.$apiFun.addnet({ netname, userid, username, parentid,label,project });
            location.reload()
        },
    }
}
</script>
<style scoped>
.content-box {
    width: 1140px;
    margin: 0 auto;
}

.title {
    font-size: 36px;
    margin: 20px 0px 10px 0px;
    line-height: 1.1;
}

.netname {
    flex: 1;
    height: 22px;
    padding: 6px 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin: 0px 0px 10px 130px;
}

.netname:focus {
    outline: 1px solid #ddd
}

.addbtn {
    width: 58px;
    padding: 6px 12px;
    background-color: #90c31d;
    font-size: 14px;
    color: white;
    cursor: pointer;
    border-radius: 4px;
    text-align: center;
}</style>
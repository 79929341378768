<template>
  <div style="height: 100vh;width: 100vw;">
    <!-- 页头 -->
    <nav-header2 style="position: absolute;z-index: 999;"></nav-header2>
    <div style="height: 50px;"></div>
    <div style="display: flex;height: calc(100% - 50px);width: 100%;">
      <!-- 侧边栏 -->
      <side-bar></side-bar>
      <div style="flex: 1;padding: 10px;background-color: #e4e7ed;display: flex;">
        <div class="content-box">
          <div>
            <div class="tab">
              <span style="color: #999999;">网络管理</span>
              <span style="margin: 0px 10px;">></span>
              <span>网络列表</span>
            </div>
            <div class="title-box">
              <!-- 标题 -->
              <div class="title">网络列表</div>
              <!-- 按钮 -->
              <div class="btn-box">
                <div
                  class="btn"
                  style="color: white;background-color: #012f55;"
                  @click="addnetshow()"
                >
                  添加网络
                </div>
                <!-- <div class="btn">批量添加</div> -->
                <div
                  class="btn"
                  @click="deleteallshow()"
                >批量删除</div>
                <!-- <div class="btn">排序</div> -->
                <div
                  class="btn"
                  @click="exportExcel()"
                >
                  <img
                    class="exportimg"
                    src="../assets/img/export.png"
                  >
                  导出
                </div>
              </div>
            </div>
            <!-- 下拉选择框 -->
            <div class="select-box">
              <div class="select">
                <el-select
                  @change="changeproject(project, label)"
                  v-model="project"
                  placeholder="请选择项目"
                >
                  <el-option
                    v-for="item in projectList"
                    :key="item.id"
                    :label="item.pjname"
                    :value="item.pjname"
                    :disabled="item.disabled"
                  >
                  </el-option>
                </el-select>
              </div>
              <div class="select">
                <el-select
                  @change="changelabel(label, project)"
                  v-model="label"
                  placeholder="请选择描述"
                >
                  <el-option
                    v-for="(item, index) in labelList"
                    :key="index"
                    :label="item.label"
                    :value="item.label"
                  >
                  </el-option>
                </el-select>
              </div>
              <!-- <div class="select" v-if="isuper == 'manager'">
                                <el-select @change="changefirm(firm)" v-model="firm" placeholder="请选择公司">
                                    <el-option v-for="(item, index) in firmList" :key="index" :label="item.firm"
                                        :value="item.firm">
                                    </el-option>
                                </el-select>
                            </div> -->
              <div class="select">
                <el-input
                  v-model="search"
                  placeholder="请输入内容"
                ></el-input>
              </div>
              <div
                class="search"
                @click="handleSearch()"
              >查询</div>
            </div>
            <!-- 网络id部分 -->
            <div>
              <div class="networkListtitle">
                <div class="inputcheck">
                  <div style="width: 32px;">
                    <el-checkbox
                      v-model="checkAll"
                      @change="checkall()"
                    ></el-checkbox>
                  </div>
                  <div
                    class="networkid"
                    style="font-weight: bold;"
                  >网络id</div>
                </div>
                <div class="networkname">网络名称</div>
                <!-- <div class="networkindex">编号</div> -->
                <div class="networkproject">所属项目</div>
                <div class="networklabel">描述</div>
                <!-- <div class="networktime" style="font-weight: bold;">创建时间</div> -->
                <div class="networkoperate">
                  <div style="width: 200px;">操作</div>
                </div>
              </div>
              <div
                class="networkList"
                v-for="item in displayedBoxes"
                :key="item.id"
              >
                <div style="display: flex;">
                  <div class="inputcheck">
                    <div style="width: 32px;">
                      <el-checkbox v-model="item.checked"></el-checkbox>
                    </div>
                    <div class="networkid">{{ item.nwid }} <span
                        @click="copy(item.nwid)"
                        style="cursor: pointer;"
                        class="iconfont icon-fuzhi_o"
                      ></span></div>
                  </div>
                </div>
                <div
                  class="networkname"
                  style="cursor: pointer;"
                  @click="page(item.nwid)"
                >{{
                                    item.netname
                                }}
                </div>
                <!-- <div class="networkindex">{{ item.netindex }}</div> -->
                <div class="networkproject">{{ item.project }}</div>
                <div class="networklabel">{{ item.label ? item.label : '暂无描述' }}</div>
                <!-- <div class="networktime">{{ formatDate(item.creationTime) }}</div> -->
                <div class="networkoperate">
                  <div
                    class="networkbtn"
                    @click="page(item.nwid)"
                  >网络配置</div>
                  <div
                    v-if="item.notice"
                    class="notice"
                  >
                    <div class="overlay"></div>
                    <div class="deleteallbox">
                      <div style="padding: 20px;">提示</div>
                      <div style="text-align: center;padding: 20px 30px">确定删除此网络？删除后此网络连接设备将会失效！
                      </div>
                      <div style="margin-top: 16px;">
                        <div style="display: flex;justify-content: flex-end;">
                          <div
                            @click="shownotice(item)"
                            class="addbtn"
                            style="margin-left: 0px;"
                          >
                            取消
                          </div>
                          <div
                            @click="networksDelete(item.nwid)"
                            class="addbtn"
                            style="color: white;background-color: #012f55;margin-left: 20px;margin-right: 15px;;"
                          >
                            确定</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="networkbtn"
                    @click="page2(item.nwid)"
                  >设备管理</div>
                  <div
                    class="networkbtn"
                    @click="shownotice(item)"
                  >删除网络</div>
                </div>
              </div>
              <!-- 删除所有展示 -->
              <div v-if="alldelete">
                <div class="overlay"></div>
                <div class="deleteallbox">
                  <div style="padding: 20px;">提示</div>
                  <div style="text-align: center;padding: 20px 30px">确定删除所选网络？删除后网络连接设备将会失效！</div>
                  <div style="margin-top: 16px;">
                    <div style="display: flex;justify-content: flex-end;">
                      <div
                        @click="deleteallcancel()"
                        class="addbtn"
                        style="margin-left: 0px;"
                      >取消
                      </div>
                      <div
                        @click="deleteallenter()"
                        class="addbtn"
                        style="color: white;background-color: #012f55;margin-left: 20px;margin-right: 15px;;"
                      >
                        确定</div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- 添加网络内容 -->
              <div v-if="addshow">
                <div class="overlay"></div>
                <div class="addbox">
                  <div class="addtable">
                    <div class="addtitle">添加网络</div>
                    <el-form
                      :rules="rules"
                      ref="form"
                      :model="form"
                      label-width="95px"
                    >
                      <el-form-item
                        label="网络名称"
                        prop="name"
                      >
                        <el-input
                          placeholder="请输入网络名称"
                          v-model="form.name"
                        ></el-input>
                      </el-form-item>
                      <el-form-item
                        label="所属项目"
                        prop="project"
                      >
                        <el-select
                          v-model="form.project"
                          placeholder="请选择项目(如果没有请先再项目列表添加)"
                        >
                          <el-option
                            v-for="item in projectList2"
                            :key="item.id"
                            :label="item.pjname"
                            :value="item.pjname"
                            :disabled="item.disabled"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item label="网络描述">
                        <el-input
                          placeholder="请填写项目描述，不超过50个字符"
                          type="textarea"
                          v-model="form.label"
                        ></el-input>
                      </el-form-item>
                      <!-- <div
                        class="addtitle"
                        style="padding-top: 0px;"
                      >IP分配池设置</div>
                      <div class="IPaddress">
                        <el-form-item
                          label="IP网段"
                          prop="IPaddress"
                        >
                          <el-input
                            placeholder="请输入IP网段"
                            v-model="form.IPaddress"
                          ></el-input>
                          <div
                            class="IPbtn"
                            @click="random()"
                          >随机生成IP段</div>
                        </el-form-item>
                      </div>
                      <el-form-item
                        label="IP起始地址"
                        prop="IPstart"
                      >
                        <el-input
                          placeholder="请输入IP起始地址"
                          v-model="form.IPstart"
                        ></el-input>
                      </el-form-item>
                      <el-form-item
                        label="IP结束地址"
                        prop="IPend"
                      >
                        <el-input
                          placeholder="请输入IP结束地址"
                          v-model="form.IPend"
                        ></el-input>
                      </el-form-item> -->
                      <div style="display: flex;justify-content: flex-end;">
                        <div
                          class="addbtn"
                          @click="cancel()"
                        >取消</div>
                        <div
                          class="addbtn"
                          @click="submitForm('form')"
                          style="background-color: #012f55;color: white;"
                        >确认
                        </div>
                      </div>
                    </el-form>
                  </div>
                  <div class="addtable">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <!-- 分页 -->
            <div class="currentpage">
              <button
                class="pagination"
                @click="goToPreviousPage()"
                :disabled="currentPage === 1"
                :class="{ 'pagination-hover': currentPage !== 1 }"
              >上一页</button>
              <span
                v-for="page in pages"
                :key="page"
              >
                <button
                  @click="goToPage(page)"
                  class="pagination2"
                  :class="{ 'pagination-active': page === currentPage }"
                >{{
                                    page }}</button>
              </span>
              <button
                class="pagination"
                @click="goToNextPage()"
                :class="{ 'pagination-hover': currentPage !== totalPages }"
                :disabled="currentPage === totalPages"
              >下一页</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import excel from '@/libs/excel'
export default {
  data() {
    return {
      alldelete: false,
      addshow: false,
      name: '111',
      checkAll: false,
      networkIDList: [],
      fnetworkList: [],
      networkList: [],
      networkName: '',
      isuper: '',
      search: '',
      project: '',
      projectList: [],
      projectList2: [],
      label: '',
      firm: '',
      labelList: [],
      firmList: [],
      value: '',
      form: {
        name: '',
        project: '',
        label: '',
        IPaddress: '',
        IPstart: '',
        IPend: '',
      },
      rules: {
        name: [
          { required: true, message: '请输入项目名称', trigger: 'blur' },
          { min: 1, max: 18, message: '长度在 1 到 6 个字符', trigger: 'blur' }
        ],
        project: [
          { required: true, message: '请选择项目名称', trigger: 'blur' },
        ],
        label: [
          { message: '请输入网络描述', trigger: 'blur' },
          { min: 0, max: 50, message: '长度在 0 到 50 个字符', trigger: 'blur' }
        ],
        IPaddress: [
          { required: true, message: '请输入IP网段', trigger: 'blur' },
        ],
        IPstart: [
          { required: true, message: '请输入IP起始地址', trigger: 'blur' },
        ],
        IPend: [
          { required: true, message: '请输入IP结束地址', trigger: 'blur' },
        ],
      },
      currentPage: 1,
      itemsPerPage: 16
    }
  },
  created() {
    this.$js.token();
    sessionStorage.setItem('tabid', '21');
    sessionStorage.setItem('path', '/Networks');
    let page = JSON.parse(sessionStorage.getItem('netpage'));
    this.currentPage = page ? page : '1'
    this.changeheight()
    this.address()
    this.networks()
    this.getproject()
  },
  computed: {
    displayedBoxes() {
      const startIndex = this.currentPage * this.itemsPerPage - this.itemsPerPage
      const endIndex = startIndex + this.itemsPerPage
      return this.fnetworkList.slice(startIndex, endIndex)
    },
    totalPages() {
      return Math.ceil(this.fnetworkList.length / this.itemsPerPage)
    },
    pages() {
      const pages = [1];
      for (let i = 2; i <= this.totalPages; i++) {
        pages.push(i);
      }
      return pages;
    }
  },
  methods: {
    goToPreviousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    goToNextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    goToPage(page) {
      if (page >= 1 && page <= this.totalPages) {
        this.currentPage = page;
      }
    },
    // 获取页面大小
    changeheight() {
      if (document.documentElement.clientHeight <= 800 && document.documentElement.clientHeight > 750) {
        this.itemsPerPage = 12
      } else if (document.documentElement.clientHeight <= 750 && document.documentElement.clientHeight > 720) {
        this.itemsPerPage = 11
      } else if (document.documentElement.clientHeight < 720) {
        this.itemsPerPage = 7
      }
    },
    // 取消操作
    cancel() {
      this.addshow = false
    },
    // 添加网络
    async addnet() {
      let data = JSON.parse(sessionStorage.getItem('user'));
      let userid = data.id
      let username = data.name
      let parentid = data.parentid
      let firm = data.firm
      let IPaddress = this.form.IPaddress
      let netname = this.form.name
      let label = this.form.label
      let project = this.form.project
      let IPstart = this.form.IPstart
      let IPend = this.form.IPend
      let res = await this.$apiFun.addnet({ netname, userid, username, parentid, firm, IPaddress, label, project, IPstart, IPend });
      alert(res);
      location.reload()
    },
    //生成网络地址
    random() {
      let random1 = Math.floor(Math.random() * 220 + 10);
      let random2 = Math.floor(Math.random() * 220 + 10);
      this.form.IPaddress = '10' + '.' + random1 + '.' + random2 + '.' + '0/24'
      this.form.IPstart = '10' + '.' + random1 + '.' + random2 + '.' + '1'
      this.form.IPend = '10' + '.' + random1 + '.' + random2 + '.' + '254'
    },
    // 表单确认
    submitForm(formName) {
      this.random();
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.addnet()
        } else {
          return false;
        }
      });
    },
    // 搜索功能
    handleSearch() {
      this.fnetworkList = [];
      this.currentPage = 1
      this.networkList.map(item => {
        if (Object.values(item).some(value => value.toString().indexOf(this.search) !== -1)) {
          this.fnetworkList.push(item);
        }
      });
    },
    //导出操作
    exportExcel() {
      let List = []
      const startIndex = this.currentPage * this.itemsPerPage - this.itemsPerPage
      const endIndex = startIndex + this.itemsPerPage
      this.fnetworkList.slice(startIndex, endIndex).forEach(item => {
        if (item.checked == true) {
          List.push(item)
        }
      })
      if (List.length > 0) {
        const params = {
          title: ['网络id', '网络名称', '编号', '所属项目', '标签'],
          key: ['nwid', 'netname', 'netindex', 'project', 'label'],
          data: List, // 数据源
          autoWidth: true, //autoWidth等于true，那么列的宽度会适应那一列最长的值
          filename: '网络列表'
        }
        excel.exportArrayToExcel(params)
      } else {
        alert('请勾选需要导出的网络')
      }
    },
    // 展示添加网络
    addnetshow() {
      this.addshow = true
    },
    //详情页面跳转
    page(id) {
      this.$js.openpages('/Netdetail', { id });
      sessionStorage.setItem('netpage', this.currentPage);
    },
    //设备页跳转
    page2(id) {
      this.$js.openpages('/Equipment', { id });
      sessionStorage.setItem('netpage', this.currentPage);
    },
    //获取状态
    async address() {
      let res = await this.$apiFun.address();
    },
    // 全选
    checkall() {
      this.fnetworkList.forEach(item => item.checked = this.checkAll)
    },
    // 复制
    copy(id) {
      //创建一个input框
      const input = document.createElement("input")
      //将指定的DOM节点添加到body的末尾
      document.body.appendChild(input)
      //设置input框的value值为直播地址
      input.value = id;
      //选取文本域中的内容
      input.select()
      //copy的意思是拷贝当前选中内容到剪贴板
      //document.execCommand（）方法操纵可编辑内容区域的元素
      //返回值为一个Boolean，如果是 false 则表示操作不被支持或未被启用
      if (document.execCommand("copy")) {
        document.execCommand("copy")
      }
      //删除这个节点
      document.body.removeChild(input)
      this.$message('复制成功');
      // navigator.clipboard.writeText(id).then(() => {
      //     alert('复制成功')
      // })
    },
    //获取所有项目
    async getproject() {
      let data = JSON.parse(sessionStorage.getItem('user'));
      let userid = data.id
      let res = await this.$apiFun.getproject({ userid })
      this.projectList = res
      this.projectList2 = JSON.parse(JSON.stringify(res))
      this.projectList.unshift({ id: '9999999', pjname: '全部项目' })
    },
    //获取所有网络列表
    async networks() {
      let data = JSON.parse(sessionStorage.getItem('user'));
      let userid = data.id
      this.isuper = data.issuper
      let issuper = data.issuper
      let res = await this.$apiFun.networks({ userid });
      this.networkList = res
      //生成checked
      this.networkList.forEach(item => this.$set(item, 'checked', false))
      // 生成notice
      this.networkList.forEach(item => this.$set(item, 'notice', false))
      //排序按时间久到新排序
      this.networkList.sort((a, b) => a.creationTime - b.creationTime)
      this.fnetworkList = this.networkList
      const labelSet = new Set();
      const firmSet = new Set();
      this.networkList.forEach(item => {
        labelSet.add(item.label);
        firmSet.add(item.firm);
      });
      labelSet.forEach(label => {
        if (label != '') {
          this.labelList.push({ label });
        }
      });
      this.labelList.unshift({ label: '所有描述' })
      firmSet.forEach(firm => {
        if (firm != '') {
          this.firmList.push({ firm });
        }
      });
      this.firmList.unshift({ firm: '所有公司' })
    },
    //改变项目
    changeproject(project, label) {
      this.currentPage = 1
      if (project == '全部项目') {
        this.fnetworkList = this.networkList.filter(item => (label == '所有描述' || label == '' || item.label == label))
      } else {
        this.fnetworkList = this.networkList.filter(item => (item.project == project && (label == '所有描述' || label == '' || item.label == label)))
      }
    },
    //改变标签项目
    changelabel(label, project) {
      this.currentPage = 1
      if (label == '所有描述') {
        this.fnetworkList = this.networkList.filter(item => (project == '全部项目' || project == '' || item.project == project))
      } else {
        this.fnetworkList = this.networkList.filter(item => (item.label == label && (project == '全部项目' || project == '' || item.project == project)))
      }
    },
    //改变标签项目
    changefirm(firm) {
      this.currentPage = 1
      if (firm == '所有公司') {
        this.fnetworkList = this.networkList
      } else {
        this.fnetworkList = this.networkList.filter(item => (item.firm == firm))
      }
    },
    //批量删除
    deleteallshow() {
      const allFalse = this.fnetworkList.every(obj => obj.checked === false);
      if (allFalse) {
        alert('请选择需要删除的网络')
      } else {
        this.alldelete = true
      }
    },
    deleteallcancel() {
      this.alldelete = false
    },
    deleteallenter() {
      const startIndex = this.currentPage * this.itemsPerPage - this.itemsPerPage
      const endIndex = startIndex + this.itemsPerPage
      this.fnetworkList.slice(startIndex, endIndex).forEach(async item => {
        if (item.checked == true) {
          let id = item.nwid
          let res = await this.$apiFun.networksDelete({ id });
          alert(res)
        }
      })
      this.alldelete = false
    },
    // 展示删除框
    shownotice(item) {
      if (item.notice) {
        item.notice = !item.notice
      } else {
        this.fnetworkList.forEach(item => {
          item.notice = false
        })
        item.notice = !item.notice
      }
    },
    //删除网络
    async networksDelete(id) {
      let res = await this.$apiFun.networksDelete({ id });
      alert(res)
      location.reload()
    },
    // 获取时间
    formatDate(timestamp) {
      var date = new Date(timestamp);
      var year = date.getFullYear();
      var month = ("0" + (date.getMonth() + 1)).slice(-2); // 月份从0开始，需要加1
      var day = ("0" + date.getDate()).slice(-2);
      return year + "-" + month + "-" + day;
    }
  }
}
</script>

<style scoped>
.content-box {
  min-width: 1300px;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  padding: 0 1.1%;
  background-color: white;
  overflow-y: hidden;
  overflow-x: auto;
  white-space: nowrap;
}

.tab {
  font-size: 12px;
  padding-top: 10px;
  height: 38.8px;
  line-height: 38.8px;
}

.title-box {
  margin: 20px 0px 10px;
  height: 30px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ebeef5;
}

.title {
  font-size: 14px;
  font-weight: bold;
}

.btn-box {
  margin: -15px 0px 0px 0px;
  display: flex;
}

.exportimg {
  width: 14px;
  height: 14px;
  margin-right: 10px;
}

.btn {
  display: flex;
  align-items: center;
  padding: 0px 16px;
  height: 32px;
  line-height: 32px;
  margin: 0px 10px 0px 0px;
  border: 1px solid #e7e9f1;
  cursor: pointer;
  font-size: 14px;
  color: #666666;
}

.networkListtitle {
  flex-shrink: 0;
  font-size: 14px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  padding: 8px;
  font-weight: bold;
  border-top: 1px solid #e7e9f1;
  border-bottom: 1px solid #e7e9f1;
  background-color: #fafafb;
}

.networkList {
  font-size: 14px;
  display: flex;
  flex-shrink: 0;
  padding: 8px;
  flex-shrink: 0;
  border-bottom: 1px solid #ebeef5;
}

.networkList:hover {
  background: #fafafb;
}

.networkid {
  width: 320px;
  flex-shrink: 0;
  margin-top: 1px;
}

.networkproject {
  width: 20.5%;
  min-width: 175.72px;
  flex-shrink: 0;
  margin-top: 1px;
}

.networklabel {
  width: 265px;
  flex-shrink: 0;
  margin-top: 1px;
}

.networkname {
  width: 22%;
  min-width: 190.69px;
  flex-shrink: 0;
  margin-top: 1px;
}

.networkoperate {
  position: relative;
  width: 200px;
  display: flex;
  justify-content: space-between;
  white-space: nowrap;
  flex-shrink: 0;
  margin-top: 1px;
}

.notice {
  z-index: 999;
  background-color: #012f55;
  border: 1px solid #ebeef5;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

.deleteallbox {
  font-size: 14px;
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
  background-color: white !important;
}

.networkbtn {
  color: #337ab7;
  border: none;
  cursor: pointer;
  height: 100%;
}

.overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  /* 半透明黑色背景 */
  z-index: 999;
  /* 确保在上层 */
}

.addbox {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  z-index: 999;
  background-color: white;
}

.addtitle {
  margin-bottom: 40px;
  margin-left: -65px;
  padding: 25px 0px 0px 15px;
  font-size: 14px;
  font-weight: bold;
}

.addtable {
  padding: 0px 80px;
}

.addbtn {
  height: 30px;
  line-height: 30px;
  background: #fafafa;
  padding: 0px 25px;
  font-size: 14px;
  border: 1px solid #dcdcdc;
  margin-left: 10px;
  cursor: pointer;
  margin-bottom: 20px;
}

.networkbtn:hover {
  color: #70c31d;
}

.inputcheck {
  display: flex;
}

.select-box {
  display: flex;
}

.select {
  margin: 0px 10px 20px 0px;
}

.search {
  margin: 0px 10px 20px 0px;
  line-height: 32px;
  padding: 0 16px;
  background-color: #012f55;
  color: white;
  cursor: pointer;
}

.IPbtn {
  height: 38px;
  font-size: 14px;
  padding: 0 5px;
  color: #333333;
  color: #7b7b7b;
  background-color: #fafafa;
  border: 1px solid #dcdcdc;
  border-radius: 4px;
  margin-left: 12px;
}

.select-box:deep(.el-input__inner) {
  width: 150px;
  height: 32px;
}

.select-box:deep(.el-input__icon) {
  line-height: 32px;
}

.select:deep(.el-input__inner:focus) {
  border-color: #012f55;
}

.select:deep(.el-input__inner:hover) {
  border-color: #012f55;
}

.select:deep(.el-select:hover .el-input__inner) {
  border-color: #012f55;
}

.IPaddress:deep(.el-input) {
  width: 69%;
}

.IPaddress:deep(.el-form-item__content) {
  display: flex;
}

.select:deep(.el-input.is-focus .el-input__inner) {
  border-color: #012f55;
}

.select:deep(.el-input__inner) {
  line-height: 32px;
}

.inputcheck:deep(.el-checkbox__inner) {
  background: #edf2fc;
  border-color: #dcdfe6;
}

.addtable:deep(.el-select > .el-input) {
  width: 345px;
}

.addtable:deep(.el-textarea__inner:focus) {
  border-color: #012f55;
}

.addtable:deep(.el-input__inner:hover) {
  border-color: #012f55;
}

.addtable:deep(.el-textarea__inner:hover) {
  border-color: #012f55;
}

.addtable:deep(.el-select:hover .el-input__inner) {
  border-color: #012f55;
}

.addtable:deep(.el-input__inner:focus) {
  border-color: #012f55;
}

.addtable:deep(.el-input.is-focus .el-input__inner) {
  border-color: #012f55;
}

.inputcheck:deep(.el-checkbox__inner) {
  background: #edf2fc;
  border-color: #dcdfe6;
}

.addbox:deep(.el-form-item) {
  margin-bottom: 35px;
}

.inputcheck:deep(.el-checkbox__input.is-focus .el-checkbox__inner) {
  border-color: #012f55;
}

.inputcheck:deep(
    .el-checkbox__input.is-checked .el-checkbox__inner,
    .el-checkbox__input.is-indeterminate .el-checkbox__inner
  ) {
  background: #012f55;
  border-color: #012f55;
}

@media screen and (max-width: 1700px) {
  .networkid {
    width: 220px;
    /* width: 16.9%; */
    flex-shrink: 0;
  }
}

@media screen and (max-width: 1600px) {
  .networkListtitle {
    padding: 0 8px;
    background-color: none;
  }

  .networklabel {
    width: 13%;
    flex-shrink: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-top: 1px;
  }

  .networkListtitle .inputcheck,
  .networkListtitle .networkname,
  .networkListtitle .networklabel,
  .networkListtitle .networkproject,
  .networkListtitle .networkoperate {
    box-sizing: border-box;
    padding: 8px 0;
    background-color: #fafafb;
  }

  .select-box:deep(.el-input__inner) {
    width: 120px;
    height: 28px;
  }

  .select-box:deep(.el-input) {
    font-size: 12px !important;
  }

  .select-box:deep(.el-input__icon) {
    line-height: 28px;
  }

  .search {
    font-size: 13px;
    margin: 0px 10px 20px 0px;
    line-height: 28px;
    padding: 0 16px;
    background-color: #012f55;
    color: white;
    cursor: pointer;
  }

  .btn {
    font-size: 13px;
    height: 28px;
    line-height: 28px;
  }
}
</style>
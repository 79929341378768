<template>
        <div class="header">
            <div class="img-box">
                <img class="img" src="../assets/img/Logo.png" alt="无涯智能官网--无涯智能集研发、生产、销售于一体，主营无线联网通讯模块和设备" border="0" />
            </div>
            <div class="header-box">
                <img class="header-img" src="../assets/img/earth.png" border="0" />
                <div class="font">English</div>
            </div>
        </div>
</template>
<script>
export default {
    data() {
        return {

        }
    },
}
</script>
<style scoped>

.header {
    padding: 0px 20px 0px;
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.img-box{
    height: 40px;
}

.header-box {
    position: relative;
    font-size: 12px;
    height: 40px;
    line-height: 40px;
    padding: 0 20px;
    width: auto;
    display: flex;
}

.header-img {
    position: absolute;
    top: 8px;
    width: 23px;
    height: 23px;
}
.font{
    margin-left: 30px;
    font-size: 18px;
    color: white;
}
.img{
height: 40px;
}
@media screen and (max-width: 1700px) {
    .img{
height: 30px;
}
    .header {
    padding: 7.5px 15px 0px;
    height: 60px;
    height: 8.282%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.img-box{
    height: 30px;
}

.header-box {
    position: relative;
    font-size: 9px;
    height: 30px;
    line-height: 30px;
    padding: 0 15px;
    width: auto;
    display: flex;
}

.header-img {
    position: absolute;
    top: 6px;
    width: 17.25px;
    height: 17.25px;
}
.font{
    margin-left: 22.5px;
    font-size: 13.5px;
    color: white;
}
}
</style>
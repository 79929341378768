<template>
    <div class="view">
        <!-- 页头 -->
        <nav-header></nav-header>
        <div class="content-box">
            <div class="img-box"></div>
            <!-- 登录功能 -->
            <div class="login-box">
                <div class="login-title">无涯通行证</div>
                <div class="login-type">
                    <div class="type" @click="changeshowid2()">密码登录</div>
                    <div class="type" @click="changeshowid1()">短信登录</div>
                    <div v-if="showid == 1" class="login-left-line"></div>
                    <div v-if="showid == 2" class="login-right-line"></div>
                </div>
                <div v-if="showid == 1">
                    <el-form :rules="rules" ref="form" :model="form" class="form">
                        <el-form-item prop="name" class="el-title input">
                            <el-input v-model="form.name" placeholder="请输入用户名/手机号"></el-input>
                        </el-form-item>
                        <el-form-item prop="password" class="el-title input">
                            <el-input @keyup.enter.native="submitForm('form')" placeholder="请输入密码"
                                v-model="form.password" show-password></el-input>
                        </el-form-item>
                        <el-form-item class="el-title input">
                            <el-button style="background-color: #43A92A;border-color: #43A92A" type="primary"
                                @click="submitForm('form')">登录</el-button>
                        </el-form-item>
                    </el-form>
                </div>
                <div v-if="showid == 2">
                    <el-form :rules="rules" ref="form" :model="form" class="form">
                        <el-form-item prop="mobile" class="el-title input">
                            <el-input v-model="form.mobile" placeholder="请输入手机号"></el-input>
                        </el-form-item>
                        <div class="mobile">
                            <el-form-item style="flex: 1;" prop="code" class="el-title input">
                                <el-input @keyup.enter.native="submitForm('form')" placeholder="请输入验证码"
                                    v-model="form.code">
                                </el-input>
                                <div @click="mobile(form.mobile)" class="inputend">
                                    <span style="color: #dedede;">|</span>
                                    <span class="inputtime">{{ form.time }}</span>
                                </div>
                                <div v-if="ifmobile == 1" class="inputnotice">验证码发送成功，5分钟内输入有效</div>
                            </el-form-item>
                        </div>
                        <el-form-item class="el-title input">
                            <el-button style="background-color: #43A92A;border-color: #43A92A" type="primary"
                                @click="submitForm('form')">登录</el-button>
                        </el-form-item>
                    </el-form>
                </div>
                <div class="login-bottom">
                    <div class="login-content" @click="enrollForm()">注册通行证</div>
                    <div class="login-content2">|</div>
                    <div class="login-content" @click="forgetForm()">忘记密码</div>
                </div>
            </div>
        </div>
        <div class="footer">
            <div>
                <span>关于我们</span>
                <span> | </span>
                <span><a style="color: #7b7b7b;" href="https://www.hi-wooya.com/" target="_blank">无涯官网</a></span>
            </div>
            <div>
                <span>版权所有©四川无涯智能科技有限公司( 蜀ICP备20020584号-1)</span>
            </div>
        </div>
    </div>
</template>

<script>
import CryptoJS from 'crypto-js';
export default {
    data() {
        return {
            ifmobile: '0',
            showid: '1',
            buttonstyle: 'not-allowed',
            timeback: '60',
            form: {
                name: '',
                password: '',
                mobile: '',
                code: '',
                time: '获取验证码'
            },
            rules: {
                name: [
                    { required: true, message: '请输入用户名/手机号', trigger: 'blur' },
                    { min: 5, max: 18, message: '长度在 5 到 18 个字符', trigger: 'blur' }
                ],
                password: [
                    { required: true, message: '请输入密码', trigger: 'blur' },
                    { min: 5, max: 18, message: '长度在 5 到 18 个字符', trigger: 'blur' }
                ],
                mobile: [
                    { required: true, message: '请输入手机号', trigger: 'blur' },
                    { min: 11, max: 11, message: '请输入正确的手机号', trigger: 'blur' }
                ],
                code: [
                    { required: true, message: '请输入验证码', trigger: 'blur' },
                    { min: 4, max: 8, message: '请输入正确的验证码', trigger: 'blur' }
                ],
            }
        }
    },
    created() {
        
    },
    methods: {
        //测试
        async mobile(mobile) {
            if (this.form.mobile.length == 11 && this.form.time == '获取验证码') {
                let res = await this.$apiFun.mobilelogin({ mobile });
                if (res != '提交成功') {
                    alert(res)
                } else {
                    this.form.time = '重新获取(' + this.timeback + ')s'
                    this.timeback -= 1
                    let timer = setInterval(() => {
                        if (this.timeback > 0) {
                            // this.form.time = t his.form.time-1
                            this.form.time = '重新获取(' + this.timeback + ')s'
                            this.timeback -= 1
                        } else {
                            clearInterval(timer);
                            this.timeback = 60
                            this.form.time = '获取验证码'
                        }
                    }, 1000);
                    this.ifmobile = 1
                }
            } else {
                alert('请输入正确的手机号')
            }
        },
        // 登录
        async login(name, passwordo, mobile, id, code) {
            if (this.showid == 2 && this.ifmobile == 0) {
                alert('请先获取验证码')
            } else {
                //进行前端密码加密
                const secretKey = 'ahiwooyaclodKeya'
                const password = CryptoJS.AES.encrypt(passwordo, secretKey).toString();
                let res = await this.$apiFun.login({ name, password, mobile, id, code });
                if (res === '用户名或密码错误' || res === '验证码不正确' || res === '手机号错误' || res === '用户名不存在') {
                    alert(res)
                } else {
                    sessionStorage.setItem('user', JSON.stringify(res));
                    sessionStorage.removeItem('tabid')
                    sessionStorage.removeItem('tabList')
                    this.$js.openpages('/networksindex');
                }
            }
        },
        // 表单登录
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.login(this.form.name, this.form.password, this.form.mobile, this.showid, this.form.code)
                } else {
                    return false;
                }
            });
        },
        // 表单注册
        enrollForm() {
            this.$js.openpages('/Enrollsuper');
        },
        // 表单忘记
        forgetForm() {
            this.$js.openpages('/Forgot');
        },
        changeshowid1() {
            this.resetForm()
            this.showid = 2
        },
        changeshowid2() {
            this.resetForm()
            this.showid = 1
        },
        //重置表单
        resetForm() {
            this.$refs['form'].resetFields();
        }
    },
    watch: {
        'form.mobile': {
            handler(newValue, oldValue) {
                if (newValue.length === 11) {
                    this.buttonstyle = 'pointer'
                } else {
                    this.buttonstyle = 'not-allowed'
                }
            }
        },
        'form.time': {
            handler(newValue, oldValue) {
                if (newValue === '获取验证码') {
                    this.buttonstyle = 'pointer'
                } else {
                    this.buttonstyle = 'not-allowed'
                }
            }
        }
    }
}
</script>


<style scoped>
.view {
    width: 100%;
    height: 100vh;
    background-image: url(../assets/img/Background.jpg);
    background-position: 50%;
    background-size: cover;
}

.content-box {
    width: 72.2%;
    height: 85%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 25px auto 0px;
}

.img-box {
    width: 780px;
    height: 690px;
}

.login-box {
    width: 500px;
    height: 500px;
    background-color: #012f55;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, .1);
    border-radius: 8px;
    margin-top: -4%;
}

.login-title {
    text-align: center;
    font-size: 30px;
    font-weight: bold;
    padding-top: 60px;
    margin-bottom: 20px;
    color: white;
}

.login-type {
    position: relative;
    width: 420px;
    height: 41px;
    margin: 40px auto 30px;
    display: flex;
    justify-content: center;
}

.login-left-line {
    position: absolute;
    top: 40px;
    left: 137px;
    width: 30px;
    height: 2px;
    background-color: #43A92A;
}

.login-right-line {
    position: absolute;
    top: 40px;
    right: 137px;
    width: 30px;
    height: 2px;
    background-color: #43A92A;
}

.type {
    font-size: 16px;
    font-weight: bold;
    padding: 0px 26px;
    line-height: 41px;
    cursor: pointer;
    color: white;
}

.form {
    width: 420px;
    margin: 0 auto !important;
}

.input {
    height: 50px;
}

.inputend {
    position: absolute;
    right: 15px;
    top: 0px;
    height: 100%;
    display: flex;
    align-items: center;
    color: #012f55;
}

.inputnotice {
    position: absolute;
    height: 12px;
    left: 0;
    bottom: -16px;
    font-size: 12px;
    color: white;
    line-height: 12px;
}

.login-bottom {
    width: 420px;
    height: 40px;
    margin: 0 auto;
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
}

.login-content {
    font-size: 14px;
    line-height: 40px;
    color: white;
    cursor: pointer;
}


.login-content2 {
    font-size: 14px;
    line-height: 40px;
    color: white;
    margin: 0 5px;
}

.mobile {
    display: flex;
    justify-content: space-between;
}


.inputtime {
    cursor: pointer;
    height: 16px;
    line-height: 16px;
    padding-left: 14px;
    font-size: 16px;
}


.footer {
    position: fixed;
    text-align: center;
    font-size: 14px;
    color: #7b7b7b;
    width: 100%;
    margin: 0px auto;
    bottom: 0px;
    left: 0px;
}

.login-box:deep(.el-input__inner) {
    background-color: #f3f4f9;
    height: 50px;
}

.login-box:deep(.el-button) {
    width: 420px;
    height: 50px;
}

.login-box:deep(.el-input__inner:focus) {
    border-color: #90c31d
}

@media screen and (max-width: 1700px) {
    .view {
        width: 100%;
        height: 724.5px;
        background-image: url(../assets/img/Background.jpg);
        background-position: 50%;
        background-size: cover;
    }

    .inputtime {
        cursor: pointer;
        height: 12px;
        line-height: 12px;
        padding-left: 14px;
        padding-top: 3px;
        font-size: 12px;
    }

    .login-box {
        width: 375px;
        height: 380px;
        background-color: #012f55;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, .1);
        border-radius: 8px;
    }

    .login-title {
        text-align: center;
        font-size: 22.5px;
        font-weight: bold;
        padding-top: 45px;
        margin-bottom: 15px;
        color: white;
    }

    .login-type {
        position: relative;
        width: 315px;
        height: 30.75px;
        margin: 30px auto 22.5px;
        display: flex;
        justify-content: center;
    }

    .login-left-line {
        position: absolute;
        top: 30px;
        left: 102.75px;
        width: 22.5px;
        height: 2px;
        background-color: #43A92A;
    }

    .login-right-line {
        position: absolute;
        top: 30px;
        right: 102.75px;
        width: 22.5px;
        height: 2px;
        background-color: #43A92A;
    }

    .type {
        font-size: 12px;
        font-weight: bold;
        padding: 0px 19.5px;
        line-height: 30.75px;
        cursor: pointer;
        color: white;
    }

    .form {
        width: 315px;
        margin: 0 auto !important;
    }

    .input {
        height: 22.5px;
    }

    .login-bottom {
        width: 315px;
        height: 30px;
        margin: 0 auto;
        margin-top: 7.5px;
        display: flex;
        justify-content: flex-end;
    }

    .login-content {
        font-size: 10.5px;
        line-height: 30px;
        color: white;
        cursor: pointer;
    }

    .login-content2 {
        font-size: 10.5px;
        line-height: 30px;
        color: white;
        margin: 0 5px;
    }

    .mobile {
        display: flex;
        justify-content: space-between;
    }

    .footer {
        position: fixed;
        text-align: center;
        font-size: 10.5px;
        color: #7b7b7b;
        width: 100%;
        margin: 0px auto;
        bottom: 0px;
        left: 0px;
    }

    .login-box:deep(.el-input__inner) {
        background-color: #f3f4f9;
        height: 37.5px;
    }

    .login-box:deep(.el-form-item) {
        margin-bottom: 38px
    }

    .login-box:deep(.el-button) {
        width: 315px;
        height: 37.5px;
    }

    .login-box:deep(.el-input__inner:focus) {
        border-color: #90c31d
    }
}

@media screen and (max-width: 1400px) {
    .view {
        height: 600px;
    }

    .img-box {
        width: 468px;
        height: 414px;
    }

    .login-box {
        width: 300px;
        height: 300px;
    }

    .login-title {
        font-size: 18px;
        padding-top: 36px;
        margin-bottom: 12px;
    }

    .login-type {
        width: 252px;
        height: 24.6px;
        margin: 24px auto 18px;
    }

    .login-left-line {
        top: 24px;
        left: 82.2px;
        width: 18px;
        height: 1.2px;
    }

    .login-right-line {
        top: 24px;
        right: 82.2px;
        width: 18px;
        height: 1.2px;
    }

    .type {
        font-size: 9.6px;
        padding: 0px 15.6px;
        line-height: 24.6px;
    }

    .form {
        width: 252px;
    }

    .input {
        height: 30px;
    }

    .login-bottom {
        width: 252px;
        height: 24px;
        margin-top: 6px;
    }

    .login-content {
        font-size: 8.4px;
        line-height: 24px;
    }


    .login-content2 {
        font-size: 8.4px;
        line-height: 24px;
        margin: 0 3px;
    }


    .footer {
        font-size: 8.4px;
        width: 100%;
        margin: 0px auto;
        bottom: 0px;
        left: 0px;
    }

    .login-box:deep(.el-input__inner) {
        height: 30px;
    }

    .login-box:deep(.el-button) {
        width: 252px;
        height: 30px;
        line-height: 8px;
    }

    .login-box:deep(.el-input__inner:focus) {
        border-color: #90c31d
    }

    .login-box:deep(.el-form-item) {
        margin-bottom: 20px
    }

}
</style>
<template>
    <div id="container">
    </div>
</template>

<script>
import AMapLoader from '@amap/amap-jsapi-loader'
window._AMapSecurityConfig = {
    securityJsCode: '945ecc9cdac7abf98f870c2e58103cb5'
}
export default {
    data() {
        return {
            AMap: null,
            map: null,
            maker: null,
            geocoder: null,
            placeSearch: null,
            district: null,
            polygons: [],
            lnglat: []
        }
    },
    props: ['changeprovince'],
    methods: {
        initMap() {
            AMapLoader.load({
                key: '9b1633822a3b0ee621e86959679bbe6f', // 申请好的Web端开发者Key，首次调用 load 时必填
                version: '2.0', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
                plugin: ['AMap.ControlBar', "AMap.HawkEye", 'AMap.DistrictSearch']
            })
                .then(AMap => {
                    this.AMap = AMap
                    this.map = new AMap.Map('container', {
                        //设置地图容器id
                        mapStyle: 'amap://styles/c09e8fb787fe168a2c5737afdb7f8c96',
                        viewMode: '3D', //是否为3D地图模式
                        zoom: 4.6, //初始化地图级别
                        expandZoomRange: true,
                        center: [100.977391, 35.441443], //初始化地图中心点位置
                    });
                    AMap.plugin(['AMap.ControlBar', "AMap.HawkEye", 'AMap.DistrictSearch'], () => {
                        var autoOptions = {
                            city: '全国',
                            // map: this.map, // 展现结果的地图实例
                            pageSize: 10, // 单页显示结果条数
                            pageIndex: 1, // 页码
                            autoFitView: false // 是否自动调整地图视野使绘制的 Marker点都处于视口的可见范围
                        };
                        let controlBar = new AMap.ControlBar({
                            visible: true,
                            position: {
                                top: '10px',
                                right: '10px'
                            }
                        })
                        // this.map.addControl(new AMap.HawkEye({ isOpen: true }));
                        this.map.addControl(controlBar);
                        // this.map.addControl(new AMap.ToolBar({ position: 'RB', offset: [20, 60] }));
                        // this.map.addControl(new AMap.Scale({ position: 'RB' }));
                        let opts = {
                            subdistrict: 0,   //获取边界不需要返回下级行政区
                            // 是否返回行政区边界坐标点
                            extensions: "all",
                            // 设置查询行政区级别为 区 
                            level: "province",
                        };
                        // 创建DistrictSearch对象
                        this.district = new AMap.DistrictSearch(opts);
                    })
                })
                .catch(e => {
                    console.log(e)
                })
        },
        async getequipment() {
            let data = JSON.parse(sessionStorage.getItem('user'));
            let userid = data.id
            let res = await this.$apiFun.getequipment({ userid })
            res.forEach(async (item) => {
                let res2 = await this.$apiFun.peer({ mid: item.equipmentid });
                let position = item.position.split(",");
                let title = item.name
                let image = "https://cloud.hi-wooya.com/img/onicon.png"
                if (Object.keys(res2).length > 0) {
                    image = "https://cloud.hi-wooya.com/img/onicon.png"
                } else {
                    image = "https://cloud.hi-wooya.com/img/officon.png"
                }
                const icon = new this.AMap.Icon({
                    image: image, //Icon 的图像
                });
                let marker = new this.AMap.Marker({
                    offset: new this.AMap.Pixel(-13, -30), //偏移量
                    icon: icon,
                    position: position,   // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
                    title: title
                });
                this.map.add(marker);
            });
        },
        mapaddress(address) {
            this.$emit('mapaddress', address)
        }

    },
    mounted() {
        //DOM初始化完成进行地图初始化
        this.initMap()
        setTimeout(() => {
            this.getequipment()
        }, 1000);
    },
    watch: {
        changeprovince(newValue, oldValue) {
            if (newValue != '全国') {
                this.district.search(newValue, (status, result) => {
                    this.map.remove(this.polygons)
                    this.polygons = [];
                    let bounds = result.districtList[0].boundaries;
                    for (let i = 0; i < bounds.length; i += 1) {
                        // 绘制边界线
                        let polygon = new this.AMap.Polygon({
                            strokeWeight: 3,
                            path: bounds[i],
                            strokeColor: '#0091ea',
                            fillColor: '#012f55',
                        });
                        this.polygons.push(polygon);
                    }
                    this.map.add(this.polygons)
                    this.map.setFitView(this.polygons);
                });
            } else {
                this.map.remove(this.polygons)
                this.map.setZoomAndCenter(4.6, [100.977391, 35.441443]);
            }
        },
    }
}
</script>

<style scoped>
#container {
    padding: 0px;
    margin: 0px;
    width: 100%;
    height: 100%;
}
</style>
<template>
  <div id="app">
    <!-- 页头 -->
    <!-- <nav-header2></nav-header2> -->
    <!-- <side-bar></side-bar> -->
    <router-view ></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',

}
</script>

<style>

</style>

<template>
    <div style="height: 100vh;width: 100vw;">
        <!-- 页头 -->
        <nav-header2 style="position: absolute;z-index: 999;"></nav-header2>
        <div style="height: 50px;"></div>
        <div style="display: flex;height: calc(100% - 50px);width: 100%;">
            <!-- 侧边栏 -->
            <side-bar></side-bar>
            <div style="flex: 1;padding: 10px;background-color: #e4e7ed;display: flex;">
                <div class="content-box">
                    <div class="tab">
                        <span style="color: #999999;">账号与安全</span>
                        <span style="margin: 0px 10px;">></span>
                        <span>账号信息</span>
                    </div>
                    <div class="title-box">
                        <!-- 标题 -->
                        <div class="title2">账号信息</div>
                    </div>
                    <div>
                        <el-form :rules="rules" ref="form" :model="form" class="heightflex">
                            <div>
                                <h3>基本信息</h3>
                                <!-- 上传头像功能 -->
                                <div>
                                    <div class="imglabel">头像</div>
                                    <div class="head-img">
                                        <img class="realhead" v-if="avatarUrl" :src="avatarUrl">
                                        <img class="addhead" v-else src="../assets/img/addhead.png">
                                        <div @click="selectFile()" class="btn2">点击修改</div>
                                    </div>
                                </div>
                                <div>
                                    <el-form-item label="用户名" label-width="100px" class="el-title" prop="username">
                                        <span style="color: #012f55;cursor: pointer;">{{ form.name }}</span>
                                    </el-form-item>
                                </div>
                            </div>
                            <div class="form">
                                <h3>联系信息</h3>
                                <div>
                                    <!-- <div class="title">修改公司名称</div> -->
                                    <el-form-item label="公司名称" label-width="100px" class="el-title" prop="firm">
                                        <el-input placeholder="请输入公司名称" v-model="form.firm"></el-input>
                                    </el-form-item>
                                </div>
                                <div>
                                    <!-- <div class="title">修改邮箱</div> -->
                                    <el-form-item label="邮箱" label-width="100px" class="el-title" prop="email">
                                        <el-input placeholder="请输入邮箱地址" v-model="form.email"></el-input>
                                    </el-form-item>
                                </div>
                                <!-- 地区修改 -->
                                <div>
                                    <!-- <div class="title">修改所在地区</div> -->
                                    <el-form-item label="地区" label-width="100px" prop="place">
                                        <el-select v-model="form.place" placeholder="请选择地区">
                                            <el-option label="北京市" value="北京市"></el-option>
                                            <el-option label="天津市" value="天津市"></el-option>
                                            <el-option label="上海市" value="上海市"></el-option>
                                            <el-option label="重庆市" value="重庆市"></el-option>
                                            <el-option label="河北省" value="河北省"></el-option>
                                            <el-option label="山西省" value="山西省"></el-option>
                                            <el-option label="辽宁省" value="辽宁省"></el-option>
                                            <el-option label="吉林省" value="吉林省"></el-option>
                                            <el-option label="黑龙江省" value="黑龙江省"></el-option>
                                            <el-option label="福建省" value="福建省"></el-option>
                                            <el-option label="江西省" value="江西省"></el-option>
                                            <el-option label="江苏省" value="江苏省"></el-option>
                                            <el-option label="浙江省" value="浙江省"></el-option>
                                            <el-option label="安徽省" value="安徽省"></el-option>
                                            <el-option label="山东省" value="山东省"></el-option>
                                            <el-option label="河南省" value="河南省"></el-option>
                                            <el-option label="湖北省" value="湖北省"></el-option>
                                            <el-option label="湖南省" value="湖南省"></el-option>
                                            <el-option label="广东省" value="广东省"></el-option>
                                            <el-option label="海南省" value="海南省"></el-option>
                                            <el-option label="四川省" value="四川省"></el-option>
                                            <el-option label="贵州省" value="贵州省"></el-option>
                                            <el-option label="云南省" value="云南省"></el-option>
                                            <el-option label="陕西省" value="陕西省"></el-option>
                                            <el-option label="甘肃省" value="甘肃省"></el-option>
                                            <el-option label="青海省" value="青海省"></el-option>
                                            <el-option label="台湾省" value="台湾省"></el-option>
                                            <el-option label="内蒙古自治区" value="内蒙古自治区"></el-option>
                                            <el-option label="广西壮族自治区" value="广西壮族自治区"></el-option>
                                            <el-option label="西藏自治区" value="西藏自治区"></el-option>
                                            <el-option label="宁夏回族自治区" value="宁夏回族自治区"></el-option>
                                            <el-option label="新疆维吾尔族自治区" value="新疆维吾尔族自治区"></el-option>
                                            <el-option label="香港特别行政区" value="香港特别行政区"></el-option>
                                            <el-option label="澳门特别行政区" value="澳门特别行政区"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>
                                <!-- 行业修改 -->
                                <div class="btnhelp">
                                    <!-- <div class="title">修改所属行业</div> -->
                                    <el-form-item label="所属行业" label-width="100px" prop="region">
                                        <el-select v-model="form.region" placeholder="请选择所属行业">
                                            <el-option label="生产设备监控" value="生产设备监控"></el-option>
                                            <el-option label="生产环境监控" value="生产环境监控"></el-option>
                                            <el-option label="智慧工地" value="智慧工地"></el-option>
                                            <el-option label="老旧改造" value="老旧改造"></el-option>
                                            <el-option label="电力抄表" value="电力抄表"></el-option>
                                            <el-option label="电力保护设备" value="电力保护设备"></el-option>
                                            <el-option label="电能质量管理" value="电能质量管理"></el-option>
                                            <el-option label="自主共享" value="自主共享"></el-option>
                                            <el-option label="充电桩" value="充电桩"></el-option>
                                            <el-option label="智慧校园" value="智慧校园"></el-option>
                                            <el-option label="环境监测" value="环境监测"></el-option>
                                            <el-option label="环保设备" value="环保设备"></el-option>
                                            <el-option label="畜牧养殖" value="畜牧养殖"></el-option>
                                            <el-option label="大田农村节水灌溉" value="大田农村节水灌溉"></el-option>
                                            <el-option label="农水灌溉计价系统" value="农水灌溉计价系统"></el-option>
                                            <el-option label="智慧农业大棚" value="智慧农业大棚"></el-option>
                                            <el-option label="农机/水肥一体机监控" value="农机/水肥一体机监控"></el-option>
                                            <el-option label="换热站监控" value="换热站监控"></el-option>
                                            <el-option label="供暖平衡阀" value="供暖平衡阀"></el-option>
                                            <el-option label="城市供水" value="城市供水"></el-option>
                                            <el-option label="城市综合管廊" value="城市综合管廊"></el-option>
                                            <el-option label="电气火灾监控" value="电气火灾监控"></el-option>
                                            <el-option label="消防报警主机联网" value="消防报警主机联网"></el-option>
                                            <el-option label="工业可燃气体报警" value="工业可燃气体报警"></el-option>
                                            <el-option label="消防水压监控" value="消防水压监控"></el-option>
                                            <el-option label="其他" value="其他"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </div>
                                <div @click="submitForm('form')" class="btn">保存</div>
                            </div>
                        </el-form>
                    </div>
                </div>
            </div>

        </div>

    </div>
</template>
<script>
export default {
    data() {
        return {
            data: '',
            selectedFile: null,
            avatarUrl: null,
            headname: '',
            form: {
                name: '',
                password: '',
                firm: '',
                region: '',
                email: '',
                place: '',
                mobile: '',
                code: '',
                timeback: '60',
                time: '获取验证码',
                head: '',
            },
            rules: {
                name: [
                    { required: false, message: '请输入账号', trigger: 'blur' },
                    { min: 5, max: 18, message: '长度在 5 到 18 个字符', trigger: 'blur' }
                ],
                email: [
                    { required: false, message: '请输入邮箱', trigger: 'blur' },
                    {
                        min: 5, max: 20,
                        pattern: /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
                        message: '请输入正确的邮箱', trigger: 'blur'
                    }
                ],
                firm: [
                    { required: false, message: '请输入公司名称', trigger: 'blur' },
                    { min: 1, message: '请输入正确的公司名称', trigger: 'blur' }
                ],
                region: [
                    { required: false, message: '请输入所属行业', trigger: 'blur' },
                    { min: 1, message: '请输入正确的所属行业', trigger: 'blur' }
                ],
                place: [
                    { required: false, message: '请输入地区', trigger: 'blur' },
                    { min: 1, message: '请输入正确的地区', trigger: 'blur' }
                ],
                mobile: [
                    { required: false, message: '请输入手机号', trigger: 'blur' },
                    { min: 11, max: 11, message: '请输入正确的手机号', trigger: 'blur' }
                ],
            }
        }
    },
    created() {
        this.$js.token();
        sessionStorage.setItem('tabid', '71');
        sessionStorage.setItem('path', '/Mine');
        sessionStorage.setItem('netpage', '1');
        this.data = JSON.parse(sessionStorage.getItem('user'));
        this.head = this.data.head
        this.form.name = this.data.name
        this.avatarUrl = 'https://cloud.hi-wooya.com/headimg/' + this.head
        this.form.mobile = this.data.mobile
        this.form.region = this.data.region
        this.form.firm = this.data.firm
        this.form.place = this.data.place
        this.form.email = this.data.email
    },
    methods: {
        // 跳转页面
        page(path) {
            this.$js.openpages(path);
        },
        // 用户头像
        selectFile() {
            // 创建一个<input>元素
            const input = document.createElement('input');
            input.type = 'file';
            // 触发文件选择操作
            input.click();
            // 监听文件选择
            input.addEventListener('change', (event) => {
                // 获取选择的文件
                this.selectedFile = event.target.files[0];
                this.avatarUrl = URL.createObjectURL(this.selectedFile);
                this.headname = this.selectedFile.name;
                if (this.selectedFile.type != 'image/png' && this.selectedFile.type != 'image/jpeg') {
                    this.selectedFile = null
                    this.avatarUrl = 'https://cloud.hi-wooya.com/headimg/' + this.head
                    this.headname = ''
                    alert('只支持上传png或者jpg格式文件')
                }
            });
        },
        async uploadAvatar() {
            if (this.selectedFile.type == 'image/png' || this.selectedFile.type == 'image/jpeg') {
                const formData = new FormData();
                let name = this.data.name
                let headname = this.headname
                formData.append('avatar', this.selectedFile);
                let res = await this.$apiFun.head(formData);
                let res1 = await this.$apiFun.updathead({ name, headname });
            }
        },
        async updatuser() {
            let name = this.data.name
            let firm = this.form.firm
            let email = this.form.email
            let region = this.form.region
            let place = this.form.place
            let res = await this.$apiFun.updateuser({ name, email, firm, region, place });
            sessionStorage.setItem('user', JSON.stringify(res));
            // alert('保存成功')
        },
        async save() {
            if (this.selectedFile) {
                await this.uploadAvatar()
                await this.updatuser()
                location.reload()
            } else {
                await this.updatuser()
                location.reload()
            }
        },
        // 表单保存
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.save()
                } else {
                    return false;
                }
            });
        },
    }
}
</script>
<style scoped>
.content-box {
    flex: 1;
    position: relative;
    padding: 0 1.1%;
    background-color: white;
    overflow-y: hidden;
    overflow-x: auto;
    white-space: nowrap;
}

.tab {
    font-size: 12px;
    padding-top: 10px;
    height: 38.8px;
    line-height: 38.8px;
}

.title-box {
    margin: 20px 0px 10px;
    height: 30px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ebeef5;
}

.title2 {
    font-size: 14px;
    font-weight: bold;
}


.btn {
    width: 28px;
    padding: 0px 16px;
    height: 32px;
    line-height: 32px;
    margin: 0px 0px 0px 261px;
    cursor: pointer;
    font-size: 14px;
    color: white;
    background-color: #012f55;
}

.btn2 {
    position: absolute;
    bottom: -2px;
    right: -120px;
    padding: 0px 16px;
    height: 32px;
    line-height: 32px;
    cursor: pointer;
    font-size: 14px;
    color: white;
    background-color: #012f55;
}

.imglabel {
    width: 100px;
    text-align: right;
    vertical-align: middle;
    float: left;
    font-size: 14px;
    color: #606266;
    line-height: 40px;
    padding: 0 12px 0 0;
    box-sizing: border-box;
}

.head-img {
    display: inline-block;
    width: 100px;
    height: 100px;
    border: 2px dotted black;
    margin-bottom: 20px;
    position: relative;
}

.realhead {
    width: 100px;
    height: 100px;
}

.addhead {
    width: 50px;
    height: 50px;
}

.el-title {
    width: 321px;
}

.form:deep(.el-select) {
    width: 221px;
}

.form:deep(.el-input__inner:hover) {
    border-color: #012f55;
}

.form:deep(.el-input__inner:hover) {
    border-color: #012f55;
}

.form:deep(.el-input__inner:focus) {
    border-color: #012f55;
}

.form:deep(.el-select .el-input.is-focus .el-input__inner) {
    border-color: #012f55;
}

.form:deep(.el-form-item__error) {
    top: 20px;
    left: 225px;
}


@media screen and (max-height: 720px) {

    h3 {
        margin: 10px;
    }

    .form:deep(.el-form-item__content) {
        height: 37.5px !important;
    }

    .form:deep(.el-input__inner) {
        height: 37.5px !important;
    }

    .form:deep(.el-form-item) {
        margin-bottom: 5px;
    }
    .btn{
        display: inline-block;
        margin: 0px;
        margin-left: 20px
    }
    .btnhelp{
        display: inline-block;
    }
}
</style>
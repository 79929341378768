<template>
    <div style="height: 100vh;width: 100vw;">
        <!-- 页头 -->
        <nav-header2></nav-header2>
        <div style="display: flex;height: calc(100% - 50px);width: 100%;">
            <!-- 侧边栏 -->
            <side-bar></side-bar>
            <div style="flex: 1;padding: 10px;background-color: #e4e7ed;display: flex;">
                <!-- 网络详细信息 -->
                <div class="content-box">
                    <div class="tab">
                        <span style="color: #999999;">网络管理</span>
                        <span style="margin: 0px 10px;">></span>
                        <span @click="page()" style="color: #999999;">网络列表</span>
                        <span style="margin: 0px 10px;">></span>
                        <span>网络配置</span>
                    </div>
                    <div class="title-box">
                        <!-- 标题 -->
                        <div class="title">网络配置</div>
                        <!-- 按钮 -->
                        <div class="btn-box">
                            <div class="btn3" style="color: white;background-color: #012f55;" @click="addnetshow()">添加网关
                            </div>
                            <div class="btn3" @click="addnetshow2()">IP分配池设置</div>
                            <!-- <div class="btn3">排序</div> -->
                            <!-- <div class="btn3" @click="exportExcel()">
                        <img class="exportimg" src="../assets/img/export.png">
                        导出
                    </div> -->
                        </div>
                    </div>
                    <div>
                        <div class="netbox">
                            <div class="netboxtitle">当前网络信息</div>
                            <div class="flexbox">
                                <div class="flexboxtitle">网络id</div>
                                <div>{{ netmessage.nwid }}</div>
                            </div>
                            <div class="flexbox">
                                <div class="flexboxtitle">网络名称</div>
                                <div>{{ netmessage.netname }}</div>
                            </div>
                            <div class="flexbox">
                                <div class="flexboxtitle">
                                    所属项目
                                </div>
                                <div>{{ netmessage.project }}</div>
                            </div>
                            <div class="flexbox">
                                <div class="flexboxtitle">
                                    网络描述
                                </div>
                                <div>{{ netmessage.label ? netmessage.label : '空' }}</div>
                            </div>
                            <div style="display: flex;justify-content: flex-end;">
                                <div class="btn3" @click="showchange()"
                                    style="width: fit-content;background-color: #012f55;color: white;margin-top: 10px;">
                                    修改信息</div>
                            </div>
                        </div>
                        <div class="netbox">
                            <div class="netboxtitle">当前路由信息</div>
                            <div style="border: 1px solid #e7e9f1;">
                                <div class="routes">
                                    <div class="routesbox">路由</div>
                                    <div class="viabox">网关</div>
                                    <div class="opbox">操作</div>
                                </div>
                                <div style="display: flex;padding: 8px;" v-for="(item, index) in this.netdetail.routes"
                                    :key="index">
                                    <div class="routesbox">
                                        {{ item.target }}
                                    </div>
                                    <div class="viabox">
                                        {{ item.via ? item.via : '空' }}
                                    </div>
                                    <div class="opbox">
                                        <div class="btn2" @click="changeroutes2(index)">删除</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- <div>
                <div class="networkListtitle">
                    <div class="inputcheck">
                        <div style="width: 32px;">
                        </div>
                        <div class="networkid" style="font-weight: bold;">网络id</div>
                    </div>
                    <div class="networkname">网络名称</div>
                    <div class="networkproject">所属项目</div>
                    <div class="networklabel">描述</div>
                    <div class="networkname">网关</div>
                    <div class="networkoperate" style="text-align: center;">
                        <div style="width: 200px;">操作</div>
                    </div>
                </div>
                <div class="networkListtitle" style="border: none;background: none;font-weight: normal;">
                    <div class="inputcheck">
                        <div style="width: 32px;">
                        </div>
                        <div class="networkid" style="font-weight: bold;">{{netmessage.nwid}}</div>
                    </div>
                    <div class="networkname">{{netmessage.netname}}</div>
                    <div class="networkproject">{{netmessage.project}}</div>
                    <div class="networklabel">{{netmessage.label ? netmessage.label:'无'}}</div>
                    <div class="networkname">查看</div>
                    <div class="networkoperate" style="text-align: center;">
                        <div style="width: 200px;">操作</div>
                    </div>
                </div>
            </div> -->
                    <div style="display: flex;">
                        <div style="width: fit-content;margin:30px 0px;">
                            <!-- <div style="margin-bottom: 20px;">当前网络id：{{ id }}</div> -->
                            <div>
                                <!-- 私有 -->
                                <!-- <div>
                            <span>{{ privateName }}</span>
                            <el-switch class="switch" @change="changeprivate()" v-model="privateChecked"
                                active-color="#13ce66" inactive-color="#999999">
                            </el-switch>
                        </div> -->


                                <!-- <div>
                            <div>
                                <span>IPV4分配模式</span>
                                <el-switch class="switch" @change="changeIPV4()" v-model="IPV4" active-color="#13ce66"
                                    inactive-color="#999999">
                                </el-switch>
                            </div>
                        </div> -->
                                <!-- <br> -->
                                <!-- <div>
                            <div>IPV6分配模式</div>
                            <div> <span>ZT 6plane (/每个设备80个可路由)</span>
                                <el-switch class="switch" @change="changeIPV6()" v-model="IPV61" active-color="#13ce66"
                                    inactive-color="#999999">
                                </el-switch>
                            </div>
                            <div> <span>ZT rfc4193 (/每个设备128个)</span>
                                <el-switch class="switch" @change="changeIPV6()" v-model="IPV62" active-color="#13ce66"
                                    inactive-color="#999999">
                                </el-switch>
                            </div>
                            <div> <span>从IP分配池自动分配</span>
                                <el-switch class="switch" @change="changeIPV6()" v-model="IPV63" active-color="#13ce66"
                                    inactive-color="#999999">
                                </el-switch>
                            </div>
                        </div> -->
                                <!-- <div>
                        <div>DNS</div>
                        <div>
                            域名
                            <input type="text" v-model="domain">
                        </div>
                        <div>
                            DNS服务器
                            <input type="text" v-model="servers">
                        </div>
                        <div class="btn" @click="changeDNS()">提交</div>
                    </div> -->
                            </div>
                            <!-- <div v-for="item in netMemberList" :key="item.id">
                    成员id：{{ item.id }}
                    <div>
                        经授权的
                        <input @change="changemember(item.id, item)" v-model="item.authorized" type="checkbox">
                    </div>
                    <div>
                        主动桥接
                        <input @change="changemember(item.id, item)" v-model="item.activeBridge" type="checkbox">
                    </div>
                    <div>
                        IP分配
                        {{ item.ipAssignments[0] }}
                    </div>
                    <div>
                        节点状态
                        {{ item.peer.paths[0].active }}
                    </div>
                    <div>
                        节点地址 / 延迟
                        {{ item.peer.paths[0].address }}
                    </div>
                    <div style="display: flex;gap: 10px;">
                        <div class="btn" @click="memberDelete(item.id)">删除</div>
                        <div class="btn" @click="flash()">刷新</div>
                    </div>
                </div> -->
                        </div>
                        <!-- 修改网络内容 -->
                        <div v-if="changeshow">
                            <div class="overlay"></div>
                            <div class="addbox">
                                <div v-if="addshow2 == 3" class="addtable">
                                    <div class="addtitle">网络设置</div>
                                    <div>
                                        <el-form ref="form" label-width="95px">
                                            <el-form-item label="网络名称" prop="networkname">
                                                <el-input placeholder="请输入网络名称" v-model="networkname"></el-input>
                                            </el-form-item>
                                            <el-form-item label="所属项目" prop="project">
                                                <el-select v-model="networkproject" placeholder="请选择项目">
                                                    <el-option v-for="item in projectList" :key="item.id"
                                                        :label="item.pjname" :value="item.pjname"
                                                        :disabled="item.disabled">
                                                    </el-option>
                                                </el-select>
                                            </el-form-item>
                                            <el-form-item label="网络描述" prop="networklabel">
                                                <el-input placeholder="请输入网络描述" v-model="networklabel"></el-input>
                                            </el-form-item>
                                            <div style="display: flex;justify-content: flex-end;">
                                                <div class="addbtn" @click="cancel2()">取消</div>
                                                <div class="addbtn" @click="networkchange()"
                                                    style="background-color: #012f55;color: white;">确认
                                                </div>
                                            </div>
                                            <!-- <div class="btn" @click="changeroutes()">添加</div> -->
                                        </el-form>
                                    </div>
                                </div>
                                <div v-if="addshow2 == 2" class="addtable">
                                    <div class="addtitle">网络设置</div>
                                    <div class="box">
                                        <el-form ref="form" label-width="95px">
                                            <el-form-item label="IP网段" prop="IPaddress">
                                                <el-input placeholder="请输入网络名称" v-model="address"></el-input>
                                                <div class="btn1" @click="random()">随机生成IP段</div>
                                            </el-form-item>
                                            <el-form-item label="IP起始地址" prop="IPstart">
                                                <el-input placeholder="请输入IP起始地址" v-model="IPstart"></el-input>
                                            </el-form-item>
                                            <el-form-item label="IP结束地址" prop="IPaddress">
                                                <el-input placeholder="请输入IP结束地址" v-model="IPend"></el-input>
                                            </el-form-item>
                                        </el-form>
                                        <div style="display: flex;justify-content: flex-end;">
                                            <div class="addbtn" @click="cancel()">取消</div>
                                            <div class="addbtn" @click="changeIP()"
                                                style="background-color: #012f55;color: white;">
                                                确认
                                            </div>
                                        </div>
                                        <!-- <div class="btn" @click="changeIP()">安装</div> -->
                                    </div>
                                </div>
                                <div class="addtable">
                                </div>
                            </div>
                        </div>
                        <!-- 添加网络内容 -->
                        <div v-if="addshow">
                            <div class="overlay"></div>
                            <div class="addbox">
                                <div v-if="addshow2 == 1" class="addtable">
                                    <div class="addtitle">添加网关</div>
                                    <div>
                                        <el-form ref="form" label-width="95px">
                                            <el-form-item label="内网网段" prop="target">
                                                <el-input placeholder="请输入内网网段" v-model="target"></el-input>
                                            </el-form-item>
                                            <el-form-item label="网关" prop="via">
                                                <el-input placeholder="请输入网关" v-model="via"></el-input>
                                            </el-form-item>
                                            <div style="display: flex;justify-content: flex-end;">
                                                <div class="addbtn" @click="cancel()">取消</div>
                                                <div class="addbtn" @click="changeroutes()"
                                                    style="background-color: #012f55;color: white;">确认
                                                </div>
                                            </div>
                                            <!-- <div class="btn" @click="changeroutes()">添加</div> -->
                                        </el-form>
                                    </div>
                                </div>
                                <div v-if="addshow2 == 2" class="addtable">
                                    <div class="addtitle">设置IP</div>
                                    <div class="box">
                                        <el-form ref="form" label-width="95px">
                                            <el-form-item label="IP网段" prop="IPaddress">
                                                <el-input placeholder="请输入网络名称" v-model="address"></el-input>
                                                <div class="btn1" @click="random()">随机生成IP段</div>
                                            </el-form-item>
                                            <el-form-item label="IP起始地址" prop="IPstart">
                                                <el-input placeholder="请输入IP起始地址" v-model="IPstart"></el-input>
                                            </el-form-item>
                                            <el-form-item label="IP结束地址" prop="IPaddress">
                                                <el-input placeholder="请输入IP结束地址" v-model="IPend"></el-input>
                                            </el-form-item>
                                        </el-form>
                                        <div style="display: flex;justify-content: flex-end;">
                                            <div class="addbtn" @click="cancel()">取消</div>
                                            <div class="addbtn" @click="changeIP()"
                                                style="background-color: #012f55;color: white;">
                                                确认
                                            </div>
                                        </div>
                                        <!-- <div class="btn" @click="changeIP()">安装</div> -->
                                    </div>
                                </div>
                                <div class="addtable">
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            networkname: '',
            networkproject: '',
            networklabel: '',
            changeshow: false,
            netmessage: [],
            addshow: false,
            addshow2: 1,
            id: '',
            netMemberList: [],
            netdetail: {},
            mid: '',
            privateName: '私有',
            address: '',
            ipAssignments: '',
            privateChecked: true,
            IPstart: '',
            IPend: '',
            target: '',
            routes: [],
            via: null,
            IPV4: true,
            IPV61: false,
            IPV62: false,
            IPV63: false,
            domain: '',
            servers: '',
            projectlist: []
        }
    },
    created() {
        this.$js.token();
        this.getnetworksDetail()
        this.networkDetail()
        this.getproject()
    },
    methods: {
        //获取所有项目
        async getproject() {
            let data = JSON.parse(sessionStorage.getItem('user'));
            let userid = data.id
            let res = await this.$apiFun.getproject({ userid })
            this.projectList = res
        },
        // 获取网络信息
        async networkDetail() {
            let id = this.id
            let res = await this.$apiFun.networkDetail({ id })
            this.netmessage = res
            this.networkname = res.netname
            this.networkproject = res.project
            this.networklabel = res.label
        },
        // 展示添加网关
        addnetshow() {
            this.addshow = true
            this.addshow2 = 1
        },
        addnetshow2() {
            this.addshow = true
            this.addshow2 = 2
        },
        // 取消操作
        cancel() {
            this.addshow = false
        },
        // 展示change
        showchange() {
            this.changeshow = true
            this.addshow2 = 3
        },
        // 取消操作
        cancel2() {
            this.changeshow = false
        },
        // 修改network
        async networkchange() {
            let id = this.id
            let netname = this.networkname
            let project = this.networkproject
            let label = this.networklabel
            let netdetail = this.netdetail
            netdetail.name = this.networkname
            let res = await this.$apiFun.networkchange({ id, netname, project, label, netdetail})
            location.reload()
        },
        //跳转网络列表
        page(id) {
            this.$js.openpages('/Networks', { id });
        },
        //生成网络地址
        random() {
            var random1 = Math.floor(Math.random() * 220 + 10);
            var random2 = Math.floor(Math.random() * 220 + 10);
            this.address = '10' + '.' + random1 + '.' + random2 + '.' + '0/24'
            this.IPstart = '10' + '.' + random1 + '.' + random2 + '.' + '1'
            this.IPend = '10' + '.' + random1 + '.' + random2 + '.' + '254'
        },
        //改变私有
        changeprivate() {
            this.netdetail.private = this.privateChecked
            if (this.privateChecked == true) {
                this.privateName = '私有'
            } else {
                this.privateName = '公有'
            }
            this.change()
        },
        //简易安装
        changeIP() {
            if (this.netdetail.ipAssignmentPools.length < 1) {
                this.netdetail.ipAssignmentPools.push({ ipRangeEnd: this.IPend, ipRangeStart: this.IPstart })
            } else {
                this.netdetail.ipAssignmentPools[0].ipRangeEnd = this.IPend
                this.netdetail.ipAssignmentPools[0].ipRangeStart = this.IPstart
            }
            let target = this.address
            let via = this.via
            this.netdetail.routes.push({ target, via });
            this.change()
        },
        //添加路由
        changeroutes() {
            let target = this.target
            let via = this.via
            this.netdetail.routes.push({ target, via });
            this.change()
            this.addshow = false
        },
        changeroutes2(index) {
            this.netdetail.routes.splice(index, 1);
            this.change()
        },
        //改变IPV4
        changeIPV4() {
            this.netdetail.v4AssignMode.zt = this.IPV4
            this.change()
        },
        //改变IPV4
        changeIPV6() {
            this.netdetail.v6AssignMode['6plane'] = this.IPV61
            this.netdetail.v6AssignMode.rfc4193 = this.IPV62
            this.netdetail.v6AssignMode.zt = this.IPV63
            this.change()
        },
        //改变DNS
        changeDNS() {
            this.netdetail.dns.domain = this.domain
            this.netdetail.dns.servers = this.servers
            this.change()
        },
        //修改网络详细信息
        async change() {
            let id = this.$route.query.id;
            let netdetail = this.netdetail
            let res = await this.$apiFun.networksDetail({ id, netdetail });
        },
        //获取网络地址
        getaddress(ipstart) {
            var ipstartArray = ipstart.split("."); // 将 IP 地址以 . 分割成数组
            ipstartArray.pop(); // 去掉数组的最后一个元素
            var newIP = ipstartArray.join("."); // 将数组拼接成字符串
            newIP = newIP + '.0/24'
            return newIP
        },
        //获取详细信息
        async getnetworksDetail() {
            let id = this.$route.query.id;
            this.id = this.$route.query.id;
            let res = await this.$apiFun.getnetworksDetail({ id });
            console.log(res);
            this.netdetail = res
            this.privateChecked = this.netdetail.private
            if (this.privateChecked == true) {
                this.privateName = '私有'
            } else {
                this.privateName = '公有'
            }
            this.IPstart = this.netdetail.ipAssignmentPools.length > 0 ? this.netdetail.ipAssignmentPools[0].ipRangeStart : ''
            this.IPend = this.netdetail.ipAssignmentPools.length > 0 ? this.netdetail.ipAssignmentPools[0].ipRangeEnd : ''
            this.address = this.getaddress(this.IPstart)
            this.routes = this.netdetail.routes
            this.IPV4 = this.netdetail.v4AssignMode.zt
            this.IPV61 = this.netdetail.v6AssignMode['6plane']
            this.IPV62 = this.netdetail.v6AssignMode.rfc4193
            this.IPV63 = this.netdetail.v6AssignMode.zt
            this.domain = this.netdetail.dns.domain
            this.servers = this.netdetail.dns.servers
        },

    }
}
</script>
<style scoped>
.content-box {
    flex: 1;
    position: relative;
    padding: 0 1.1%;
    background-color: white;
    overflow-y: hidden;
    overflow-x: auto;
    white-space: nowrap;
}

.routes {
    display: flex;
    padding: 8px;
    border-bottom: 1px solid #e7e9f1;
    background-color: #fafafb;
}

.addbtn {
    height: 30px;
    line-height: 30px;
    background: #FAFAFA;
    padding: 0px 25px;
    font-size: 14px;
    border: 1px solid #dcdcdc;
    margin-left: 10px;
    cursor: pointer;
    margin-bottom: 20px;
}

.title {
    /* text-align: center; */
    font-size: 14px;
    font-weight: bold;
}

.title-box {
    margin: 20px 0px 10px;
    height: 30px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ebeef5;
}

.btn-box {
    margin: -15px 0px 0px 0px;
    display: flex;
}


.addtable {
    padding: 0px 80px;
}

.btn3 {
    display: flex;
    align-items: center;
    padding: 0px 16px;
    height: 32px;
    line-height: 32px;
    margin: 0px 10px 0px 0px;
    border: 1px solid #e7e9f1;
    cursor: pointer;
    font-size: 14px;
    color: #666666;
}

.overlay {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    /* 半透明黑色背景 */
    z-index: 998;
    /* 确保在上层 */
}

.addbox {
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 600px;
    /* height: 300px; */
    z-index: 999;
    background-color: white;
}

.addtitle {
    margin-bottom: 40px;
    margin-left: -65px;
    padding: 25px 0px 0px 15px;
    font-size: 14px;
    font-weight: bold;
}

.tab {
    font-size: 12px;
    padding-top: 10px;
    height: 38.8px;
    line-height: 38.8px;
}

.opbox {
    width: 60px;
}

.viabox {
    width: 160px;
}

.routesbox {
    width: 200px;
}

.btn1 {
    width: 135px;
    text-align: center;
    font-size: 14px;
    padding: 0 5px;
    color: #333333;
    background-color: #FAFAFA;
    border: 1px solid #dcdcdc;
    border-radius: 4px;
    margin-left: 12px;
    cursor: pointer;
}

.btn2:hover {
    color: #90c31d;
    cursor: pointer;
}


.netbox {
    width: 345px;
    padding: 10px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
    margin-bottom: 20px;
    /* background-color: red; */
}

.netboxtitle {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
}

.flexbox {
    display: flex;
}

.flexboxtitle {
    width: 150px;
}


.box:deep(.el-form-item__content) {
    display: flex;
}

.content-box:deep(.el-input__inner:focus) {
    border-color: #012f55;
}

</style>
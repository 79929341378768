import { render, staticRenderFns } from "./Project.vue?vue&type=template&id=6986ba62&scoped=true"
import script from "./Project.vue?vue&type=script&lang=js"
export * from "./Project.vue?vue&type=script&lang=js"
import style0 from "./Project.vue?vue&type=style&index=0&id=6986ba62&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6986ba62",
  null
  
)

export default component.exports
<template>
    <div>
        <!-- 页头 -->
        <nav-header></nav-header>
        <!-- 手机号验证 -->
        <div class="content-box" v-if="showid == 0">
            <div class="title">修改手机号</div>
            <el-form :rules="rules" ref="form" :model="form">
                <el-form-item label="手机号" label-width="100px" prop="mobile">
                    <el-input v-model="form.mobile"></el-input>
                </el-form-item>
                <div class="mobile">
                    <el-form-item label="验证码" label-width="100px" prop="message">
                        <el-input class="mobile-input" placeholder="请输入验证码" v-model="form.code"></el-input>
                        <div @click="mobile(form.mobile)" class="inputend">
                            <span style="color: #dedede;">|</span>
                            <span
                                style="cursor: pointer;height: 16px;line-height: 16px;padding-left: 14px;font-size: 16px;">{{
            form.time
        }}</span>
                        </div>
                        <div v-if="ifmobile == 1" class="inputnotice">验证码发送成功，5分钟内输入有效</div>
                    </el-form-item>
                </div>
            </el-form>
            <div style="display: flex;justify-content: center;">
                <div style="width: 100px;"></div>
                <div class="nextpage" @click="nextpage(form.code)">下一页</div>
            </div>
        </div>
        <!-- 修改密码 -->
        <div class="content-box" v-if="showid == 1">
            <div class="title">修改手机号</div>
            <el-form :rules="rules" ref="form" :model="form">
                <el-form-item label="手机号" label-width="100px" prop="mobile">
                    <el-input v-model="form.mobile2"></el-input>
                </el-form-item>
                <div class="mobile">
                    <el-form-item label="重复手机号" label-width="100px" prop="mobile">
                        <el-input v-model="form.mobile3"></el-input>
                    </el-form-item>
                </div>
            </el-form>
            <div style="display: flex;justify-content: center;">
                <div style="width: 100px;"></div>
                <div class="nextpage" @click="changemobile(form.mobile3)">修改手机号</div>
            </div>
        </div>
        <div class="footer">
            <div>
                <span>关于我们</span>
                <span> | </span>
                <span><a style="color: #7b7b7b;" href="https://www.hi-wooya.com/" target="_blank">无涯官网</a></span>
            </div>
            <div>
                <span>版权所有©四川无涯智能科技有限公司( 蜀ICP备20020584号-1)</span>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            ifmobile: '0',
            phone: '',
            buttonstyle: 'not-allowed',
            timeback: 60,
            showid: '0',
            form: {
                mobile2: '',
                mobile3: '',
                mobile: '',
                code: '',
                timeback: '60',
                time: '获取验证码'
            },
            rules: {
                mobile2: [
                    { required: true, message: '请输入手机号', trigger: 'blur' },
                    { min: 11, max: 11, message: '请输入正确的手机号', trigger: 'blur' }
                ],
                mobile3: [
                    { required: true, message: '请输入手机号', trigger: 'blur' },
                    { min: 11, max: 11, message: '请输入正确的手机号', trigger: 'blur' }
                ],
                mobile: [
                    { required: true, message: '请输入手机号', trigger: 'blur' },
                    { min: 11, max: 11, message: '请输入正确的手机号', trigger: 'blur' }
                ],
                code: [
                    { required: true, message: '请输入验证码', trigger: 'blur' },
                    { min: 6, max: 6, message: '请输入正确的验证码', trigger: 'blur' }
                ],
            },
        }
    },
    methods: {
        //短信测试
        async mobile(mobile) {
            if (this.form.mobile.length == 11 && this.form.time == '获取验证码') {
                let res = await this.$apiFun.forgot({ mobile });
                if (res != '提交成功') {
                    alert(res)
                } else {
                    this.form.time = '重新获取(' + this.timeback + ')s'
                    this.timeback -= 1
                    let timer = setInterval(() => {
                        if (this.timeback > 0) {
                            this.form.time = '重新获取(' + this.timeback + ')s'
                            this.timeback -= 1
                        } else {
                            clearInterval(timer);
                            this.timeback = 60
                            this.form.time = '获取验证码'
                        }
                    }, 1000);
                    this.ifmobile = 1
                }
            }
        },
        async nextpage(code) {
            if (this.ifmobile == 0) {
                alert('请先获取验证码')
            } else {
                this.phone = this.form.mobile
                let res = await this.$apiFun.pwdnextpage({ code });
                if (res == 'success') {
                    this.showid = 1
                } else {
                    alert(res)
                }
            }
        },
        async changemobile(mobile3) {
            if (mobile3.length == 11) {
                if (this.form.mobile2 === this.form.mobile3) {
                    let data = JSON.parse(sessionStorage.getItem('user'));
                    let name = data.name
                    let changemobile = mobile3
                    let res = await this.$apiFun.changemobile({ name, changemobile });
                    alert(res)
                    if (res == '修改成功') {
                        this.$js.openpages('/');
                    }
                } else {
                    alert('两次手机号不一致')
                }
            } else {
                alert('手机号错误')
            }
        }
    },
    watch: {
        'form.mobile': {
            handler(newValue, oldValue) {
                if (newValue.length === 11) {
                    this.buttonstyle = 'pointer'
                } else {
                    this.buttonstyle = 'not-allowed'
                }
            }
        },
        'form.time': {
            handler(newValue, oldValue) {
                if (newValue === '获取验证码') {
                    this.buttonstyle = 'pointer'
                } else {
                    this.buttonstyle = 'not-allowed'
                }
            }
        }
    },
}
</script>
<style scoped>
.content-box {
    padding: 20px;
    width: 800px;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, .1);
    margin: 20px auto;
}

.title {
    font-size: 24px;
    margin-left: 45px;
    margin-bottom: 20px;
}

.mobile {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}
.inputend {
    position: absolute;
    right: 15px;
    top: 0px;
    height: 100%;
    display: flex;
    align-items: center;
    color: #012f55;
}

.inputnotice {
    position: absolute;
    height: 12px;
    left: 0;
    bottom: -16px;
    font-size: 12px;
    color: #012f55;
    line-height: 12px;
}
.nextpage {
    width: 370px;
    height: 40px;
    line-height: 40px;
    background-color: #012f55;
    color: white;
    text-align: center;
    cursor: pointer;
}

.footer {
    position: fixed;
    text-align: center;
    font-size: 14px;
    color: #7b7b7b;
    width: 100%;
    margin: 0px auto;
    bottom: 0px;
    left: 0px;
}

.content-box:deep(.el-input) {
    width: 370px;
}

.content-box:deep(.el-form-item__content) {
    width: 370px;
}

.content-box:deep(.el-input__inner) {
    background-color: #f3f4f9;
    height: 50px;
}

.content-box:deep(.el-form-item__label) {
    height: 50px;
    line-height: 50px;
}

.content-box:deep(.el-form-item) {
    width: fit-content;
    margin: 0 auto;
}

@media screen and (max-width: 1700px) {
    .content-box {
        padding: 15px;
        width: 600px;
        box-shadow: 0 3.75px 15px 0 rgba(0, 0, 0, .1);
        margin: 15px auto;
    }

    .title {
        font-size: 18px;
        margin-left: 33.75px;
        margin-bottom: 15px;
    }

    .mobile {
        margin-top: 15px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;
    }
    .nextpage {
        width: 277.5px;
        height: 30px;
        line-height: 30px;
        background-color: #012f55;
        color: white;
        text-align: center;
        cursor: pointer;
    }

    .footer {
        position: fixed;
        text-align: center;
        font-size: 10.5px;
        color: #7b7b7b;
        width: 100%;
        margin: 0px auto;
        bottom: 0px;
        left: 0px;
    }

    .content-box:deep(.el-input) {
        width: 277.5px;
    }

    .content-box:deep(.el-form-item__content) {
        width: 277.5px;
    }

    .content-box:deep(.el-input__inner) {
        background-color: #f3f4f9;
        height: 37.5px;
    }

    .content-box:deep(.el-form-item__label) {
        height: 37.5px;
        line-height: 37.5px;
    }

    .content-box:deep(.el-form-item) {
        width: fit-content;
        margin: 0 auto;
    }

}
</style>
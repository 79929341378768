import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
// api接口内容
import apiFun from "./assets/js/api";
//全局js
import method from "./assets/js/method";
// 引入全局样式
import "../src/assets/css/common.css"
//阿里图标
import "./assets/icon/iconfont.css";
//echarts
import * as echarts from 'echarts';

Vue.config.productionTip = false



import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

// 公共組件
import NavHeader from './components/NavHeader.vue'
import NavHeader2 from './components/NavHeader2.vue'
import NavFooter from './components/NavFooter.vue'
import Sidebar from './components/Sidebar.vue'
import MapContainer from './components/MapContainer/MapContainer.vue'
import MapIndex from './components/MapContainer/MapIndex.vue'
Vue.component('side-bar', Sidebar)
Vue.component('nav-header', NavHeader)
Vue.component('nav-header2', NavHeader2)
Vue.component('nav-footer', NavFooter)
Vue.component('gd-map', MapContainer)
Vue.component('index-map', MapIndex)

Vue.prototype.$apiFun = apiFun; //请求接口 api
Vue.prototype.$js = method; //公共工具类
Vue.prototype.$echarts = echarts  //echarts

new Vue({
  router: router,
  render: h => h(App),
}).$mount('#app')

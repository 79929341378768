<template>
    <div style="height: 100vh;width: 100vw;">
        <!-- 页头 -->
        <nav-header2 style="position: absolute;z-index: 999;"></nav-header2>
        <div style="height: 50px;"></div>
        <div style="display: flex;height: calc(100% - 50px);width: 100%;">
            <!-- 侧边栏 -->
            <side-bar></side-bar>
            <div style="flex: 1;padding: 10px;background-color: #e4e7ed;display: flex;">
                <div class="content-box">
                    <div class="tab">
                        <span style="color: #999999;">账号与安全</span>
                        <span style="margin: 0px 10px;">></span>
                        <span>安全信息</span>
                    </div>
                    <div class="title-box">
                        <!-- 标题 -->
                        <div class="title">安全信息</div>
                    </div>
                    <div class="safebox">
                        <div class="safetitle">
                            登陆密码
                        </div>
                        <div class="safecontent">
                            为提高安全性，建议每过一段时间修改一次密码。
                        </div>
                        <div class="safebutton" @click="page('/Forgot')">
                            点击修改
                        </div>
                    </div>
                    <div class="safebox">
                        <div class="safetitle">
                            绑定手机
                        </div>
                        <div class="safecontent">
                            您已设置了手机 <span style="color: #012f55;">{{ phone }}</span>，可以登录及找回密码，接收无涯云发送的服务通知。
                        </div>
                        <div class="safebutton" @click="page('/Changemobile')">
                            点击修改
                        </div>
                    </div>
                    <div class="safebox">
                        <div class="safetitle">
                            绑定邮箱
                        </div>
                        <div class="safecontent" v-if="form.email != ''">
                            您已设置了邮箱 <span style="color: #012f55;">{{ email }}</span>。
                        </div>
                        <div class="safecontent" v-if="form.email == ''">
                            您暂未设置邮箱。
                        </div>
                        <div class="safebutton" v-if="form.email != ''" @click="setshow()">
                            点击修改
                        </div>
                        <div class="safebutton" v-if="form.email == ''" @click="setshow()">
                            点击设置
                        </div>
                    </div>
                    <div v-if="addshow">
                        <div class="overlay"></div>
                        <div class="addbox">
                            <div class="addtable">
                                <div class="addtitle">修改邮箱</div>
                                <el-form :rules="rules" ref="form" :model="form" label-width="95px">
                                    <el-form-item label="邮箱地址" prop="email">
                                        <el-input placeholder="请输入网络名称" v-model="form.email"></el-input>
                                    </el-form-item>
                                    <div style="display: flex;justify-content: flex-end;">
                                        <div class="addbtn" @click="cancel()">取消</div>
                                        <div class="addbtn" @click="submitForm('form')"
                                            style="background-color: #012f55;color: white;">确认
                                        </div>
                                    </div>
                                </el-form>
                            </div>
                            <div class="addtable">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
export default {
    data() {
        return {
            data: '',
            phone: '',
            id: '',
            email: '',
            addshow: false,
            form: {
                email: '',
            },
            rules: {
                email: [
                    { required: true, message: '请输入邮箱地址', trigger: 'blur' },
                    {
                        min: 5, max: 20,
                        pattern: /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
                        message: '请输入正确的邮箱', trigger: 'blur'
                    }
                ]
            }
        }
    },
    created() {
        this.$js.token();
        sessionStorage.setItem('tabid', '72');
        sessionStorage.setItem('path', '/safe');
        sessionStorage.setItem('netpage', '1');
        this.data = JSON.parse(sessionStorage.getItem('user'));
        this.phone = this.data.mobile
        this.form.email = this.data.email
        this.email = this.data.email
        this.id = this.data.id
    },
    methods: {
        // 跳转页面
        page(path) {
            this.$js.openpages(path);
        },
        // 取消操作
        cancel() {
            this.addshow = false
        },
        setshow() {
            this.addshow = true
        },
        //修改邮箱
        async setemail() {
            let id = this.id
            let email = this.form.email
            let res = await this.$apiFun.setemail({ id, email });
            this.addshow = false
            this.data.email = email
            sessionStorage.setItem('user', JSON.stringify(this.data));
            location.reload()
        },
        // 表单确认
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.setemail()
                } else {
                    return false;
                }
            });
        },
    }
}
</script>
<style scoped>
.content-box {
    flex: 1;
    position: relative;
    padding: 0 1.1%;
    background-color: white;
    overflow-y: hidden;
    overflow-x: auto;
    white-space: nowrap;
}

.tab {
    font-size: 12px;
    padding-top: 10px;
    height: 38.8px;
    line-height: 38.8px;
}

.title-box {
    margin: 20px 0px 10px;
    height: 30px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ebeef5;
}

.title {
    /* text-align: center; */
    font-size: 14px;
    font-weight: bold;
}

.safebox {
    display: flex;
    width: 100%;
    height: 60px;
    border-bottom: 1px solid #ebeef5;
    align-items: center;
}

.safetitle {
    width: 200px;
    font-weight: bold;
}

.safecontent {
    flex: 1;
    font-size: 14px;
}

.safebutton {
    padding: 0px 16px;
    height: 32px;
    line-height: 32px;
    cursor: pointer;
    font-size: 14px;
    color: white;
    background-color: #012f55;
}

.overlay {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* 半透明黑色背景 */
    z-index: 998;
    /* 确保在上层 */
}

.addbox {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -70%);
    width: 600px;
    /* height: 300px; */
    z-index: 999;
    background-color: white;
}

.addtitle {
    margin-bottom: 40px;
    margin-left: -65px;
    padding: 25px 0px 0px 15px;
    font-size: 14px;
    font-weight: bold;
}

.addtable {
    padding: 0px 80px;
}

.addbtn {
    height: 30px;
    line-height: 30px;
    background: #FAFAFA;
    padding: 0px 25px;
    font-size: 14px;
    border: 1px solid #dcdcdc;
    margin-left: 10px;
    cursor: pointer;
    margin-bottom: 20px;
}

.addtable:deep(.el-input__inner:hover) {
    border-color: #012f55;
}

.addtable:deep(.el-input__inner:focus) {
    border-color: #012f55;
}

.addtable:deep(.el-input.is-focus .el-input__inner) {
    border-color: #012f55;
}

</style>
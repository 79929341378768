import router from '../../router/index'


//设置个对象，就不用一个个暴露了，直接暴露对象
let method = {};


// 页面跳转
method.openpages = (path,query) => {
    router.push({
        path,
        query
    });
}

//检查token
method.token = () => {
    let data = JSON.parse(sessionStorage.getItem('user'));
    if(data == undefined || data ==null){
        alert('登录信息失效请重新登陆')
        router.push({
            path:'/'
        });
    }
}


//暴露出这个对象
export default method;


<template>
    <div class="view">
        <!-- 页头 -->
        <nav-header></nav-header>
        <!-- 登录功能 -->
        <div class="login-box">
            <div class="login-title">用户注册</div>
            <el-form :rules="rules" ref="form" :model="form">
                <el-form-item label="手机号" label-width="100px" prop="mobile" class="el-title">
                    <el-input v-model="form.mobile" placeholder="请输入手机号"></el-input>
                </el-form-item>
                <el-form-item label="验证码" label-width="100px" prop="code">
                    <el-input placeholder="请输入验证码" v-model="form.code"></el-input>
                    <div @click="mobile(form.mobile)" class="inputend">
                            <span style="color: #dedede;">|</span>
                            <span class="inputtime">{{ form.time }}</span>
                        </div>
                        <div v-if="ifmobile == 1" class="inputnotice">验证码发送成功，5分钟内输入有效</div>
                </el-form-item>
                <el-form-item label="密码" label-width="100px" prop="password1" class="el-title">
                    <el-input onkeyup="this.value=this.value.replace(/[^\w_]/g,'');" placeholder="请输入密码"
                        v-model="form.password1" show-password></el-input>
                </el-form-item>
                <el-form-item label="重复密码" label-width="100px" class="el-title" prop="password2">
                    <el-input placeholder="请再次输入密码" v-model="form.password2" show-password></el-input>
                </el-form-item>
                <el-form-item label="公司名称" label-width="100px" class="el-title" prop="firm">
                    <el-input placeholder="请输入公司名称" v-model="form.firm"></el-input>
                </el-form-item>
                <el-form-item label="所属行业" label-width="100px" prop="region">
                    <el-select v-model="form.region" placeholder="请选择所属行业">
                        <el-option label="生产设备监控" value="生产设备监控"></el-option>
                        <el-option label="生产环境监控" value="生产环境监控"></el-option>
                        <el-option label="智慧工地" value="智慧工地"></el-option>
                        <el-option label="老旧改造" value="老旧改造"></el-option>
                        <el-option label="电力抄表" value="电力抄表"></el-option>
                        <el-option label="电力保护设备" value="电力保护设备"></el-option>
                        <el-option label="电能质量管理" value="电能质量管理"></el-option>
                        <el-option label="自主共享" value="自主共享"></el-option>
                        <el-option label="充电桩" value="充电桩"></el-option>
                        <el-option label="智慧校园" value="智慧校园"></el-option>
                        <el-option label="环境监测" value="环境监测"></el-option>
                        <el-option label="环保设备" value="环保设备"></el-option>
                        <el-option label="畜牧养殖" value="畜牧养殖"></el-option>
                        <el-option label="大田农村节水灌溉" value="大田农村节水灌溉"></el-option>
                        <el-option label="农水灌溉计价系统" value="农水灌溉计价系统"></el-option>
                        <el-option label="智慧农业大棚" value="智慧农业大棚"></el-option>
                        <el-option label="农机/水肥一体机监控" value="农机/水肥一体机监控"></el-option>
                        <el-option label="换热站监控" value="换热站监控"></el-option>
                        <el-option label="供暖平衡阀" value="供暖平衡阀"></el-option>
                        <el-option label="城市供水" value="城市供水"></el-option>
                        <el-option label="城市综合管廊" value="城市综合管廊"></el-option>
                        <el-option label="电气火灾监控" value="电气火灾监控"></el-option>
                        <el-option label="消防报警主机联网" value="消防报警主机联网"></el-option>
                        <el-option label="工业可燃气体报警" value="工业可燃气体报警"></el-option>
                        <el-option label="消防水压监控" value="消防水压监控"></el-option>
                        <el-option label="其他" value="其他"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="地区" label-width="100px" prop="place">
                    <el-select v-model="form.place" placeholder="请选择地区">
                        <el-option label="北京市" value="北京市"></el-option>
                        <el-option label="天津市" value="天津市"></el-option>
                        <el-option label="上海市" value="上海市"></el-option>
                        <el-option label="重庆市" value="重庆市"></el-option>
                        <el-option label="河北省" value="河北省"></el-option>
                        <el-option label="山西省" value="山西省"></el-option>
                        <el-option label="辽宁省" value="辽宁省"></el-option>
                        <el-option label="吉林省" value="吉林省"></el-option>
                        <el-option label="黑龙江省" value="黑龙江省"></el-option>
                        <el-option label="福建省" value="福建省"></el-option>
                        <el-option label="江西省" value="江西省"></el-option>
                        <el-option label="江苏省" value="江苏省"></el-option>
                        <el-option label="浙江省" value="浙江省"></el-option>
                        <el-option label="安徽省" value="安徽省"></el-option>
                        <el-option label="山东省" value="山东省"></el-option>
                        <el-option label="河南省" value="河南省"></el-option>
                        <el-option label="湖北省" value="湖北省"></el-option>
                        <el-option label="湖南省" value="湖南省"></el-option>
                        <el-option label="广东省" value="广东省"></el-option>
                        <el-option label="海南省" value="海南省"></el-option>
                        <el-option label="四川省" value="四川省"></el-option>
                        <el-option label="贵州省" value="贵州省"></el-option>
                        <el-option label="云南省" value="云南省"></el-option>
                        <el-option label="陕西省" value="陕西省"></el-option>
                        <el-option label="甘肃省" value="甘肃省"></el-option>
                        <el-option label="青海省" value="青海省"></el-option>
                        <el-option label="台湾省" value="台湾省"></el-option>
                        <el-option label="内蒙古自治区" value="内蒙古自治区"></el-option>
                        <el-option label="广西壮族自治区" value="广西壮族自治区"></el-option>
                        <el-option label="西藏自治区" value="西藏自治区"></el-option>
                        <el-option label="宁夏回族自治区" value="宁夏回族自治区"></el-option>
                        <el-option label="新疆维吾尔族自治区" value="新疆维吾尔族自治区"></el-option>
                        <el-option label="香港特别行政区" value="香港特别行政区"></el-option>
                        <el-option label="澳门特别行政区" value="澳门特别行政区"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item class="el-title">
                    <el-button class="input" style="background-color: #43A92A;" type="primary"
                        @click="enrollForm('form')">立即注册</el-button>
                </el-form-item>
            </el-form>
            <div style="color: #7b7b7b;text-align: center;padding-bottom: 20px;margin-top: -10px;">已有帐号，<a
                    style="color: #012f55;" href="/">登录</a></div>
        </div>
        <div class="footer">
            <div>
                <span>关于我们</span>
                <span> | </span>
                <span><a style="color: #7b7b7b;" href="https://www.hi-wooya.com/" target="_blank">无涯官网</a></span>
            </div>
            <div>
                <span>版权所有©四川无涯智能科技有限公司( 蜀ICP备20020584号-1)</span>
            </div>
        </div>
    </div>
</template>

<script>
import bcrypt from 'bcryptjs';
export default {
    data() {
        return {
            ifmobile: 0,
            buttonstyle: 'not-allowed',
            timeback: 60,
            form: {
                password1: '',
                password2: '',
                firm: '',
                region: '',
                place: '',
                mobile: '',
                code: '',
                timeback: '60',
                time: '获取验证码'
            },
            rules: {
                password1: [
                    { required: true, message: '请输入密码', trigger: 'blur' },
                    { min: 5, max: 18, message: '长度在 5 到 18 个字符', trigger: 'blur' }
                ],
                password2: [
                    { required: true, message: '请输入密码', trigger: 'blur' },
                    { min: 5, max: 18, message: '长度在 5 到 18 个字符', trigger: 'blur' }
                ],
                firm: [
                    { required: true, message: '请输入公司名称', trigger: 'blur' },
                    { min: 1, message: '请输入正确的公司名称', trigger: 'blur' }
                ],
                region: [
                    { required: true, message: '请输入所属行业', trigger: 'blur' },
                    { min: 1, message: '请输入正确的所属行业', trigger: 'blur' }
                ],
                place: [
                    { required: true, message: '请输入地区', trigger: 'blur' },
                    { min: 1, message: '请输入正确的地区', trigger: 'blur' }
                ],
                mobile: [
                    { required: true, message: '请输入手机号', trigger: 'blur' },
                    { min: 11, max: 11, message: '请输入正确的手机号', trigger: 'blur' }
                ],
                code: [
                    { required: true, message: '请输入验证码', trigger: 'blur' },
                    { min: 6, max: 6, message: '请输入正确的验证码', trigger: 'blur' }
                ],
            }
        }
    },
    methods: {
        //测试
        async mobile(mobile) {
            if (this.form.mobile.length == 11 && this.form.time == '获取验证码') {
                let res = await this.$apiFun.mobile({ mobile });
                if (res != '提交成功') {
                    alert(res)
                } else {
                    this.form.time = '重新获取(' + this.timeback + ')s'
                    this.timeback -= 1
                    let timer = setInterval(() => {
                        if (this.timeback > 0) {
                            this.form.time = '重新获取(' + this.timeback + ')s'
                            this.timeback -= 1
                        } else {
                            clearInterval(timer);
                            this.timeback = 60
                            this.form.time = '获取验证码'
                        }
                    }, 1000);
                    this.ifmobile = 1
                }
            }else{
                alert('请输入正确的手机号')
            }
        },
        // 注册
        async enroll( password, mobile, code, firm, region, place) {
            if (this.ifmobile == 0) {
                alert('请先获取验证码')
            } else {
                const salt = await bcrypt.genSalt(10);
                const hashedPassword = await bcrypt.hash(password, salt);
                let res = await this.$apiFun.enroll({ hashedPassword, mobile, code, firm, region, place });
                if (res === '注册成功') {
                    this.$js.openpages('/');
                    alert(res)
                } else {
                    alert(res)
                }
            }
        },
        // 表单注册
        enrollForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if (this.form.password1 === this.form.password2) {
                        this.enroll(this.form.password2, this.form.mobile, this.form.code, this.form.firm, this.form.region, this.form.place)
                    } else {
                        alert('两次密码不一致')
                    }
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
    },
    watch: {
        'form.mobile': {
            handler(newValue, oldValue) {
                if (newValue.length === 11) {
                    this.buttonstyle = 'pointer'
                } else {
                    this.buttonstyle = 'not-allowed'
                }
            }
        },
        'form.time': {
            handler(newValue, oldValue) {
                if (newValue === '获取验证码') {
                    this.buttonstyle = 'pointer'
                } else {
                    this.buttonstyle = 'not-allowed'
                }
            }
        }
    },
}
</script>


<style scoped>
.login-box {
    width: 800px;
    margin: 0px auto;
    padding: 0px 20px;
    margin-top: 50px;
    /* background: rgba(255, 255, 255, .9); */
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, .1);
}
.inputend {
    position: absolute;
    right: 15px;
    top: 0px;
    height: 100%;
    display: flex;
    align-items: center;
    color: #012f55;
}

.inputnotice {
    position: absolute;
    height: 12px;
    left: 0;
    bottom: -16px;
    font-size: 12px;
    color: #012f55;
    line-height: 12px;
}
.inputtime {
    cursor: pointer;
    height: 16px;
    line-height: 16px;
    padding-left: 14px;
    font-size: 16px;
}
.login-title {
    padding-top: 20px;
    font-size: 24px;
    text-align: center;
    /* margin-bottom: -10px; */
    /* margin-left: 80px; */
}

.input {
    width: 370px;
    margin-left: 100px;
}

.el-title {
    margin-left: 20px;
}

.footer {
    position: fixed;
    text-align: center;
    font-size: 14px;
    color: #7b7b7b;
    width: 100%;
    margin: 0px auto;
    bottom: 0px;
    left: 0px;
}

.login-box:deep(.el-input) {
    width: 370px;
}

.login-box:deep(.el-form-item__content) {
    width: 370px;
}

.login-box:deep(.el-form-item__label) {
    height: 50px;
    line-height: 50px;
}

.login-box:deep(.el-input__inner) {
    background-color: #f3f4f9;
    height: 50px;
}

.login-box:deep(.el-form-item) {
    width: fit-content;
    margin: 20px 0px 20px 126px;
}

.login-box:deep(.el-input__inner:focus) {
    border-color: #43a92a
}
.login-box:deep(.el-input__inner:hover) {
    border-color: #43a92a
}

.login-box:deep(.el-button) {
    /* width: 420px; */
    height: 50px;
    padding: 12px 0px;
}
.login-box:deep(.el-select .el-input.is-focus .el-input__inner) {
    border-color: #43a92a
}

@media screen and (max-width: 1700px) {
    .login-box {
        width: 600px;
        margin: 0px auto;
        padding: 0px 15px;
        margin-top: 37.5px;
        /* background: rgba(255, 255, 255, .9); */
        box-shadow: 0 3.75px 15px 0 rgba(0, 0, 0, .1);
    }
    .inputtime {
        cursor: pointer;
        height: 12px;
        line-height: 12px;
        padding-left: 14px;
        padding-top: 3px;
        font-size: 12px;
    }
    .login-title {
        padding-top: 15px;
        font-size: 18px;
        text-align: center;
        margin-bottom: -7.5px;
    }

    .input {
        width: 277.5px;
    }
    .el-title {
        margin-left: 15px;
    }

    .footer {
        position: fixed;
        text-align: center;
        font-size: 10.5px;
        color: #7b7b7b;
        width: 100%;
        margin: 0px auto;
        bottom: 0px;
        left: 0px;
    }



    .login-box:deep(.el-input) {
        width: 277.5px;
    }

    .login-box:deep(.el-form-item__label) {
        font-size: 12px;
    }

    .login-box:deep(.el-form-item__content) {
        width: 277.5px;
    }

    .login-box:deep(.el-form-item__label) {
        height: 37.5px;
        line-height: 37.5px;
    }

    .login-box:deep(.el-input__inner) {
        background-color: #f3f4f9;
        height: 37.5px;
    }

    .login-box:deep(.el-input__inner::placeholder) {
        font-size: 12px;
    }

    .login-box:deep(.el-form-item) {
        width: fit-content;
        margin: 15px 0px 15px 94.5px;
    }

    .login-box:deep(.el-input__inner:focus) {
        border-color: #90c31d
    }

    .login-box:deep(.el-button) {
        /* width: 420px; */
        height: 37.5px;
        padding: 9px 0px;
    }
}
</style>
import Vue from 'vue'   //引入Vue
import Router from 'vue-router'  //引入vue-router
Vue.use(Router)

import index from '../pages/index.vue'
import Enrollsuper from '../pages/Enrollsuper.vue'
import Userdata from '../pages/Userdata.vue'
import networksindex from '../pages/networksindex.vue'
import Networks from '../pages/Networks.vue'
import Netdetail from '../pages/Netdetail.vue'
import Forgot from '../pages/Forgot.vue'
import Addnetworks from '../pages/Addnetworks.vue'
import Equipment from '../pages/Equipment.vue'
import Minedata from '../pages/Minedata.vue'
import Changemobile from '../pages/Changemobile.vue'
import Project from '../pages/Project.vue'
import Usercharge from '../pages/Usercharge.vue'
import SafeUser from '../pages/SafeUser.vue'
import Myorder from '../pages/Myorder.vue'



// 路由重复点击报错问题
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

export default new Router({
    mode: 'history',
    routes:[
        {
            path: '/',
            name: 'index',
            component: index,
        },
        {
            path: '/Enrollsuper',
            name: 'Enrollsuper',
            component: Enrollsuper,
        },
        {
            path: '/Userdata',
            name: 'Userdata',
            component: Userdata,
        },
        {
            path: '/networksindex',
            name: 'networksindex',
            component: networksindex,
        },
        {
            path: '/Networks',
            name: 'Networks',
            component: Networks,
        },
        {
            path: '/Netdetail',
            name: 'Netdetail',
            component: Netdetail,
        },
        {
            path: '/Forgot',
            name: 'Forgot',
            component: Forgot,
        },
        {
            path: '/Addnetworks',
            name: 'Addnetworks',
            component: Addnetworks,
        },
        {
            path: '/Equipment',
            name: 'Equipment',
            component: Equipment,
        },
        {
            path: '/Minedata',
            name: 'Minedata',
            component: Minedata,
        },
        {
            path: '/Changemobile',
            name: 'Changemobile',
            component: Changemobile,
        },
        {
            path: '/Project',
            name: 'Project',
            component: Project,
        },
        {
            path: '/Usercharge',
            name: 'Usercharge',
            component: Usercharge,
        },
        {
            path: '/SafeUser',
            name: 'SafeUser',
            component: SafeUser,
        },
        {
            path: '/Myorder',
            name: 'Myorder',
            component: Myorder,
        },
        
    ],
})
<template>
    <div style="height: 100vh;width: 100vw;">
        <!-- 页头 -->
        <nav-header2 style="position: absolute;z-index: 999;"></nav-header2>
        <div style="height: 50px;"></div>
        <div style="display: flex;height: calc(100% - 50px);width: 100%;">
            <!-- 侧边栏 -->
            <side-bar></side-bar>
            <div style="flex: 1;padding: 10px;background-color: #e4e7ed;display: flex;">
                <div class="content-box">
                    <div>
                        <div class="tab">
                            <span style="color: #999999;">子账户管理</span>
                        </div>
                        <div class="title-box">
                            <!-- 标题 -->
                            <div class="title">子账户列表</div>
                            <!-- 按钮 -->
                            <div class="btn-box">
                                <div class="btn" @click="deleteallshow()">批量删除</div>
                                <div class="btn">排序</div>
                                <div class="btn" @click="exportExcel()">
                                    <img class="exportimg" src="../assets/img/export.png">
                                    导出
                                </div>
                            </div>
                        </div>

                        <!-- 下拉选择框 -->
                        <div class="select-box">
                            <div class="select">
                                <el-date-picker @change="changestartdate()" v-model="startdate" type="date"
                                    placeholder="注册日期（起）">
                                </el-date-picker>
                            </div>
                            <div class="select">
                                <el-date-picker @change="changestartdate()" v-model="enddate" type="date"
                                    placeholder="注册日期（止）">
                                </el-date-picker>
                            </div>
                            <div class="select">
                                <el-input v-model="searchphone" placeholder="请输入手机号"></el-input>
                            </div>
                            <div class="search" @click="handleSearch()">查询</div>
                        </div>
                        <div>
                            <div class="networkListtitle">
                                <div class="inputcheck">
                                    <div class="titlebox" style="width: 32px;">
                                        <el-checkbox v-model="checkAll" @change="checkall()"></el-checkbox>
                                    </div>
                                    <div class="username titlebox">
                                        用户名</div>
                                </div>
                                <div class="usermobile titlebox">电话</div>
                                <div class="userfirm titlebox">公司</div>
                                <div class="useremail titlebox">邮箱</div>
                                <div class="userdate titlebox">注册日期</div>
                                <div class="networknum titlebox">网络数量</div>
                                <div class="equipmentnum titlebox">设备数量</div>
                                <div class="networkoperate titlebox">
                                    <div style="width: 240px; ">操作</div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="userList" v-for="i in displayedBoxes" :key="i.id">
                                <div class="inputcheck">
                                    <div style="width: 32px;">
                                        <el-checkbox v-model="i.checked"></el-checkbox>
                                    </div>
                                    <div class="username">{{ i.name }}</div>
                                </div>
                                <div class="usermobile">{{ i.mobile }}</div>
                                <div class="userfirm">{{ i.firm }}</div>
                                <div class="useremail">{{ i.email }}</div>
                                <div class="userdate">{{ time(i.date) }}</div>
                                <div class="networknum">{{ i.networkslen }}/{{ i.networknum }}</div>
                                <div class="equipmentnum">{{ i.Memberslen }}/{{ i.equipmentnum }}</div>
                                <div class="networkoperate">
                                    <div class="userkbtn" @click="shownotice(i)">删除该用户</div>
                                    <div class="userkbtn" @click="changenumber(i)">设定网络和设备数量</div>
                                </div>

                                <!-- 删除用户 -->
                                <div v-if="i.notice" class="notice">
                                    <div class="overlay"></div>
                                    <div class="deleteallbox">
                                        <div style="padding: 20px;">提示</div>
                                        <div style="text-align: center;padding: 20px 30px">确定删除此用户？删除后用户信息将会被彻底清除！
                                        </div>
                                        <div style="margin-top: 16px;">
                                            <div style="display: flex;justify-content: flex-end;">
                                                <div @click="shownotice(i)" class="addbtn" style="margin-left: 0px;">取消
                                                </div>
                                                <div @click="userDelete(i.id)" class="addbtn"
                                                    style="color: white;background-color: #012f55;margin-left: 20px;margin-right: 15px;margin-bottom: 20px;">
                                                    确定</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- 设定数量 -->
                        <div v-if="shownumber" class="notice">
                            <div class="overlay"></div>
                            <div class="addbox">
                                <div class="addtitle">设置数量</div>
                                <div class="addtable">
                                    <el-form :rules="rules" ref="form" :model="form" label-width="80px">
                                        <el-form-item label="网络数量" prop="name">
                                            <el-input placeholder="请输入网络数量" v-model="form.networknum"></el-input>
                                        </el-form-item>
                                        <el-form-item label="设备数量">
                                            <el-input placeholder="请输入设备数量" v-model="form.equipmentnum"></el-input>
                                        </el-form-item>
                                    </el-form>
                                    <div style="display: flex;justify-content: end;">
                                        <div class="addbtn" @click="changenumber()">取消</div>
                                        <div class="addbtn" @click="enternumber(i)"
                                            style="background-color: #012f55;color: white;">确认
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- 删除所有展示 -->
                        <div v-if="alldelete">
                            <div class="overlay"></div>
                            <div class="deleteallbox">
                                <div style="padding: 20px;">提示</div>
                                <div style="text-align: center;padding: 20px 30px">确定删除所选用户？删除后用户信息将会被彻底清除！</div>
                                <div style="margin-top: 16px;">
                                    <div style="display: flex;justify-content: flex-end;">
                                        <div @click="deleteallcancel()" class="addbtn" style="margin-left: 0px;">取消
                                        </div>
                                        <div @click="deleteallenter()" class="addbtn"
                                            style="color: white;background-color: #012f55;margin-left: 20px;margin-right: 15px;margin-bottom: 20px;">
                                            确定</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <!-- 分页 -->
                        <div class="currentpage">
                            <button class="pagination" @click="goToPreviousPage()" :disabled="currentPage === 1"
                                :class="{ 'pagination-hover': currentPage !== 1 }">上一页</button>
                            <span v-for="page in pages" :key="page">
                                <button @click="goToPage(page)" class="pagination2"
                                    :class="{ 'pagination-active': page === currentPage }">{{
                                    page }}</button>
                            </span>
                            <button class="pagination" @click="goToNextPage()"
                                :class="{ 'pagination-hover': currentPage !== totalPages }"
                                :disabled="currentPage === totalPages">下一页</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>
</template>

<script>
import excel from '@/libs/excel'
export default {
    data() {
        return {
            selectedUser: null,
            alldelete: false,
            shownumber: false,
            startdate: '',
            enddate: '',
            fuserList: [],
            userList: [],
            // dataList: [],
            search: '',
            searchphone: '',
            checkAll: '',
            currentPage: 1,
            itemsPerPage: 16,
            form: {
                networknum: '',
                equipmentnum: ''
            },
            rules: {
                networknum: [
                    { required: true, message: '请输入网络数量', trigger: 'blur' },
                    { min: 1, max: 20, trigger: 'blur' }
                ],
                equipmentnum: [
                    { required: true, message: '请输入设备数量', trigger: 'blur' },
                    { min: 0, max: 20, trigger: 'blur' }
                ],
            },
        }
    },
    created() {
        this.$js.token();
        sessionStorage.setItem('tabid', '8');
        sessionStorage.setItem('path', '/Userdata');
        sessionStorage.setItem('netpage', '1');
        this.selectuser()
        this.changeheight()
    },
    computed: {
        displayedBoxes() {
            const startIndex = this.currentPage * this.itemsPerPage - this.itemsPerPage
            const endIndex = startIndex + this.itemsPerPage
            return this.fuserList.slice(startIndex, endIndex)
        },
        totalPages() {
            return Math.ceil(this.fuserList.length / this.itemsPerPage)
        },
        pages() {
            const pages = [1];
            for (let i = 2; i <= this.totalPages; i++) {
                pages.push(i);
            }
            return pages;
        }
    },
    methods: {
        goToPreviousPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
            }
        },
        goToNextPage() {
            if (this.currentPage < this.totalPages) {
                this.currentPage++;
            }
        },
        goToPage(page) {
            if (page >= 1 && page <= this.totalPages) {
                this.currentPage = page;
            }
        },
        // 获取页面大小
        changeheight() {
            if (document.documentElement.clientHeight <= 800 && document.documentElement.clientHeight > 750) {
                this.itemsPerPage = 12
            } else if (document.documentElement.clientHeight <= 750 && document.documentElement.clientHeight > 720) {
                this.itemsPerPage = 11
            } else if (document.documentElement.clientHeight < 720) {
                this.itemsPerPage = 7
            }
        },
        // 删除成员
        async userDelete(id) {
            // console.log(id);
            let res = await this.$apiFun.deleteuser({ id })
            alert(res)
            location.reload()
        },
        // 展示删除框
        shownotice(item) {
            if (item.notice) {
                item.notice = !item.notice
            } else {
                this.fuserList.forEach(item => {
                    item.notice = false
                })
                item.notice = !item.notice
            }
        },
        //展示改变设备数量
        changenumber(i) {
            this.shownumber = !this.shownumber
            if (i) {
                this.selectedUser = i
                this.form.networknum = i.networknum
                this.form.equipmentnum = i.equipmentnum
            }
        },
        //改变设备数量
        async enternumber() {
            let id = this.selectedUser.id
            let networknum = this.form.networknum
            let equipmentnum = this.form.equipmentnum
            let res = await this.$apiFun.changenumber({ id, networknum, equipmentnum })
            console.log(res);
            location.reload()
        },
        // 时间开始
        changestartdate() {
                this.fuserList = [];
                let start = this.startdate ? this.startdate.getTime() : 0;
                let end = this.enddate ? this.enddate.getTime() : Infinity;
                this.fuserList = this.userList.filter(item => {
                    let itemDate = item.date
                    return itemDate >= start && itemDate <= end;
                });

        },
        //批量删除
        deleteallshow() {
            const allFalse = this.fuserList.every(obj => obj.checked === false);
            if (allFalse) {
                alert('请选择需要删除的用户')
            } else {
                this.alldelete = true
            }
        },
        deleteallcancel() {
            this.alldelete = false
        },
        deleteallenter() {
            const startIndex = this.currentPage * this.itemsPerPage - this.itemsPerPage
            const endIndex = startIndex + this.itemsPerPage
            this.fuserList.slice(startIndex, endIndex).forEach(async item => {
                if (item.checked == true) {
                    let id = item.nwid
                    let res = await this.$apiFun.networksDelete({ id });
                    alert(res)
                }
            })
            this.alldelete = false
        },
        // 搜索功能
        handleSearch() {
            this.fuserList = [];
            this.userList.map(item => {
                if (Object.values(item).some(value => value.toString().indexOf(this.searchphone) !== -1)) {
                    this.fuserList.push(item);
                }
            });
        },
        // 时间戳转换
        time(date) {
            let timestamp = parseInt(date);
            let time = new Date(timestamp);
            // 获取年、月、日
            let year = time.getFullYear();
            let month = ("0" + (time.getMonth() + 1)).slice(-2); // 月份是从0开始的，所以需要加1
            let day = ("0" + time.getDate()).slice(-2);
            // 格式化日期
            let formattedDate = year + "-" + month + "-" + day;
            return formattedDate
        },
        // 全选
        checkall() {
            this.fuserList.forEach(item => item.checked = this.checkAll)
        },
        //导出操作
        exportExcel() {
            let List = []
            this.fuserList.forEach(item => {
                if (item.checked == true) {
                    List.push(item)
                }
            })
            if (List.length > 0) {
                const params = {
                    title: ['账号', '电话', '邮箱', '注册日期', '网络数量', '设备数量'],
                    key: ['name', 'mobile', 'email', 'date', 'networknum', 'equipmentnum'],
                    data: List, // 数据源
                    autoWidth: true, //autoWidth等于true，那么列的宽度会适应那一列最长的值
                    filename: '客户数据'
                }
                excel.exportArrayToExcel(params)
            } else {
                alert('请勾选需要导出的网络')
            }
        },
        //查询用户信息
        async selectuser() {
            let Memberslen = 0
            let networkslen = 0
            let id = JSON.parse(sessionStorage.getItem('user')).id
            let issuper = JSON.parse(sessionStorage.getItem('user')).issuper
            let res = await this.$apiFun.selectuser({ id, issuper });
            if (res == false) {
                console.log(res);
            } else {
                this.userList = res
                this.userList.forEach(async (item) => {
                    let userid = item.id
                    let res = await this.$apiFun.networks({ userid });
                    // 获取已使用成员数量
                    let memberPromises = res.map(async (item) => {
                        let id = item.nwid
                        let length2 = await this.$apiFun.getnetworksMember({ id });
                        return Object.keys(length2).length
                    })
                    let memberCounts = await Promise.all(memberPromises)
                    Memberslen = memberCounts.reduce((a, b) => a + b, 0)
                    // 等待异步执行结束后再执行
                    networkslen = res.length
                    this.$set(item, 'networkslen', networkslen)
                    this.$set(item, 'Memberslen', Memberslen)
                })
                //生成checked
                this.userList.forEach(item => this.$set(item, 'checked', false))
                // 生成notice
                this.userList.forEach(item => this.$set(item, 'notice', false))
                this.userList.sort((a, b) => {
                    return a.date - b.date
                })
                this.fuserList = this.userList
            }
        },
        // 修改操作
        updata() {
            alert('修改还没做')
        }
    }
}
</script>

<style scoped>
.content-box {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    padding: 0 1.1%;
    background-color: white;
    overflow-y: hidden;
    overflow-x: auto;
    white-space: nowrap;
}

.tab {
    font-size: 12px;
    padding-top: 10px;
    height: 38.8px;
    line-height: 38.8px;
}

.title-box {
    margin: 20px 0px 10px;
    height: 30px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ebeef5;
}

.title {
    font-size: 14px;
    font-weight: bold;
}

.btn-box {
    margin: -15px 0px 0px 0px;
    display: flex;
}

.addbox {
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 600px;
    height: 300px;
    z-index: 999;
    background-color: white;
}

.addtitle {
    padding: 25px 0px 0px 15px;
    font-size: 14px;
    font-weight: bold;
}

.addtable {
    margin-top: 40px;
    padding: 0px 80px;
}

.addbtn {
    height: 30px;
    line-height: 30px;
    background: #FAFAFA;
    padding: 0px 25px;
    font-size: 14px;
    border: 1px solid #dcdcdc;
    margin-left: 10px;
    cursor: pointer;
}

.notice {
    color: #333333;
    z-index: 999;
}

.overlay {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    /* 半透明黑色背景 */
    z-index: 999;
    /* 确保在上层 */
}


.deleteallbox {
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
    background-color: white !important;
}


.userkbtn:hover {
    color: #70c31d;
    cursor: pointer;
}

.exportimg {
    width: 14px;
    height: 14px;
    margin-right: 10px;
}

.select-box {
    display: flex;
}

.select {
    margin: 0px 10px 20px 0px;
}

.search {
    margin: 0px 10px 20px 0px;
    line-height: 32px;
    padding: 0 16px;
    background-color: #012f55;
    color: white;
    cursor: pointer;
}

.networkListtitle {
    font-size: 14px;
    box-sizing: border-box;
    position: relative;
    display: flex;
    padding: 0 8px;
    font-weight: bold;
    background-color: #fafafb;
}

.inputcheck {
    display: flex;
}

.username {
    width: 140px;
}


.select-box:deep(.el-input__inner) {
    width: 151px;
    height: 32px;
}

.select-box:deep(.el-date-editor.el-input, .el-date-editor.el-input__inner) {
    width: 150px;
}

.select-box:deep(.el-input__icon) {
    line-height: 32px;
}

.select:deep(.el-input__inner:focus) {
    border-color: #012f55;
}

.select:deep(.el-input.is-focus .el-input__inner) {
    border-color: #012f55;
}

.btn {
    display: flex;
    align-items: center;
    padding: 0px 16px;
    height: 32px;
    line-height: 32px;
    margin: 0px 10px 0px 0px;
    border: 1px solid #e7e9f1;
    cursor: pointer;
    font-size: 14px;
    color: #666666;
}

.addbtn {
    height: 30px;
    line-height: 30px;
    background: #FAFAFA;
    padding: 0px 25px;
    font-size: 14px;
    border: 1px solid #dcdcdc;
    margin-left: 10px;
    cursor: pointer;
}

.deleteallbox {
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* width: 210px; */
    /* height: 100px; */
    z-index: 999;
    background-color: white !important;
}


.userList {
    display: flex;
    border-bottom: 1px solid #ebeef5;
    padding: 8px;
}
.userList:hover {
    background: #FAFAFB;
}

.usermobile {
    width: 12.3%;
    flex-shrink: 0;
}

.userfirm {
    width: 160px;
    text-overflow: ellipsis;
    overflow: hidden;
    flex-shrink: 0;
}

.useremail {
    width: 228px;
    flex-shrink: 0;
}

.userdate {
    width: 180px;
    flex-shrink: 0;
}

.networknum {
    width: 9.22%;
    flex-shrink: 0;
}

.equipmentnum {
    width: 9.22%;
    flex-shrink: 0;
}

.networkoperate {
    position: relative;
    width: 240px;
    display: flex;
    justify-content: space-between;
}

.userkbtn {
    color: #337AB7;
    border: none;
    cursor: pointer;
    height: 100%;
}


.inputcheck:deep(.el-checkbox__inner) {
    background: #edf2fc;
    border-color: #dcdfe6;
}

.inputcheck:deep(.el-checkbox__inner) {
    background: #edf2fc;
    border-color: #dcdfe6;
}

.inputcheck:deep(.el-checkbox__input.is-focus .el-checkbox__inner) {
    border-color: #012f55;
}

.inputcheck:deep(.el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner) {
    background: #012f55;
    border-color: #012f55;
}

.select:deep(.el-input__inner) {
    line-height: 32px;
}

.select:deep(.el-input__inner:hover) {
    border-color: #012f55;
}

.notice:deep(.el-input__inner:focus) {
    border-color: #012f55;
}

.notice:deep(.el-input.is-focus .el-input__inner) {
    border-color: #012f55;
}

.titlebox {
    padding: 8px 0px;
    border-top: 1px solid #e7e9f1;
    border-bottom: 1px solid #e7e9f1;
}

@media screen and (min-width: 1680px) {
    .titlebox {
        padding: 8px 0px;
        border: none;
    }

    .networkListtitle {
        background-color: #fafafb;
        border-top: 1px solid #e7e9f1;
        border-bottom: 1px solid #e7e9f1;
    }
}
</style>
<template>
  <div style="height: 100vh;width: 100vw;">
    <!-- 页头 -->
    <nav-header2 style="position: absolute;z-index: 999;"></nav-header2>
    <div style="height: 50px;"></div>
    <div style="display: flex;height: calc(100% - 50px);width: 100%;">
      <!-- 侧边栏 -->
      <side-bar></side-bar>
      <div style="flex: 1;padding: 10px;background-color: #e4e7ed;display: flex;">
        <div class="content-box">
          <div>
            <div class="tab">
              <span style="color: #999999;">增值服务</span>
              <span style="margin: 0px 10px;">></span>
              <span>我的订单</span>
            </div>
            <div class="title-box">
              <div class="title">我的订单</div>
            </div>
            <!-- 下拉选择框 -->
            <div class="select-box">
              <div class="select">
                <el-date-picker
                  @change="changestartdate()"
                  v-model="startdate"
                  type="date"
                  placeholder="注册日期（起）"
                >
                </el-date-picker>
              </div>
              <div class="select">
                <el-date-picker
                  @change="changestartdate()"
                  v-model="enddate"
                  type="date"
                  placeholder="注册日期（止）"
                >
                </el-date-picker>
              </div>
              <div class="select">
                <el-input
                  v-model="orderid"
                  placeholder="请输入订单号"
                ></el-input>
              </div>
              <div
                class="search"
                @click="handleSearch()"
              >查询</div>
            </div>
            <!-- 表单部分 -->
            <div>
              <div class="orderListtitle">
                <div class="orderid">订单号</div>
                <div class="ordertype">资源类型</div>
                <div class="orderdate">购买时间</div>
                <div class="orderprice">购买金额</div>
                <div class="orderstate">支付状态</div>
              </div>
              <div
                class="orderList"
                v-for="item in displayedBoxes"
                :key="item.id"
              >
                <div class="orderid">{{ item.orderid }}</div>
                <div class="ordertype">{{ item.name }}</div>
                <div class="orderdate">{{ time(item.date) }}</div>
                <div class="orderprice">{{ item.price }}</div>
                <div class="orderstate">{{ item.state }}</div>
              </div>
            </div>
          </div>
          <div>
            <!-- 分页 -->
            <div class="currentpage">
              <button
                class="pagination"
                @click="goToPreviousPage()"
                :disabled="currentPage === 1"
                :class="{ 'pagination-hover': currentPage !== 1 }"
              >上一页</button>
              <span
                v-for="page in pages"
                :key="page"
              >
                <button
                  @click="goToPage(page)"
                  class="pagination2"
                  :class="{ 'pagination-active': page === currentPage }"
                >{{
                                    page }}</button>
              </span>
              <button
                class="pagination"
                @click="goToNextPage()"
                :class="{ 'pagination-hover': currentPage !== totalPages }"
                :disabled="currentPage === totalPages"
              >下一页</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      startdate: '',
      enddate: '',
      orderid: '',
      forderList: [],
      orderList: [],
      currentPage: 1,
      itemsPerPage: 16,
    }
  },
  created() {
    this.$js.token();
    sessionStorage.setItem('tabid', '52');
    sessionStorage.setItem('path', '/Myorder');
    this.changeheight()
    this.getorderList()
  },
  computed: {
    displayedBoxes() {
      const startIndex = this.currentPage * this.itemsPerPage - this.itemsPerPage
      const endIndex = startIndex + this.itemsPerPage
      return this.forderList.slice(startIndex, endIndex)
    },
    totalPages() {
      return Math.ceil(this.orderList.length / this.itemsPerPage)
    },
    pages() {
      const pages = [1];
      for (let i = 2; i <= this.totalPages; i++) {
        pages.push(i);
      }
      return pages;
    }
  },
  methods: {
    // 分页按钮
    goToPreviousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    goToNextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    goToPage(page) {
      if (page >= 1 && page <= this.totalPages) {
        this.currentPage = page;
      }
    },
    // 时间戳转换
    time(date) {
      let timestamp = parseInt(date);
      let time = new Date(timestamp);
      // 获取年、月、日
      let year = time.getFullYear();
      let month = ("0" + (time.getMonth() + 1)).slice(-2); // 月份是从0开始的，所以需要加1
      let day = ("0" + time.getDate()).slice(-2);
      // 格式化日期
      let formattedDate = year + "-" + month + "-" + day;
      return formattedDate
    },
    // 选择时间
    changestartdate() {
      this.forderList = [];
      let start = this.startdate ? this.startdate.getTime() : 0;
      let end = this.enddate ? this.enddate.getTime() : Infinity;
      this.forderList = this.orderList.filter(item => {
        let itemDate = item.date
        return itemDate >= start && itemDate <= end;
      });
    },
    // 搜索功能
    handleSearch() {
      this.forderList = this.orderList.filter(item => (item.orderid == this.orderid))
    },
    // 获取订单列表
    async getorderList() {
      let data = JSON.parse(sessionStorage.getItem('user'));
      let userid = data.id
      let issuper = data.issuper
      let res = await this.$apiFun.orderlist({ userid });
      this.forderList = res
      this.orderList = res
    },
    // 获取页面大小
    changeheight() {
      if (document.documentElement.clientHeight <= 800 && document.documentElement.clientHeight > 750) {
        this.itemsPerPage = 12
      } else if (document.documentElement.clientHeight <= 750 && document.documentElement.clientHeight > 720) {
        this.itemsPerPage = 11
      } else if (document.documentElement.clientHeight < 720) {
        this.itemsPerPage = 7
      }
    },
  }
}
</script>

<style scoped>
.content-box {
  min-width: 1300px;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  padding: 0 1.1%;
  background-color: white;
  overflow-y: hidden;
  overflow-x: auto;
  white-space: nowrap;
}

.tab {
  font-size: 12px;
  padding-top: 10px;
  height: 38.8px;
  line-height: 38.8px;
}

.orderListtitle {
  font-size: 14px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  padding: 8px;
  font-weight: bold;
  border-top: 1px solid #e7e9f1;
  border-bottom: 1px solid #e7e9f1;
  background-color: #fafafb;
}

.title-box {
  margin: 20px 0px 10px;
  height: 30px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ebeef5;
}

.title {
  font-size: 14px;
  font-weight: bold;
}

.select-box {
  display: flex;
}

.select {
  margin: 0px 10px 20px 0px;
}

.search {
  margin: 0px 10px 20px 0px;
  line-height: 32px;
  padding: 0 16px;
  background-color: #012f55;
  color: white;
  cursor: pointer;
}

.orderList {
  font-size: 14px;
  display: flex;
  flex-shrink: 0;
  padding: 8px;
  flex-shrink: 0;
  border-bottom: 1px solid #ebeef5;
}

.orderList:hover {
  background: #fafafb;
}

.orderid {
  width: 22%;
  min-width: 190.69px;
  flex-shrink: 0;
  margin-top: 1px;
  margin-left: 32px;
}

.ordertype {
  width: 16%;
  min-width: 175.72px;
  flex-shrink: 0;
  margin-top: 1px;
}

.orderdate {
  width: 20%;
  min-width: 175.72px;
  flex-shrink: 0;
  margin-top: 1px;
}

.orderprice {
  width: 20.5%;
  min-width: 175.72px;
  flex-shrink: 0;
  margin-top: 1px;
}
.orderstate {
  width: 20.5%;
  min-width: 175.72px;
  flex-shrink: 0;
  margin-top: 1px;
}

.select-box:deep(.el-input__inner) {
  width: 151px;
  height: 32px;
}

.select-box:deep(.el-date-editor.el-input, .el-date-editor.el-input__inner) {
  width: 150px;
}

.select-box:deep(.el-input__icon) {
  line-height: 32px;
}

.select:deep(.el-input__inner:focus) {
  border-color: #012f55;
}

.select:deep(.el-input.is-focus .el-input__inner) {
  border-color: #012f55;
}

.select:deep(.el-input__inner) {
  line-height: 32px;
}

.select:deep(.el-input__inner:hover) {
  border-color: #012f55;
}

@media screen and (min-width: 1680px) {
  .orderListtitle {
    background-color: #fafafb;
    border-top: 1px solid #e7e9f1;
    border-bottom: 1px solid #e7e9f1;
  }
}
</style>
<template>
    <div style="height: 100vh;width: 100vw;">
        <!-- 页头 -->
        <nav-header2 style="position: absolute;z-index: 999;"></nav-header2>
        <div style="height: 50px;"></div>
        <div style="display: flex;height: calc(100% - 50px);width: 100%;">
            <!-- 侧边栏 -->
            <side-bar></side-bar>
            <div style="flex: 1;padding: 10px;background-color: #e4e7ed;display: flex;">
                <div class="content-box">
                    <div>
                        <div class="tab">
                            <span style="color: #999999;">增值服务</span>
                            <span style="margin: 0px 10px;">></span>
                            <span>产品购买</span>
                        </div>
                        <div class="title-box">
                            <!-- 标题 -->
                            <div class="title2">产品购买</div>
                        </div>
                    </div>
                    <div>
                        <h3 class="charge-title">套餐</h3>
                        <div class="charge-box">
                            <div @click="changecharge(item)" v-for="(item) in netList" :key="item.id"
                                class="charge-item" :class="selectid == item.id ? 'charge-select' : ''">
                                <div class="charge-price">￥{{ formattedPrice(item.price) }}元</div>
                                <div class="charge-name">包含{{ item.name }}</div>
                            </div>
                        </div>
                    </div>
                    <!-- <div>
                        <h3 class="charge-title">设备套餐</h3>
                        <div class="charge-box">
                            <div @click="changecharge(item)" v-for="(item) in equipmentList" :key="item.id"
                                class="charge-item" :class="selectid == item.id ? 'charge-select' : ''">
                                <div class="charge-price">￥{{ formattedPrice(item.price) }}元</div>
                                <div class="charge-name">包含{{ item.name }}</div>
                            </div>
                        </div>
                    </div> -->
                    <div>
                        <h3 class="charge-title">支付方式</h3>
                        <div class="radio charge-box">
                            <el-radio v-model="chargemethod" label="ali">支付宝</el-radio>
                            <el-radio v-model="chargemethod" label=vx>微信</el-radio>
                        </div>
                    </div>
                    <div>
                        <h3 class="charge-title">支付金额</h3>
                        <div class="charge-box">
                            <div class="money">￥{{ money ? formattedPrice(money) : '0' }}元</div>
                            <div class="btn" @click="alipay()">立即支付</div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <button @click="alipay()">点击</button> -->
        </div>
        <div v-if="chargeshow">
            <div class="overlay"></div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            chargeshow: false,
            selectid: '',
            money: '',
            name: '',
            type: '',
            num: '',
            chargemethod: '',
            netList: [{
                'price': '100.00',
                'name': '5个网络',
                'id': '11',
                'num': '5',
                'type':'网络'
            }, {
                'price': '100.00',
                'name': '5个设备',
                'id': '21',
                'num': '5',
                'type':'设备'
            },],
            equipmentList: [{
                'price': '100.00',
                'name': '5个设备',
                'id': '21',
                'num': '5',
                'type':'设备'
            }, {
                'price': '200.00',
                'name': '10个设备',
                'id': '22',
                'num': '10',
                'type':'设备'
            },]
        }
    },
    created(){
        this.$js.token();
    },
    methods: {
        formattedPrice(price) {
            // 将字符串形式的价格转换为浮点数，然后再转换为字符串，这样会自动去除尾部无意义的0
            return parseFloat(price).toString();
        },
        async alipay() {
            // this.chargeshow = true
            if (this.chargemethod && this.selectid) {
                if (this.chargemethod == 'ali') {
                    const h = this.$createElement;
                    let data = JSON.parse(sessionStorage.getItem('user'));
                    let date = Date.now();
                    let id = date * 10000 + Math.floor(1000 + Math.random() * 9000);
                    let userid = data.id
                    let price = this.money
                    let type = this.type
                    let name = this.name
                    let num = this.num
                    let res = await this.$apiFun.alipay({ id, userid, price, name, date ,type,num})
                    console.log(res);
                    const newWindow = window.open("", "_blank");
                    newWindow.document.write(res);
                    // this.$message({
                    //     message: h('p', null, [
                    //         h('span', { style: 'color: teal' }, '支付宝支付'),
                    //     ])
                    // });
                } else {
                    const h = this.$createElement;
                    this.$message({
                        message: h('p', null, [
                            h('span', { style: 'color: teal' }, '微信支付'),
                        ])
                    });
                }
            } else {
                const h = this.$createElement;
                this.$message({
                    message: h('p', null, [
                        h('span', { style: 'color: red' }, '请选择需要的内容 '),
                    ])
                });
            }
        },
        changecharge(item) {
            this.selectid = item.id
            this.money = item.price
            this.name = item.name
            this.type = item.type
            this.num = item.num
        }
    }
}
</script>
<style scoped>
.content-box {
    display: flex;
    flex: 1;
    flex-direction: column;
    position: relative;
    padding: 0 1.1%;
    background-color: white;
    overflow-x: auto;
    white-space: nowrap;
}

.tab {
    font-size: 12px;
    padding-top: 10px;
    height: 38.8px;
    line-height: 38.8px;
}

.title-box {
    margin: 20px 0px 10px;
    height: 30px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ebeef5;
}

.title2 {
    font-size: 14px;
    font-weight: bold;
}

.charge-box {
    display: flex;
    margin-left: 60px;
}

.charge-title {
    margin: 20px 10px;
}

.charge-item {
    /* background-color: red; */
    width: 150px;
    height: 100px;
    padding: 10px 0px;
    margin-right: 20px;
    border: 1px solid #ebeef5;
    cursor: pointer;
}

.charge-item:hover {
    background: #FAFAFB;
}

.charge-price {
    font-size: 28px;
    color: #012f55;
    text-align: center;
    padding-bottom: 10px;
}

.charge-name {
    font-size: 16px;
    border-top: 1px solid #ebeef5;
    padding-top: 10px;
    margin: 0px 20px;
    text-align: center;
}

.money {
    height: 32px;
    line-height: 32px;
    margin-right: 20px;
    font-size: 25px;
    color: #012f55;
}

.btn {
    align-items: center;
    padding: 0px 16px;
    width: fit-content;
    height: 32px;
    line-height: 32px;
    margin: 0px 10px 0px 0px;
    /* border: 1px solid #e7e9f1; */
    background-color: #012f55;
    cursor: pointer;
    font-size: 14px;
    color: white;
}

.overlay {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    /* 半透明黑色背景 */
    z-index: 999;
    /* 确保在上层 */
}

.charge-select {
    border: 1px solid #012f55;
}

.radio:deep(.el-radio__input.is-checked + .el-radio__label) {
    color: #012f55 !important;
}

.radio:deep(.el-radio__input.is-checked .el-radio__inner) {
    background: #012f55 !important;
    border-color: #012f55 !important;
}

.radio:deep(.el-radio__inner:hover) {
    border-color: #012f55 !important;
}
</style>
<template>
    <div style="height: 100vh;width: 100vw;">
        <!-- 页头 -->
        <nav-header2 style="position: absolute;z-index: 999;"></nav-header2>
        <div style="height: 50px;"></div>
        <div style="display: flex;height: calc(100% - 50px);width: 100%;">
            <!-- 侧边栏 -->
            <side-bar></side-bar>
            <div style="flex: 1;padding: 10px;background-color: #e4e7ed;display: flex;">
                <!-- 网络详细信息 -->
                <div class="content-box">
                    <div>
                        <div class="tab">
                            <span style="color: #999999;">网络管理</span>
                            <span style="margin: 0px 10px;">></span>
                            <span @click="page()" class="networktab">网络列表</span>
                            <span style="margin: 0px 10px;">></span>
                            <span>设备管理</span>
                        </div>
                        <div class="title-box">
                            <!-- 标题 -->
                            <div class="title">设备列表</div>
                            <!-- 按钮 -->
                            <div class="btn-box">
                                <div class="btn" @click="deleteallshow()">批量删除</div>
                                <div class="btn">排序</div>
                                <div class="btn" @click="exportExcel()">
                                    <img class="exportimg" src="../assets/img/export.png">
                                    导出
                                </div>
                            </div>
                        </div>
                        <!-- 下拉选择框 -->
                        <div class="select-box">
                            <div class="select">
                                <el-select @change="changeequipment()" v-model="equipment" placeholder="请选择设备状态">
                                    <el-option label="所有设备" value="所有设备">
                                    </el-option>
                                    <el-option label="在线设备" value="在线">
                                    </el-option>
                                    <el-option label="离线设备" value="离线">
                                    </el-option>
                                </el-select>
                            </div>
                            <div class="select">
                                <el-input v-model="searchname" placeholder="请输入设备名称"></el-input>
                            </div>
                            <div class="select">
                                <el-input v-model="searchid" placeholder="请输入设备ID"></el-input>
                            </div>
                            <div class="search" @click="handleSearch()">查询</div>
                        </div>

                        <!-- 网络id部分 -->
                        <div>
                            <div class="equipmenttitle">
                                <div class="inputcheck">
                                    <div style="width: 32px;">
                                        <el-checkbox v-model="checkAll" @change="checkall()"></el-checkbox>
                                    </div>
                                    <div class="equipmentstate" style="font-weight: bold;">设备状态</div>
                                </div>
                                <div class="equipmentname">设备名称</div>
                                <!-- <div class="equipmentindex">编号</div> -->
                                <div class="equipmentid">设备ID</div>
                                <div class="equipmentid">设备IP</div>
                                <!-- <div class="equipmentlabel">加入网络</div> -->
                                <div class="equipmentlabel">授权</div>
                                <div class="equipmentoperate" style="color: #333333;">
                                    <div style="width: 100%;">操作</div>
                                </div>
                            </div>
                            <div class="networkList" v-for="item in displayedBoxes" :key="item.id">
                                <div style="display: flex;">
                                    <div class="inputcheck">
                                        <div style="width: 32px;">
                                            <el-checkbox v-model="item.checked"></el-checkbox>
                                        </div>
                                        <div class="equipmentstate">
                                            <div class="iconfont icon-lvdeng light"
                                                :style="item.state == '在线' ? 'color: #13ce66;' : 'color: #999999;'">
                                            </div>
                                            <div style="padding-left: 15px">{{ item.state }}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="equipmentname">{{ item.name }}</div>
                                <!-- <div class="equipmentindex">{{ item.index }}</div> -->
                                <div class="equipmentid">{{ item.id }}</div>
                                <div class="equipmentid">{{ item.ipAssignments[item.ipAssignments.length - 1] }}</div>
                                <!-- <div class="equipmentlabel">
                                    <el-switch @change="changemember(item.id, item)" v-model="item.activeBridge"
                                        active-color="#13ce66" inactive-color="#999999">
                                    </el-switch>
                                </div> -->
                                <div class="equipmentlabel">
                                    <el-switch @change="changemember(item.id, item)" v-model="item.authorized"
                                        active-color="#13ce66" inactive-color="#999999">
                                    </el-switch>
                                    <!-- <input @change="changemember(item.id, item)" v-model="item.authorized" type="checkbox"> -->
                                </div>
                                <div class="equipmentoperate">
                                    <div v-if="item.notice" class="notice">
                                        <div class="overlay"></div>
                                        <div class="deleteallbox">
                                            <div style="padding: 20px;">提示</div>
                                            <div style="text-align: center;padding: 20px 30px">确定删除此网络？删除后此网络连接设备将会失效！
                                            </div>
                                            <div style="margin-top: 16px;">
                                                <div style="display: flex;justify-content: flex-end;">
                                                    <div @click="showdelete(item)" class="addbtn"
                                                        style="margin-left: 0px;">
                                                        取消
                                                    </div>
                                                    <div @click="memberDelete(item.id)" class="addbtn"
                                                        style="color: white;background-color: #012f55;margin-left: 20px;margin-right: 15px;;">
                                                        确定</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="networkbtn" @click="isaddshow(item.id, item.nwid)">设置设备</div>
                                    <div style="margin-left: 5%;" class="networkbtn" @click="showdelete(item)">删除设备
                                    </div>
                                </div>
                            </div>
                            <!-- 删除所有展示 -->
                            <div v-if="alldelete">
                                <div class="overlay"></div>
                                <div class="deleteallbox">
                                    <div style="padding: 20px;">提示</div>
                                    <div style="text-align: center;padding: 20px 30px">确定删除所选设备？删除后设备及其配置将会失效！</div>
                                    <div style="margin-top: 16px;">
                                        <div style="display: flex;justify-content: flex-end;">
                                            <div @click="deleteallcancel()" class="addbtn" style="margin-left: 0px;">取消
                                            </div>
                                            <div @click="deleteallenter()" class="addbtn"
                                                style="color: white;background-color: #012f55;margin-left: 20px;margin-right: 15px;;">
                                                确定</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- 添加地图内容 -->
                            <div v-if="addshow">
                                <div class="overlay"></div>
                                <!-- 地图功能 -->
                                <div v-if="mapshow" class="mapbox">
                                    <div class="addtitle">地图</div>
                                    <div class="mapsonbox">
                                        <div style="width: 900px;height: 350px;">
                                            <gd-map :fa-msg2="address" :fa-msg="position" @mapaddress="mapaddress"
                                                @maplist="maplist"></gd-map>
                                        </div>
                                        <div class="addressbox">
                                            <div>详细地址:</div>
                                            <!-- <input class="addressinput" type="text" readonly v-model="address"> -->
                                            <input class="addressinput" type="text" @input="onInput" v-model="address">
                                            <div v-if="maplistshow"
                                                style="width: 860px;height: 100px;position: absolute;overflow: auto;background-color: white;border: 1px solid #ddd;">
                                                <div class="maplist" @click="maplistclick(item)" v-for="item in mapList"
                                                    :key="item.id">
                                                    {{ item.name }} <span style="color: #7B7B7B;font-size: 12px;">{{
                                                        item.address }}</span> </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div style="display: flex;justify-content: end;width: 900px;margin: 0 auto;">
                                        <div class="addbtn" @click="cancelmap()">取消</div>
                                        <div class="addbtn" @click="mapenter()"
                                            style="background-color: #012f55;color: white;">
                                            确认
                                        </div>
                                    </div>
                                </div>
                                <!-- 添加功能 -->
                                <div class="addbox" v-if="!mapshow">
                                    <div class="addtitle">基本信息</div>
                                    <div class="addtable">
                                        <div>
                                            <el-form ref="form" :model="form" label-width="80px">
                                                <el-form-item label="设备名称" prop="name">
                                                    <el-input placeholder="请输入设备名称" v-model="form.name"></el-input>
                                                </el-form-item>
                                                <el-form-item label="所属网络" prop="net">
                                                    <el-input :disabled="true" placeholder="所属网络"
                                                        v-model="form.net"></el-input>
                                                </el-form-item>
                                                <el-form-item label="设备描述">
                                                    <el-input placeholder="请填写项目描述" type="textarea"
                                                        v-model="form.desc"></el-input>
                                                </el-form-item>
                                            </el-form>
                                        </div>
                                        <div style="margin-left: 100px;">
                                            <!-- 图片功能 -->
                                            <div style="display: flex;margin-bottom: 32px;">
                                                <div style="width: 80px;" class="el-form-item__label">上传图片</div>
                                                <div>
                                                    <div @click="selectFile()" class="head-img">
                                                        <img class="realhead" v-if="avatarUrl" :src="avatarUrl">
                                                        <img class="addhead" v-else src="../assets/img/addhead.png">
                                                    </div>
                                                    <div style="color: #7B7B7B;font-size: 10px;">仅支持jpg，png格式,且大小为5M以内
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="map">
                                                <el-form ref="form" :model="form" label-width="80px">
                                                    <el-form-item label="设备地图">
                                                        <el-input placeholder="请输入设备地址" disabled v-model="form.map">
                                                            <el-button @click="ismapshow()"
                                                                slot="append">选择地址</el-button>
                                                        </el-input>
                                                    </el-form-item>
                                                </el-form>
                                            </div>
                                            <div style="display: flex;justify-content: end;margin-top: 65px;">
                                                <div class="addbtn" @click="canceladd()">取消</div>
                                                <div class="addbtn" @click="save()"
                                                    style="background-color: #012f55;color: white;">
                                                    确认
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- 分页 -->
                    <div class="currentpage">
                        <button class="pagination" @click="goToPreviousPage()" :disabled="currentPage === 1"
                            :class="{ 'pagination-hover': currentPage !== 1 }">上一页</button>
                        <span v-for="page in pages" :key="page">
                            <button @click="goToPage(page)" class="pagination2"
                                :class="{ 'pagination-active': page === currentPage }">{{
                                    page }}</button>
                        </span>
                        <button class="pagination" @click="goToNextPage()" :disabled="currentPage === totalPages"
                            :class="{ 'pagination-hover': currentPage !== totalPages }">下一页</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import excel from '@/libs/excel'
export default {
    data() {
        return {
            alldelete: false,
            mapshow: false,
            maplistshow: false,
            ispicture: 0,
            addshow: false,
            fnetMemberList: [],
            netMemberList: [],
            mapList: [],
            equipment: '',
            searchname: '',
            networkid: '',
            searchid: '',
            equipmentname: '',
            equipmentid: '',
            selectedFile: null,
            avatarUrl: null,
            checkAll: false,
            address: '四川无涯智能科技有限公司',
            position: '',
            province: '',
            picturename: '',
            // 防抖节流
            time: null,
            t: null,
            form: {
                name: '',
                net: '',
                desc: '',
                map: ''
            },
            currentPage: 1,
            itemsPerPage: 16
        }
    },
    created() {
        this.$js.token();
        this.getnetworksMember()
        this.changeheight()
    },
    computed: {
        displayedBoxes() {
            const startIndex = this.currentPage * this.itemsPerPage - this.itemsPerPage
            const endIndex = startIndex + this.itemsPerPage
            return this.fnetMemberList.slice(startIndex, endIndex)
        },
        totalPages() {
            return Math.ceil(this.fnetMemberList.length / this.itemsPerPage)
        },
        pages() {
            const pages = [1];
            for (let i = 2; i <= this.totalPages; i++) {
                pages.push(i);
            }
            return pages;
        }
    },
    methods: {
        goToPreviousPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
            }
        },
        goToNextPage() {
            if (this.currentPage < this.totalPages) {
                this.currentPage++;
            }
        },
        goToPage(page) {
            if (page >= 1 && page <= this.totalPages) {
                this.currentPage = page;
            }
        },
        // 获取页面大小
        changeheight() {
            if (document.documentElement.clientHeight <= 800 && document.documentElement.clientHeight > 750) {
                this.itemsPerPage = 12
            } else if (document.documentElement.clientHeight <= 750 && document.documentElement.clientHeight > 720) {
                this.itemsPerPage = 11
            } else if (document.documentElement.clientHeight < 720) {
                this.itemsPerPage = 7
            }
        },
        // 子组件传递参数
        mapaddress(data) {
            this.address = data.address
            this.position = data.position
            this.province = data.province
        },
        maplist(data) {
            this.mapList = data
            // this.maplistshow = true
            // if (this.address == data[0].name) {
            //     this.position = [data[0].location.lng, data[0].location.lat]
            //     this.maplistshow = false
            // }
        },
        onInput() {
            if (this.t != null) {
                clearTimeout(this.t)
            }
            this.t = setTimeout(() => {
                this.maplistshow = true
            }, 1500)
        },
        // 地图展示
        async isaddshow(mid, id) {
            this.addshow = true
            this.equipmentid = mid
            let res = await this.$apiFun.getequipmentname({ mid, id });
            this.form.name = res.name
            this.form.desc = res.desc
            this.form.map = res.address
            this.address = res.address
            this.position = res.position.split(',');
            if (res.img != '0') {
                this.picturename = res.img
                this.avatarUrl = 'https://cloud.hi-wooya.com/equipmentimg/' + this.picturename
            }
        },
        //批量删除
        deleteallshow() {
            const allFalse = this.fnetMemberList.every(obj => obj.checked === false);
            if (allFalse) {
                alert('请选择需要删除的设备')
            } else {
                this.alldelete = true
            }
        },
        deleteallcancel() {
            this.alldelete = false
        },
        deleteallenter() {
            const startIndex = this.currentPage * this.itemsPerPage - this.itemsPerPage
            const endIndex = startIndex + this.itemsPerPage
            this.fnetMemberList.slice(startIndex, endIndex).forEach(async item => {
                if (item.checked == true) {
                    let id = item.nwid
                    let mid = item.id
                    let res = await this.$apiFun.memberDelete({ id, mid });
                }
            })
            this.alldelete = false
            alert('删除成功')
            location.reload()
        },
        canceladd() {
            this.addshow = false
        },
        ismapshow() {
            this.mapshow = true
        },
        cancelmap() {
            this.mapshow = false
        },
        mapenter() {
            this.form.map = this.address
            this.mapshow = false
        },
        // 设备图片
        selectFile() {
            // 创建一个<input>元素
            const input = document.createElement('input');
            input.type = 'file';
            // 触发文件选择操作
            input.click();
            // 监听文件选择
            input.addEventListener('change', (event) => {
                // 获取选择的文件
                this.selectedFile = event.target.files[0];
                this.avatarUrl = URL.createObjectURL(this.selectedFile);
                this.picturename = this.selectedFile.name;
                if (this.selectedFile.type != 'image/png' && this.selectedFile.type != 'image/jpeg') {
                    alert('只支持上传png或者jpg格式文件')
                    this.ispicture = 1
                } else {
                    this.ispicture = 2
                }
                if (this.selectedFile.size > 5242880) {
                    alert('图片大小最大为5M')
                    this.ispicture = 1
                } else {
                    this.ispicture = 2
                }
            });
        },
        // 上传设备数据
        async uploadAvatar() {
            let name = this.form.name
            let net = this.form.net
            let desc = this.form.desc
            let userid = JSON.parse(sessionStorage.getItem('user')).id
            let equipmentid = this.equipmentid
            let province = this.province
            let position = this.position[0] + ',' + this.position[1]
            let picture = this.picturename ? this.picturename : '0'
            let address = this.form.map
            this.networkid = this.$route.query.id
            let res2 = await this.$apiFun.networkDetail({ id: this.networkid });
            let project = res2.project
            if (this.ispicture == 2) {
                const formData = new FormData();
                formData.append('avatar', this.selectedFile);
                let res = await this.$apiFun.equipmentimg(formData);
            }
            let res1 = await this.$apiFun.updatequipment({ name, net, desc, equipmentid, picture, address, position, userid, province, project });
            // alert(res1)
        },
        // 保存设备功能
        async save() {
            await this.uploadAvatar()
            // alert('设置成功')
            location.reload()
        },
        //改变设备
        changeequipment() {
            this.fnetMemberList = [];
            if (this.equipment == '所有设备') {
                this.fnetMemberList = this.netMemberList
            } else {
                this.netMemberList.map(item => {
                    if (item.state.indexOf(this.equipment) !== -1) {
                        this.fnetMemberList.push(item);
                    }
                });
            }
        },
        // 搜索功能
        handleSearch() {
            this.fnetMemberList = [];
            this.netMemberList.map(item => {
                if (item.name.indexOf(this.searchname) !== -1) {
                    if (item.id.indexOf(this.searchid) !== -1) {
                        this.fnetMemberList.push(item);
                    }
                }
            });
        },
        // 打开ip
        openip(ip) {
            window.open('http://' + ip, '_blank');
        },
        //导出操作
        exportExcel() {
            let List = []
            const startIndex = this.currentPage * this.itemsPerPage - this.itemsPerPage
            const endIndex = startIndex + this.itemsPerPage
            this.netMemberList.slice(startIndex, endIndex).forEach(item => {
                if (item.checked == true) {
                    List.push(item)
                }
            })
            if (List.length > 0) {
                const params = {
                    title: ['设备状态', '设备名称', '编号', '设备ID', '设备IP'],
                    key: ['state', 'name', 'index', 'id', 'ipAssignments'],
                    data: List, // 数据源
                    autoWidth: true, //autoWidth等于true，那么列的宽度会适应那一列最长的值
                    filename: '设备列表'
                }
                excel.exportArrayToExcel(params)
            } else {
                alert('请勾选需要导出的网络')
            }
        },
        //修改设备名称
        namechange(mid, name) {
            if (this.time != null) {
                clearTimeout(this.time)
            }
            this.time = setTimeout(async () => {
                let names = await this.$apiFun.equipmentname({ mid, name });
            }, 3000)
        },
        //跳转网络列表
        page(id) {
            this.$js.openpages('/Networks', { id });
        },
        // 全选
        checkall() {
            this.netMemberList.forEach(item => item.checked = this.checkAll)
        },
        //批量删除
        deleteall() {
            let id = this.$route.query.id;
            this.netMemberList.forEach(async item => {
                if (item.checked == true) {
                    let mid = item.id
                    let res = await this.$apiFun.memberDelete({ id, mid });
                    alert(res)
                }
            })
        },
        // 点击选择地址
        maplistclick(item) {
            this.address = item.name
            this.position = [item.location.lng, item.location.lat]
            this.province = item.pname
            this.maplistshow = false
        },
        //修改成员详细信息
        async changemember(mid, netMemberList) {
            let id = this.$route.query.id;
            netMemberList.activeBridge = netMemberList.authorized
            let res2 = await this.$apiFun.networkDetail({ id });
            let project = res2.project
            let name = netMemberList.name
            let userid = res2.userid
            let res1 = await this.$apiFun.updatequipment({ id, name, net: id, desc: '', equipmentid: mid, picture: '0', address: '四川无涯智能科技有限公司', position: '104.231819,30.534400', userid, province: '四川省', project });
            if (res1 == '设置成功') {
                let res = await this.$apiFun.memberDetail({ id, mid, netMemberList });
            }
            alert(res1)
        },
        //获取成员信息
        async getnetworksMember() {
            let id = this.$route.query.id;
            this.form.net = id
            let res = await this.$apiFun.getnetworksMember({ id });
            this.netMemberList = res
            // 生成notice
            this.netMemberList.forEach(item => {
                this.$set(item, 'checked', false)
                this.$set(item, 'notice', false)
            })
            this.fnetMemberList = this.netMemberList
        },
        //删除成员信息
        async memberDelete(mid) {
            let id = this.$route.query.id;
            let res = await this.$apiFun.memberDelete({ id, mid });
            alert(res)
            location.reload()
        },
        //刷新
        flash() {
            this.netMemberList = []
            this.getnetworksMember()
        },
        // 展示删除框
        showdelete(item) {
            if (item.notice) {
                item.notice = !item.notice
            } else {
                this.fnetMemberList.forEach(item => {
                    item.notice = false
                })
                item.notice = !item.notice
            }
        }
    }
}
</script>

<style scoped>
.content-box {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    padding: 0 1.1%;
    background-color: white;
    overflow-y: hidden;
    overflow-x: auto;
    white-space: nowrap;
}

.tab {
    font-size: 12px;
    padding-top: 10px;
    height: 38.8px;
    line-height: 38.8px;
}

.networktab {
    color: #999999;
}

.networktab:hover {
    color: #90c31d;
    cursor: pointer;
}

.notice {
    color: #333333;
    z-index: 999;
    padding: 10px 0;
    background-color: #012f55;
    border: 1px solid #ebeef5;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
}

.head-img {
    width: 80px;
    height: 80px;
    border: 2px dotted black;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}

.realhead {
    width: 80px;
    height: 80px;
}

.addhead {
    width: 50px;
    height: 50px;
}

.mapbox {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1000px;
    height: 600px;
    z-index: 999;
    background-color: white;
}

.mapsonbox {
    width: 900px;
    /* height: 350px; */
    margin: 20px auto;
}

.addressbox {
    position: relative;
    background-color: #f6f6f7;
    padding: 20px 20px;
}

.networkbtn {
    color: #337AB7;
    border: none;
    cursor: pointer;
    /* padding: 0px 5px 0px 0px; */
    height: 100%;
}

.addressinput {
    width: 840px;
    border: 1px solid #e7e9f1 !important;
    color: #333 !important;
    line-height: 32px !important;
    height: 32px;
    border-radius: 0 !important;
    padding: 0 10px !important;
    font-size: 12px !important;
    font-weight: 400 !important;
}

.addbox {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1000px;
    height: 400px;
    z-index: 999;
    background-color: white;
}

.maplist {
    font-size: 14px;
    padding: 3px 10px;
    cursor: pointer;
}

.maplist:hover {
    background-color: #fafafb;
}

.addtitle {
    padding: 25px 0px 0px 15px;
    font-size: 14px;
    font-weight: bold;
}

.addtable {
    display: flex;
    margin-top: 40px;
    padding: 0px 80px;
}

.addbtn {
    height: 30px;
    line-height: 30px;
    background: #FAFAFA;
    padding: 0px 25px;
    font-size: 14px;
    border: 1px solid #dcdcdc;
    margin-left: 10px;
    cursor: pointer;
    margin-bottom: 20px;
}

.overlay {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    /* 半透明黑色背景 */
    z-index: 999;
    /* 确保在上层 */
}



.title-box {
    margin: 20px 0px 10px;
    height: 30px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ebeef5;
}

.deleteallbox {
    font-size: 14px;
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
    background-color: white !important;
}

.equipmenttitle {
    font-size: 14px;
    position: relative;
    display: flex;
    padding: 8px;
    font-weight: bold;
    border-top: 1px solid #e7e9f1;
    border-bottom: 1px solid #e7e9f1;
    background-color: #fafafb;
}


.networkList {
    font-size: 14px;
    display: flex;
    padding: 8px;
    border-bottom: 1px solid #ebeef5;
}

.networkList:hover {
    background: #FAFAFB;
}

.inputcheck {
    display: flex;
}

.title {
    font-size: 14px;
    font-weight: bold;
}

.btn-box {
    margin: -15px 0px 0px 0px;
    display: flex;
}

.search {
    margin: 0px 10px 20px 0px;
    line-height: 32px;
    padding: 0 16px;
    background-color: #012f55;
    color: white;
    cursor: pointer;
}


.exportimg {
    width: 14px;
    height: 14px;
    margin-right: 10px;
}

.select-box {
    display: flex;
}

.select {
    margin: 0px 10px 20px 0px;
}

.equipmentstate {
    position: relative;
    width: 215px;
    margin-top: 1px;
}

.equipmentname {
    width: 17%;
    min-width: 152px;
    flex-shrink: 0;
    margin-top: 1px;
}

.equipmentid {
    width: 17%;
    min-width: 152px;
    flex-shrink: 0;
    margin-top: 1px;
}

.equipmentlabel {
    width: 13%;
    min-width: 120px;
    flex-shrink: 0;
    margin-top: 1px;
}

.equipmentoperate {
    position: relative;
    width: 240px;
    display: flex;
    /* justify-content: center; */
    color: #0c05f9;
    flex-shrink: 0;
    margin-top: 1px;
}

.btn {
    display: flex;
    align-items: center;
    padding: 0px 16px;
    height: 32px;
    line-height: 32px;
    margin: 0px 10px 0 0;
    border: 1px solid #e7e9f1;
    cursor: pointer;
    font-size: 14px;
    color: #666666;
}

.light {
    position: absolute;
    font-size: 25px;
    top: -2px;
    left: -6px;
    color: #999999;
}

input:focus {
    outline: 1px solid #012f55;
}

.inputcheck:deep(.el-checkbo x__inner) {
    background: #edf2fc;
    border-color: #dcdfe6;
}

.inputcheck:deep(.el-checkbox__inner) {
    background: #edf2fc;
    border-color: #dcdfe6;
}

.map:deep(.el-input-group__append, .el-input-group__prepend) {
    padding: 0 10px;
    cursor: pointer;
    color: #012f55;
}

.inputcheck:deep(.el-checkbox__input.is-focus .el-checkbox__inner) {
    border-color: #012f55;
}

.inputcheck:deep(.el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner) {
    background: #012f55;
    border-color: #012f55;
}

.select-box:deep(.el-input__inner) {
    width: 150px;
    height: 32px;
}

.select-box:deep(.el-input__icon) {
    line-height: 32px;
}

.select:deep(.el-input__inner:focus) {
    border-color: #012f55;
}

.select:deep(.el-input.is-focus .el-input__inner) {
    border-color: #012f55;
}

.select:deep(.el-input__inner:hover) {
    border-color: #012f55;
}

.select:deep(.el-select:hover .el-input__inner) {
    border-color: #012f55;
}

.addtable:deep(.el-input) {
    width: 260px;
}

.addtable:deep(.el-textarea) {
    width: 260px;
}

.addtable:deep(.el-textarea__inner:focus) {
    border-color: #012f55;
}

.addtable:deep(.el-input__inner:focus) {
    border-color: #012f55;
}

@media screen and (max-width: 1700px) {
    .equipmentstate {
        width: 120px;
    }
}

@media screen and (max-width: 1600px) {
    .select-box:deep(.el-input__inner) {
        width: 120px;
        height: 28px;
    }

    .select-box:deep(.el-input) {
        font-size: 12px !important;
    }

    .select-box:deep(.el-input__icon) {
        line-height: 28px;
    }

    .search {
        font-size: 13px;
        margin: 0px 10px 20px 0px;
        line-height: 28px;
        padding: 0 16px;
        background-color: #012f55;
        color: white;
        cursor: pointer;
    }

    .btn {
        font-size: 13px;
        height: 28px;
        line-height: 28px;
    }
}

@media screen and (max-width: 1400px) {
    .equipmentoperate {
        width: 200px;
    }

}
</style>